import React from "react";
import FormField from './FormField';

export default class FormTextArea extends FormField {
    render() {
        return (
            <div className={`col-md-${this.props.width}`}>
                <label htmlFor={this.props.field.key}>{this.props.label}</label>
                <textarea
                    className="form-control"
                    id={this.props.field.key}
                    value={this.props.field.value || ''}
                    name={this.props.field.key}
                    onChange={this.updateValue.bind(this)}
                />
            </div>
        )
    }
}