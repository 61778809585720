import React from "react";

import FormInput from "../../FormField/FormInput";
import FormSelect from "../../FormField/FormSelect";
import FormTextArea from "../../FormField/FormTextArea";
import ViewBase from "../../View";


export default class ViewUnit extends ViewBase {
    state = {};
    obj = 'unit';

    constructor(props) {
        super(props);
        this.state[this.obj] = props[this.obj];
    }

    render() {
        return(
            <div className="container">
                <div className="row row-form">
                    <div className="col-md-12">
                        <h2>
                            Ladepunkt{' '}
                            {!this.state.unit.deleted &&
                                <a href="#" className="btn btn-default btn-icon" onClick={this.deleteSelf.bind(this)}>
                                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                                </a>
                            }
                            {this.state.unit.deleted &&
                                <a href="#" className="btn btn-default btn-icon" onClick={this.undeleteSelf.bind(this)}>
                                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                                    <i className="fa fa-undo fa-ml" aria-hidden="true"></i>
                                </a>
                            }
                        </h2>
                        {this.state.unit.deleted &&
                            <ul id="flash-messages">
                                <li className="alert alert-danger alert-dismissible show" role="alert">
                                    <i className="fa fa-trash-o"></i> Ladepunkt soll gelöscht werden.
                                </li>
                            </ul>
                        }
                        <p>Ein Ladepunkt ist ein einzelner Stecker / Buchse an einem physikalischen Gerät.</p>
                    </div>
                </div>
                <div className="row row-form">
                    <FormInput
                        width="12"
                        field={this.state.unit.fields.uid}
                        label="Ladepunkt-ID (EVSE-ID)"
                    />
                </div>
                <div className="row row-form">
                    <FormSelect
                        width="4"
                        field={this.state.unit.fields.pricegroup}
                        label="Preisgruppe"
                    />
                    <FormSelect
                        width="4"
                        field={this.state.unit.fields.plug}
                        label="Anschluß"
                        updateParent={this.updateParent.bind(this)}
                    />
                    <FormInput
                        width={(['typ2', 'typ2-wired', 'typ1', 'typ1-wired'].includes(this.props.unit.fields.plug.value) ? '2' : 4)}
                        field={this.state.unit.fields.power}
                        label="Ladeleistung"
                        unit="kw"
                    />
                    {['typ2', 'typ2-wired', 'typ1', 'typ1-wired'].includes(this.props.unit.fields.plug.value) &&
                        <FormInput
                            width="2"
                            field={this.state.unit.fields.phases}
                            label="Phasen"
                        />
                    }
                </div>
                <div className="row row-form">
                    <FormTextArea
                        width="12"
                        field={this.state.unit.fields.public_key_meter}
                        label="Public Key des Energiezähler zum Nachweis eines eichrechtlich erfassten Ladevorgangs"
                    />
                </div>
                <div className="row row-form">
                    <FormTextArea
                        width="12"
                        field={this.state.unit.fields.public_key}
                        label="Public Key für die Kommunikation zwischen Ladestation und Giro-e-Backend (nur RTB-Säulen)"
                    />
                </div>
            </div>
        );
    }
}