import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableUnit extends SearchTable {
    params = {
        sort_field: 'uid',
        sort_order: 'asc',
        page: 1
    };
    formId = 'unit-search-form';
    varPrefix = 'searchTableUnit';
    loadParamsRegExp = [
        /\/unit\/(.*)/g
    ];

    apiUrl = '/api/units';
    sortDef = [
        { key: 'uid', name: 'Ladepunkt-EVSEID' },
        { key: 'created', name: 'Erstellungsdatum' }
    ];

    colDef = [
        { sortField: 'uid', text: 'Ladepunkt-EVSEID' },
        { text: 'Betreiber' },
        { text: 'Details' },
        { text: 'Status'},
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr key={`unit-${row.id}`}>
                {this.renderTableCellUid(row)}
                {this.renderTableCellOperator(row)}
                {this.renderTableCellDetails(row)}
                {this.renderTableCellStatus(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellUid(row) {
        return(
            <td>
                {this.formatUnitUid(row.uid)}
            </td>
        )
    }

    renderTableCellDetails(row) {
        return (
            <td>
                {this.getDerefPlugPower(row)}<br/>
                <small>{row.unit_group.address}, {row.unit_group.postalcode} {row.unit_group.locality}</small>
            </td>
        )
    }

    renderTableCellStatus(row) {
        return (
            <td>
                {this.renderStatusIcon('eye', row.public === 'public')}
                {this.renderStatusIcon('eye-slash', row.public === 'hidden')}
            </td>
        )
    }

    getDerefPlugPower(row) {
        let result = this.getDerefPlug(row.plug);
        return result + ' mit ' + String(row.power / 1000).replace('.', ',') + ' kW';
    }

    getDerefPlug(plug) {
        if (plug === 'ccs')
            return 'CCS';
        if (plug === 'chademo')
            return 'CHAdeMO';
        if (plug === 'typ2')
            return 'Typ2';
        if (plug === 'typ2-wired')
            return 'Typ2-Kabel';
        if (plug === 'typ1')
            return 'Typ1';
        if (plug === 'typ1-wired')
            return 'Typ1-Kabel';
        if (plug === 'schuko')
            return 'Schuko';
        return plug;
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/unit/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/unit/${row.id}/edit`, 'edit', 'bearbeiten', this.capabilities.includes('edit'))}
                {this.renderTableCellActionIcon(`/unit/${row.id}/delete`, 'trash', 'löschen', this.capabilities.includes('edit'))}
            </td>
        )
    }
}