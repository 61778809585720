import React from "react";

import Sidebar from "./Views/Sidebar";

import User from "../Models/User";
import UnitGroup from "../Models/UnitGroup";
import Unit from '../Models/Unit';
import Station from '../Models/Station';
import Pricegroup from "../Models/Pricegroup";

import ViewSave from "./Views/ViewSave";
import ViewStatus from "./Views/ViewStatus";
import ViewUnit from "./Views/ViewUnit";
import ViewSettings from "./Views/ViewSettings";
import ViewStation from "./Views/ViewStation";
import ViewRefund from "./Views/ViewRefund";

import { loadGoogleMaps } from '../../Common';

const { Component } = React;

export default class UnitGroupInterface extends Component {
    state = {
        initialized: false,
        refundPricegroup: null,
        units: [],
        unitGroup: null,
        viewType: 'status',
        viewClientUid: null
    };

    constructor(props) {
        super(props);
        if (!unitGroupData || !unitGroupData.id)
            this.state.viewType = 'settings';
    }

    componentDidMount() {
        let promise_list = [loadGoogleMaps()];
        if (typeof unitGroupData === 'undefined') {
            // TODO: get data
        }
        Promise.all(promise_list)
            .then(data => {
                window.currentUser = new User(currentUser);
                let stations = [];
                for (let i = 0; i < unitGroupData.unit.length; i++) {
                    let unit = unitGroupData.unit[i];
                    unit.pricegroup = unit.pricegroup_id;
                    let station = stations.find(station => station.fields.uid.value === unit.uid_station);
                    if (!station) {
                        station = new Station({
                            public: unit.public,
                            uid: unit.uid_station,
                            tech_backend: unit.tech_backend,
                            hardware: unit.hardware_id,
                            name: unit.name_station
                        });
                        stations.push(station);
                    }
                    station.units.push(new Unit(Object.assign(unit, {parent: station})));
                }
                stations.map((station, id) => { station.validate(); });
                if (unitGroupData.operator_refunded_id) {
                    let subOperator = subOperators.filter(operator => operator.id === unitGroupData.operator_refunded_id);
                    if (subOperator) {
                        subOperator = subOperator[0];
                        let type = ((subOperator.type === 'light') ? 'client' : subOperator.type);
                        unitGroupData['sub_operator_' + type] = unitGroupData.operator_refunded_id;
                        unitGroupData.sub_operator_type = 'charge-' + type;
                    }
                }
                unitGroupData.refund_pricegroup = unitGroupData.refund_pricegroup_id;
                if (!unitGroupData.refund_end) {
                    unitGroupData.refund_end = '2100-01-01'
                }
                let unitGroup = new UnitGroup(unitGroupData);
                unitGroup.validate();
                this.setState({
                    unitGroup: unitGroup,
                    stations: stations,
                    initialized: true,
                    refundPricegroup: new Pricegroup()
                })
            });
    }

    setView(type, clientUid) {
        let update = {
            viewType: type,
            viewClientUid: clientUid
        };
        if (this.state.viewType === 'station') {
            this.getStationByClientUid(this.state.viewClientUid).validate();
        }
        if (this.state.viewType === 'unit') {
            this.getUnitByClientUid(this.state.viewClientUid).validate();
        }
        if (['station', 'unit'].includes(this.state.viewType)) {
            update.stations = this.state.stations;
        }
        if (this.state.viewType === 'settings') {
            this.state.unitGroup.validate();
            update.unitGroup = this.state.unitGroup;
        }
        this.setState(update);
    }

    getStationByClientUid(client_uid) {
        for (let i = 0; i < this.state.stations.length; i++) {
            if (client_uid === this.state.stations[i].clientUid) {
                return this.state.stations[i];
            }
        }
    }

    getUnitByClientUid(client_uid) {
        for (let i = 0; i < this.state.stations.length; i++) {
            for (let j = 0; j < this.state.stations[i].units.length; j++) {
                if (client_uid === this.state.stations[i].units[j].clientUid) {
                    return this.state.stations[i].units[j];
                }
            }
        }
    }

    triggerUnitGroupUpdate() {
        this.setState({
            unitGroup: this.state.unitGroup
        });
    }

    triggerStationUpdate() {
        this.setState({
            stations: this.state.stations
        })
    }

    render() {
        if (!this.state.initialized)
            return <div>... wird geladen ...</div>;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <h1>
                            {this.state.unitGroup.fields.name.value && <span>
                                {this.state.unitGroup.fields.name.value}{' - '}
                            </span>}
                            {(this.state.unitGroup.fields.address.value || this.state.unitGroup.fields.postalcode.value || this.state.unitGroup.fields.locality.value) &&
                                <span>
                                    {this.state.unitGroup.fields.address.value},{' '}
                                    {this.state.unitGroup.fields.postalcode.value}{' '}
                                    {this.state.unitGroup.fields.locality.value}
                                </span>
                            }
                            {(!this.state.unitGroup.fields.address.value && !this.state.unitGroup.fields.postalcode.value && !this.state.unitGroup.fields.locality.value) &&
                                <span style={{fontStyle: 'italic'}}>Neue Ladegruppe</span>
                            }
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar
                            unitGroup={this.state.unitGroup}
                            stations={this.state.stations}
                            viewType={this.state.viewType}
                            viewClientUid={this.state.viewClientUid}
                            setView={this.setView.bind(this)}
                            triggerStationUpdate={this.triggerStationUpdate.bind(this)}
                        />
                    </div>
                    <div className="col-md-9">
                        {this.state.viewType === 'save' &&
                            <ViewSave
                                unitGroup={this.state.unitGroup}
                                stations={this.state.stations}
                                refundPricegroup={this.state.refundPricegroup}
                            />
                        }
                        {this.state.viewType === 'status' &&
                            <ViewStatus
                                unitGroup={this.state.unitGroup}
                                stations={this.state.stations}
                            />
                        }
                        {this.state.viewType === 'settings' &&
                            <ViewSettings
                                unitGroup={this.state.unitGroup}
                                triggerUnitGroupUpdate={this.triggerUnitGroupUpdate.bind(this)}
                            />
                        }
                        {this.state.viewType === 'refund' &&
                            <ViewRefund
                                unitGroup={this.state.unitGroup}
                                triggerUnitGroupUpdate={this.triggerUnitGroupUpdate.bind(this)}
                                refundPricegroup={this.state.refundPricegroup}
                            />
                        }
                        {this.state.viewType === 'station' &&
                            <ViewStation
                                unitGroup={this.state.unitGroup}
                                station={this.getStationByClientUid(this.state.viewClientUid)}
                                triggerStationUpdate={this.triggerStationUpdate.bind(this)}
                            />
                        }
                        {this.state.viewType === 'unit' &&
                            <ViewUnit
                                unitGroup={this.state.unitGroup}
                                unit={this.getUnitByClientUid(this.state.viewClientUid)}
                                triggerStationUpdate={this.triggerStationUpdate.bind(this)}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}