import React from "react";
import FormField from './FormField';

export default class FormInput extends FormField {
    static defaultProps = {
        validators: [],
        filters: [],
        renderFilters: [],
        transformKey: (obj) => obj.key,
        transformLabel: (obj) => obj.label
    };

    render() {
        return (
            <div className={`col-md-${this.props.width}`}>
                <label htmlFor={this.props.field.key}>{this.props.label}</label>
                <select
                    className="form-control"
                    id={this.props.field.key}
                    value={this.props.field.value || ''}
                    name={this.props.field.key}
                    onChange={this.updateValue.bind(this)}
                >
                    {this.props.field.options.map((option, id) => (
                        <option key={option.key} value={option.key}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        )
    }
}