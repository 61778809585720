import React from "react";
const { Component } = React;

import FormSelect from "../../FormField/FormSelect";
import FormInput from "../../FormField/FormInput";
import FormInputOperatorPicker from '../../FormField/FormInputOperatorPicker';

export default class ViewInput extends Component {
    state = {
        newOperator: false,
        error: false
    };

    constructor(props) {
        super(props);
        this.state.model = props.model;
    }

    submit() {
        if (!this.state.model.validate()) {
            return this.setState({
                mode: this.state.model
            });
        }
        this.props.queryUids();
    }

    updateOperator(operator) {
        this.setState({
            newOperator: (operator) ? operator.id === 0 : false
        });
    }

    render() {
        return(
            <div>
                {!!this.props.errors && <div className="row row-form">
                    <div className="col-md-12" style={{color: '#dc3545'}}>
                        {this.props.errors === 'station in use' && <span>Fehler: Station existiert bereits.</span>}
                        {Array.isArray(this.props.errors) && <div>
                            Fehler:
                            <ul>
                                {this.props.errors.map((item, id) => <li key={id}>{item}</li>)}
                            </ul>
                        </div>}
                    </div>
                </div>}
                <div className="row row-form">
                    <FormInput
                        width="6"
                        field={this.state.model.fields.stationUidField}
                        label="Stations-ID (falls bekannt)"
                    />
                    <FormSelect
                        width="6"
                        field={this.state.model.fields.unitCountField}
                        label="Anzahl an Ladepunkten"
                    />
                </div>
                <div className="row row-form">
                    <FormInputOperatorPicker
                        width="6"
                        field={this.state.model.fields.operatorField}
                        label="Betreiber"
                        updateParent={this.updateOperator.bind(this)}
                    />
                    {this.state.newOperator && <FormInput
                        width="6"
                        field={this.state.model.fields.newOperatorLocalityField}
                        label="Ort des neuen Betreibers"
                    />}
                    {(!this.state.newOperator && this.state.model.fields.operatorField.value && this.state.model.fields.operatorField.value.locality) && <p className="col-md-6">
                        Der Betreiber befindet sich in {this.state.model.fields.operatorField.value.locality}
                    </p>}
                </div>
                <div className="row row-form">
                    <div className="col-md-12">
                        <input
                            type="submit"
                            value="EVSEID(s) generieren"
                            className="form-control"
                            onClick={this.submit.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
