import React from "react";
import { daterangepicker_locale } from '../Constants';
const { Component } = React;
import moment from 'moment';
import {formatDatetime, formatDuration, formatEnergy, formatPrice} from "../Format";

export default class DiscountCalculateTestManagement extends Component {
    formId = 'discount-calculate-test-form';
    apiUrl = '/api/discount/test/calculcate';
    state = {
        initialized: false
    };

    componentDidMount() {
        this.init();
    }

    init() {
        let daterangepicker_locale = Object.assign({}, daterangepicker_locale);
        daterangepicker_locale.format = 'DD.MM.YYYY, hh:mm';
        let daterangepicker_ranges = {
           '1 Minute': [moment().subtract(1, 'minute'), moment()],
           '10 Minuten': [moment().subtract(10, 'minute'), moment()],
           '30 Minuten': [moment().subtract(30, 'minute'), moment()],
           '90 Minuten': [moment().subtract(90, 'minute'), moment()],
           '4 Stunden': [moment().subtract(4, 'hour'), moment()],
           '8 Stunden': [moment().subtract(8, 'hour'), moment()],
           '1 Tag': [moment().subtract(1, 'day'), moment()],
           '4 Tage': [moment().subtract(4, 'day'), moment()]
        };
        this.dateRangeBegin = moment().subtract(4, 'hour');
        this.dateRangeEnd = moment();
        $('#datetimerange').daterangepicker({
            startDate: this.dateRangeBegin,
            endDate: this.dateRangeEnd,
            timePicker: true,
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, () => this.updateData());

        document.getElementById(this.formId).onsubmit = (event) => this.formSubmit(event)
    }

    formSubmit(event) {
        event.preventDefault();
        this.updateData();
    }

    updateData() {
        let params = {};
        let fields = $('#' + this.formId).serializeArray();
        for (let i = 0; i < fields.length; i++){
            if (fields[i].value && fields[i].value !== '_default' && fields[i].value !== '_all')
                params[fields[i].name] = fields[i].value;
        }
        $.post(this.apiUrl, params)
            .then(data => {
                if (data.status)
                    return;
                this.setState({
                    data: data.data,
                    initialized: true
                });
            });
    }

    render() {
        if (!this.state.initialized) {
            return (
                <p className="italic-comment">
                    Bitte Optionen wählen.
                </p>
            );
        }
        return (
            <div className="row">
                <div className="col-md-12">
                    <h2>Ergebnis</h2>
                    <table className="table table-striped pricetable">
                        <tr>
                            <th className="col-left">Position</th>
                            <th>Messwerte</th>
                            <th>Abgerechnet</th>
                            <th>Ohne Rabatt (*)</th>
                            <th>Rabatt</th>
                            <th>Mit Rabatt</th>
                        </tr>
                        <tr>
                            <td className="col-left">Start</td>
                            <td>1</td>
                            <td>1</td>
                            <td>{ formatPrice(this.state.data.calculated.base) }</td>
                            <td>{ formatPrice(this.state.data.discount.base) }</td>
                            <td>{ formatPrice(this.state.data.discounted.base) }</td>
                        </tr>
                        <tr>
                            <td className="col-left">Verbrauch</td>
                            <td>{ formatEnergy(this.state.data.request.energy) }</td>
                            <td>{ formatEnergy(this.state.data.corrected_values.energy)}</td>
                            <td>{ formatPrice(this.state.data.calculated.energy) }</td>
                            <td>{ formatPrice(this.state.data.discount.energy) }</td>
                            <td>{ formatPrice(this.state.data.discounted.energy) }</td>
                        </tr>
                        <tr>
                            <td className="col-left">Zeit</td>
                            <td>{ formatDuration(this.state.data.request.duration) }</td>
                            <td>{ formatDuration(this.state.data.corrected_values.duration) }</td>
                            <td>{ formatPrice(this.state.data.calculated.time) } </td>
                            <td>{ formatPrice(this.state.data.discount.time) }</td>
                            <td>{ formatPrice(this.state.data.discounted.time) }</td>
                        </tr>
                        <tr>
                            <td className="col-left">Allgemeiner Rabatt</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{ formatPrice(this.state.data.discount.all) }</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th className="col-left">Brutto</th>
                            <td></td>
                            <td></td>
                            <th>{ formatPrice(this.state.data.calculated.total) } </th>
                            <th>{ formatPrice(this.state.data.discount.total) }</th>
                            <th>{ formatPrice(this.state.data.discounted.total) }</th>
                        </tr>
                        <tr>
                            <td className="col-left">davon { String(this.state.data.vat * 100).replace('.', ',') } % Umsatzsteuer</td>
                            <td></td>
                            <td></td>
                            <td>{ formatPrice(this.state.data.calculated.tax) } </td>
                            <td>{ formatPrice(this.state.data.discount.tax) }</td>
                            <td>{ formatPrice(this.state.data.discounted.tax) }</td>
                        </tr>
                        <tr>
                            <th className="col-left">davon Netto</th>
                            <td></td>
                            <td></td>
                            <th>{ formatPrice(this.state.data.calculated.subtotal) } </th>
                            <th>{ formatPrice(this.state.data.discount.subtotal) }</th>
                            <th>{ formatPrice(this.state.data.discounted.subtotal) }</th>
                        </tr>
                    </table>
                    <p className="italic-comment">
                        * Die Messwerte werden gemäß der vom Ladepunkt verwendeten Preisgruppe angepasst; diese Spalte
                        ist also der Wert nach der Messwertkorrektur, aber vor jedem Rabatt aus dem Rabattsystem.
                    </p>
                </div>
            </div>
        );
    }
}