import { zerofy } from "../Format";

export const filterInteger = (value) => {
    if (value === null || value === '')
        return null;
    return parseInt(value);
};

export const filterFloat = (value) => {
    if (value === '' || value === null)
        return null;
    return parseFloat(String(value).replace(',', '.'));
};

export const filterString = (value) => {
    if (!value)
        return '';
    return String(value);
};

export const filterFloatToString = (value) => {
    if (value === undefined || value === null || value === '')
        return '';
    return String(value).replace('.', ',');
};

export const filterUppercase = (value) => {
    return value.toUpperCase();
};

export const filterEnsureList = (value) => {
    if (!value)
        return [];
    return value;
};

export const filterIsoDate = (value) => {
    if (!value || !value instanceof Date)
        return null;
    return String(value.getFullYear()) + '-' + zerofy(value.getMonth() + 1) + '-' + zerofy(value.getDate());
};

export const filterDate = (value) => {
    if (!value || value.length !== 10)
        return null;
    return new Date(value);
};


export const filterDiv1000 = (value) => {
    return value / 1000;
}

export const filterMul1000 = (value) => {
    return value * 1000;
}