import React from "react";
const { Component } = React;
import EvseGeneratorModel from './EvseGeneratorModel';
import ViewInput from "./Views/ViewInput";
import ViewResult from "./Views/ViewResult";
import {requestGet, requestPost} from '../../Common';


export default class EvseGenerator extends Component {
    state = {
        viewState: 'input'
    };

    constructor(props) {
        super(props);
        this.state.operators = [{id: -1, name: 'bitte wählen'}, {id: 0, name: 'Neuer Betreiber'}, ...validOperators];
        this.state.model = new EvseGeneratorModel();
    }

    queryUids() {
        this.setState({
            viewState: 'saving'
        })
        requestGet('/api/common/csrf').then(data => {
            requestPost('/api/unit/new-uid', this.state.model.dump(), data.csrf).then(data => {
                console.log(data)
                if (data.status) {
                    return this.setState({
                        viewState: 'input',
                        errors: data.error
                    });
                }
                return this.setState({
                    viewState: 'result',
                    generatedUids: data.data.uids,
                    unitGroup: data.data.unit_group
                });
            });
        });
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <h1>EVSEIDs generieren</h1>
                        <p>Mit diesem Tool werden EVSEIDs generiert und angelegt.</p>
                    </div>
                </div>
                {this.state.viewState === 'input' &&
                    <ViewInput
                        model={this.state.model}
                        queryUids={this.queryUids.bind(this)}
                        errors={this.state.errors}
                    />
                }
                {this.state.viewState === 'result' &&
                    <ViewResult
                        model={this.state.model}
                        uids={this.state.generatedUids}
                        unitGroup={this.state.unitGroup}
                    />
                }
            </div>
        )
    }
}
