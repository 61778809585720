import { registerLocale, setDefaultLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';

export default class Common {
    constructor () {
        window.timezone = 'Europe/Berlin';
        window.onunload = function(){};
        registerLocale('de', de)
        setDefaultLocale('de');
        this.storageAvailable = this.getStorageAvailable();
        this.setLastUrl();

        $('[data-toggle="tooltip"]').tooltip();
    };


    setLastUrl() {
        if (!this.storageAvailable)
            return;
        this.lastUrl = localStorage.getItem('lastUrl');
        localStorage.setItem('lastUrl', window.location.pathname);
    }

    getStorageAvailable() {
        try {
            let x = '__storage_test__';
            localStorage.setItem(x, x);
            localStorage.removeItem(x);
            return true;
        }
        catch(e) {
            return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage.length !== 0;
        }
    }
};

export const generateUid = () => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < 32; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const base64ToUint8Array = (base64String) => {
    const rawData = atob(base64String);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
};

export const loadGoogleMaps = () => {
    if (typeof googleMapsApiKey === undefined)
        return;
    window.resolveGoogleMapsPromise = null;
    const googleMapsPromise = new Promise(function(resolve, reject) {
        window.resolveGoogleMapsPromise = resolve;
    });
    let script_tag = document.createElement('script');
    script_tag.setAttribute("type","text/javascript");
    script_tag.setAttribute("src","https://maps.google.com/maps/api/js?key=" + googleMapsApiKey + "&callback=window.resolveGoogleMapsPromise");
    (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);
    return googleMapsPromise;
};


export const requestGet = async (url) => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response.json();
};

export const requestPost = async (url, data, csrf_token) => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token
        },
        body: JSON.stringify(data)
    });
    return response.json();
};