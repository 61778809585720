import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableBank extends SearchTable {
    params = {
        sort_field: 'name',
        sort_order: 'asc',
        page: 1
    };
    apiUrl = '/api/banks';
    formId = 'bank-search-form';
    varPrefix = 'searchTableBank';
    loadParamsRegExp = [
        /\/bank\/(.*)/g
    ];

    sortDef = [
        { key: 'name', name: 'Name' }
    ];

    colDef = [
        { sortField: 'name', text: 'Name' },
        { text: 'Land' },
        { text: 'BIC' },
        { text: 'BLZ' },
        { text: 'Kurz-BLZ' }
    ];

    constructor() {
        super();
        let bic = $('#bic');
        if (bic.val()) {
            this.params.bic = bic.val();
        }
    }


    renderTableRow(row) {
        return (
            <tr key={`bank-${row.id}`}>
                {this.renderTableCellName(row)}
                {this.renderTableCellCountry(row)}
                {this.renderTableCellBIC(row)}
                {this.renderTableCellIdentificationNumber(row)}
                {this.renderTableCellIdentificationNumberShort(row)}
            </tr>
        )
    }

    renderTableCellName(row) {
        return(
            <td>
                {row.name}
            </td>
        )
    }


    renderTableCellCountry(row) {
        return(
            <td>
                {row.country}
            </td>
        )
    }

    renderTableCellBIC(row) {
        return(
            <td>
                {row.bic}
            </td>
        )
    }

    renderTableCellIdentificationNumber(row) {
        return(
            <td>
                {row.identification_number}
            </td>
        )
    }

    renderTableCellIdentificationNumberShort(row) {
        return(
            <td>
                {row.identification_number_short}
            </td>
        )
    }
}