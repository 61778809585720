import '../sass/base.scss'
import '../sass/webapp.scss'

import React from "react";
import ReactDOM from "react-dom";

import Common from './Common';

import OperatorManagement from './Helper/OperatorManagement';
import FirmwareManagement from "./Helper/FirmwareManagement";
import PricegroupManagement from './Helper/PricegroupManagement';
import ProductManagement from './Helper/ProductManagement';
import UnitManagement from './Helper/UnitManagement';
import AccountManagement from './Helper/AccountManagement';
import CashpointPreview from './Helper/CashpointPreview';
import UnitGroupMap from './Helper/UnitGroupMap';
import InvoiceItemList from './Helper/InvoiceItemList'
import DashboardStatistics from './Statistics/DashboardStatistics';
import DashboardLeftManagement from './Helper/DashboardLeftManagement';
import DashboardRightManagement from './Helper/DashboardRightManagement';
import UnitsTcc from "./UnitTcc/UnitsTcc";
import UnitTcc from "./UnitTcc/UnitTcc";
import ChargeMeterTimeline from "./Helper/ChargeMeterTimeline";
import UnitGroupInterface from "./DynamicForms/UnitGroupInterface/UnitGroupInterface";
import StationMove from "./DynamicForms/StationMove/StationMove";
import EvseGenerator from "./DynamicForms/EvseGenerator/EvseGenerator";

import SearchTableNotification from './SearchTable/SearchTableNotification';
import SearchTableBank from './SearchTable/SearchTableBank';
import SearchTableDownload from './SearchTable/SearchTableDownload';
import SearchTableOperator from './SearchTable/SearchTableOperator';
import SearchTableFirmware from './SearchTable/SearchTableFirmware';
import SearchTableUnitGroup from './SearchTable/SearchTableUnitGroup';
import SearchTableUnit from './SearchTable/SearchTableUnit';
import SearchTableUnitTechBackendLog from './SearchTable/SearchTableUnitTechBackendLog';
import SearchTablePricegroup from './SearchTable/SearchTablePricegroup';
import SearchTableDiscount from './SearchTable/SearchTableDiscount';
import SearchTableDiscountClient from './SearchTable/SearchTableDiscountClient';
import SearchTableCashpoint from "./SearchTable/SearchTableCashpoint";
import SearchTableProduct from './SearchTable/SearchTableProduct';
import SearchTableProductCategory from './SearchTable/SearchTableProductCategory';
import SearchTableAccount from './SearchTable/SearchTableAccount';
import SearchTableToken from './SearchTable/SearchTableToken';
import SearchTableTokenPreRegister from './SearchTable/SearchTableTokenPreRegister';
import SearchTableTokenFleet from './SearchTable/SearchTableTokenFleet';
import SearchTableInvoice from './SearchTable/SearchTableInvoice';
import SearchTableCharge from './SearchTable/SearchTableCharge';
import SearchTableOrder from './SearchTable/SearchTableOrder';
import SearchTableTransfer from './SearchTable/SearchTableTransfer';
import SearchTableManufacturer from "./SearchTable/SearchTableManufacturer";
import SearchTableHardware from "./SearchTable/SearchTableHardware";
import SearchTableOperatorUser from "./SearchTable/SearchTableOperatorUser";
import SearchTableOperatorRelatedClient from './SearchTable/SearchTableOperatorRelatedClient';

import ChargeStatistics from './Statistics/ChargeStatistics';
import OrderStatistics from './Statistics/OrderStatistics';
import ChargeDiagrams from './Statistics/ChargeDiagrams';

import PushNotificationSettings from "./Helper/PushNotificationSettings";
import DiscountCalculateTestManagement from "./Helper/DiscountCalculateTestManagement";
import DiscountRecipientTestManagement from "./Helper/DiscountRecipientTestManagement";
import UnitMassImport from "./UnitMassImport/UnitMassImport";
import SearchTableRefund from "./SearchTable/SearchTableRefund";
import UnitPicker from "./Pickers/UnitPicker";
import LocalityPicker from "./Pickers/LocalityPicker";
import SearchTableTransferTransaction from "./SearchTable/SearchTableTransferTransaction";
import LiveLog from "./LiveLog/LiveLog";
import OperatorPicker from "./Pickers/OperatorPicker";
import AddressPicker from "./Pickers/AddressPicker";
import SearchTableOperatorRelatedEmployee from "./SearchTable/SearchTableOperatorRelatedEmployee";
import NewOperatorSelector from "./Helper/NewOperatorSelector";


$(document).ready(function () {
    window.common = new Common();
    /*
     * Helpers
     */
    if (document.getElementById('account-form') || document.getElementById('account-b2b-form')) {
        window.accountManagement = new AccountManagement();
    }
    let helperObjects = {
        'unit-group-map': UnitManagement,
        'pricegroup-form': PricegroupManagement,
        'product-form': ProductManagement,
        'cashpoint-preview': CashpointPreview,
        'unit-form': UnitManagement,
        'unit-group-form': UnitManagement,
        'operator-form': OperatorManagement,
        'operator-user-form': OperatorManagement,
        'firmware-form': FirmwareManagement
    }

    for (const [html_id, HelperClass] of Object.entries(helperObjects)) {
        if (document.getElementById(html_id)) {
            window[HelperClass.name.charAt(0).toLowerCase() + HelperClass.name.slice(1)] = new HelperClass();
        }
    }

    if (document.getElementById('push-notification-settings')) {
        ReactDOM.render(
            <PushNotificationSettings ref={(pushNotificationSettings) => {window.pushNotificationSettings = pushNotificationSettings}} />,
            document.getElementById("push-notification-settings")
        );
    }

    if ($('#dashboard').length) {
        if ($('#dashboard-transfers').length) {
            ReactDOM.render(
                <DashboardStatistics ref={(dashboardStatistics) => {window.dashboardStatistics = dashboardStatistics}} />,
                document.getElementById("dashboard-transfers")
            );
        }
        ReactDOM.render(
            <DashboardRightManagement ref={(dashboardRightManagement) => {window.dashboardRightManagement = dashboardRightManagement}} />,
            document.getElementById("dashboard-right")
        );
        ReactDOM.render(
            <DashboardLeftManagement ref={(dashboardLeftManagement) => {window.dashboardLeftManagement = dashboardLeftManagement}} />,
            document.getElementById("dashboard-left")
        );
    }
    /*
     react loader
     */
    let reactObjects = {
        'invoice-item-list': InvoiceItemList,
        'units-tcc-box': UnitsTcc,
        'unit-tcc-box': UnitTcc,
        'charge-meter-timeline': ChargeMeterTimeline,
        'unit-mass-import-files': UnitMassImport,
        'discount-calculate-test-results': DiscountCalculateTestManagement,
        'discount-recipient-test-results': DiscountRecipientTestManagement,
        'live-log-viewer': LiveLog,
        'unit-group-interface': UnitGroupInterface,
        'station-move': StationMove,
        'evse-generator': EvseGenerator,

        // search tables
        'notification-search-results': SearchTableNotification,
        'download-search-results': SearchTableDownload,
        'bank-search-results': SearchTableBank,
        'manufacturer-search-results': SearchTableManufacturer,
        'hardware-search-results': SearchTableHardware,
        'operator-search-results': SearchTableOperator,
        'operator-user-search-results': SearchTableOperatorUser,
        'operator-related-client-search-results': SearchTableOperatorRelatedClient,
        'operator-related-employee-search-results': SearchTableOperatorRelatedEmployee,
        'firmware-search-results': SearchTableFirmware,
        'unit-group-search-results': SearchTableUnitGroup,
        'unit-search-results': SearchTableUnit,
        'unit-tech-backend-log-search-results': SearchTableUnitTechBackendLog,
        'pricegroup-search-results': SearchTablePricegroup,
        'discount-search-results': SearchTableDiscount,
        'discount-client-search-results': SearchTableDiscountClient,
        'cashpoint-search-results': SearchTableCashpoint,
        'product-search-results': SearchTableProduct,
        'product-category-search-results': SearchTableProductCategory,
        'account-search-results': SearchTableAccount,
        'token-search-results': SearchTableToken,
        'token-pre-register-search-results': SearchTableTokenPreRegister,
        'token-fleet-search-results': SearchTableTokenFleet,
        'invoice-search-results': SearchTableInvoice,
        'charge-search-results': SearchTableCharge,
        'order-search-results': SearchTableOrder,
        'transfer-search-results': SearchTableTransfer,
        'refund-search-results': SearchTableRefund,
        'transfer-transaction-search-results': SearchTableTransferTransaction,

        // statistics
        'charge-statistics-product-results': ChargeStatistics,
        'order-statistics-product-results': OrderStatistics,
        'charge-diagram-results': ChargeDiagrams
    };
    for (const [html_id, ReactClass] of Object.entries(reactObjects)) {
        if (document.getElementById(html_id)) {
            ReactDOM.render(
                <ReactClass ref={(reactClass) => {window[ReactClass.name.charAt(0).toLowerCase() + ReactClass.name.slice(1)] = reactClass}} />,
                document.getElementById(html_id)
            );
        }
    }


    /*
     * Other React Stuff
     */
    $('.unit-picker').each((index, obj) => {
        ReactDOM.render(
            <UnitPicker id={$(obj).attr('data-id')}/>, obj
        )
    });

    $('.locality-picker').each((index, obj) => {
        ReactDOM.render(
            <LocalityPicker id={$(obj).attr('data-id')}/>, obj
        )
    });

    $('.operator-picker').each((index, obj) => {
        ReactDOM.render(
            <OperatorPicker id={$(obj).attr('data-id')}/>, obj
        )
    });

    $('.address-picker').each((index, obj) => {
        ReactDOM.render(
            <AddressPicker id={$(obj).attr('data-id')}/>, obj
        )
    });

    $('.new-button').each((index, obj) => {
        ReactDOM.render(
            <NewOperatorSelector
                newUrl={obj.getAttribute('data-new-url')}
                title={obj.getAttribute('data-title')}
                enableSelector={obj.getAttribute('data-enable-selector') === '1'}
            />, obj
        )
    });

    /*
     * CSRF Token renewal
     */
    const csrfField = $('#csrf_token');
    if (csrfField.length) {
        setInterval(() => {
            $.getJSON('/api/common/csrf', (result) => {
                csrfField.val(result.csrf);
                csrfField.change()
            })
        }, 480000) //8 Minutes
    }

});


window.googleMapsLoaded = new $.Deferred();
window.setGoogleMapsLoaded = function() {
    window.googleMapsLoaded.resolve();
};
