import React from "react";
const { Component } = React;
import {AsyncTypeahead} from 'react-bootstrap-typeahead';

export default class BasePicker extends Component {

    state = {
        isLoading: false,
        value: '',
        options: []
    };
    includeQuery = false;

    getUrl() {
        throw new Error('Implement this method in a subclass')
    }

    sanitizeOption(option) {
        return option
    }

    filterByFunction() {
        return []
    }

    beforeSend(value) {
        return value;
    }

    render() {
        return (
            <div>
                <AsyncTypeahead
                    id={`${this.props.id}-typeahead`}
                    isLoading={this.state.isLoading}
                    align={'left'}
                    onSearch={query => {
                        this.setState({isLoading: true});
                        $.getJSON(this.getUrl(), {search: this.beforeSend(query)}, (result) => {
                            let sanitizedOptions = result.map(i => this.sanitizeOption(i));
                            if (this.includeQuery)
                                sanitizedOptions = [this.sanitizeOption(query), ...sanitizedOptions]
                            this.setState({isLoading: false, options: sanitizedOptions});
                        });
                    }}
                    options={this.state.options}
                    onChange={(e) => this.setState({value: e.length ? e[0] : ''})}
                    filterBy={this.filterByFunction()}
                />
                <input name={this.props.id} id={this.props.id} value={this.state.value} type='hidden'/>
            </div>
        );
    }
}
