import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableDownload extends SearchTable {
    params = {
        sort_field: 'modified',
        sort_order: 'desc',
        page: 1
    };
    apiUrl = '/api/downloads';
    formId = 'download-search-form';
    varPrefix = 'searchTableDownload';
    loadParamsRegExp = [
        /\/download\/(.*)/g
    ];
    admin = false;

    sortDef = [
        { key: 'name', name: 'Name' },
        { key: 'created', name: 'Erstellt' },
        { key: 'modified', name: 'Bearbeitet' }
    ];

    colDef = [
        { sortField: 'name', text: 'Name' },
        { sortField: 'modified', text: 'Aktualisiert' },
        { text: 'Status'},
        { text: 'Aktionen' }
    ];

    init() {
        super.init();
        this.admin = parseInt($('#' + this.formId).data('admin')) === 1;
    }
    
    renderTableRow(row) {
        return (
            <tr key={`download-${row.id}`}>
                {this.renderTableCellName(row)}
                {this.renderTableCellModified(row)}
                {this.renderTableCellStatus(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellName(row) {
        return(
            <td>
                {row.name}
            </td>
        )
    }

    renderTableCellModified(row, full) {
        return(
            <td>
                {(full) ? this.formatDateTime(row.modified) : this.formatDate(row.modified)}
            </td>
        )
    }

    renderTableCellStatus(row) {
        return(
            <td>
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/download/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/download/${row.id}/download`, 'download', 'download', row.downloadable)}
                {this.renderTableCellActionIcon(`/download/${row.id}/edit`, 'pencil', 'bearbeiten', this.admin)}
                {this.renderTableCellActionIcon(`/download/${row.id}/delete`, 'trash', 'löschen', this.admin)}
            </td>
        )
    }
}