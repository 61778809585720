import React from "react";
import { formatDatetime } from '../Format';
import SearchTable from './SearchTable'

export default class SearchTableOperatorUser extends SearchTable {
    params = {
        sort_field: 'user_name',
        sort_order: 'asc',
        page: 1
    };
    apiUrl = '/api/operator/users';
    formId = 'operator-user-search-form';
    varPrefix = 'searchTableOperatorUser';
    loadParamsRegExp = [
        /\/operator\/(.*)\/user\//g
    ];

    sortDef = [
        { key: 'user_name', name: 'Login'},
        { key: 'last_login', name: 'Letztes Login'},
        { key: 'created', name: 'Erstellt' },
        { key: 'modified', name: 'Bearbeitet' }
    ];

    colDef = [
        { sortField: 'user_name', text: 'Name' },
        { sortField: 'last_login', text: 'Letztes Login'},
        { text: 'Aktionen' }
    ];

    init() {
        let operator_id = $('#' + this.formId).data('operator-id');
        if (operator_id) {
            this.params.operator = operator_id;
        }
        super.init();
    }
    
    renderTableRow(row) {
        return (
            <tr key={`operator-user-${row.id}`}>
                {this.renderTableCellName(row)}
                {this.renderTableCellLastLogin(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellName(row) {
        return(
            <td>
                {row.user_name}<br/>
                {row.firstname} {row.lastname}
            </td>
        )
    }

    renderTableCellLastLogin(row) {
        return(
            <td>
                {formatDatetime(row.login_datetime, 'unbekannt')}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/operator/${row.operator.id}/user/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/operator/${row.operator.id}/user/${row.id}/edit`, 'pencil', 'bearbeiten', true)}
                {this.renderTableCellActionIcon(`/operator/${row.operator.id}/user/${row.id}/delete`, 'trash', 'löschen', true)}
                {this.renderTableCellActionIcon(`/user/${row.id}/switch`, 'arrow-right', 'In Nutzeransicht wechseln', this.operatorEnabled)}
            </td>
        )
    }
}