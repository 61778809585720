import React from "react";
const { Component } = React;

export default class ViewInput extends Component {

    render() {
        return(
            <div className="row">
                <div className="col-md-12">
                    <h2>Generierte EVSEIDs</h2>
                    <ul>
                        {this.props.uids.map(uid => <li key={uid}>
                            <h4>{uid}</h4>
                        </li>)}
                    </ul>
                    Sie können <a href={`/unit-group/${this.props.unitGroup}/edit`}>die Ladepunkte bearbeiten</a>
                    {' '}oder <a href="/unit/new-uid">weitere EVSEIDs generieren</a>.
                </div>
            </div>
        );
    }
}
