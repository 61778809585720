import React from "react";
import {formatDatetime } from "../Format";

const { Component } = React;

export default class UnitTcc extends Component {
    state = {
        loadError: false,
        configUpdating: [],
        configSaving: [],
        newConfigKey: '',
        newConfigValue: '',
        unitConfig: []
    };
    saveConfigMessages = {
        Accepted: 'gespeichert',
        Rejected: 'nicht beschreibbar',
        RebootRequired: 'Neustart erforderlich',
        NotSupported: 'nicht unterstützt'
    };

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $('.btn-icon').tooltip();
    }

    init() {
        this.setState({
            unitConfig: this.props.unitConfig
        });
        this.props.socket.on('update', (data) => {
            if (data.type === 'query-config') {
                this.handleQueryConfigUpdate(data);
            }
            if (data.type === 'set-config') {
                this.handleSaveConfigUpdate(data);
            }
        });
    }

    handleQueryConfigUpdate(data) {
        let config = this.state.unitConfig;
        let new_keys = [];
        let new_error = null;
        let found = false;

        if (data.data.configurationKey) {
            for (let i = 0; i < data.data.configurationKey.length; i++) {
                new_keys.push(data.data.configurationKey[i].key);
                found = false;
                for (let j = 0; j < config.length; j++) {
                    if (config[j].key === data.data.configurationKey[i].key) {
                        config[j] = data.data.configurationKey[i];
                        config[j].updated = data.datetime;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    let new_item = Object.assign({}, data.data.configurationKey[i]);
                    new_item.updated = data.datetime;
                    config.push(new_item);
                }
            }
        }
        if (data.data.unknownKey && data.data.unknownKey.length) {
            new_error = 'Schlüssel unbekannt';
            new_keys = [...new_keys, ...data.data.unknownKey];
        }
        this.setState({
            unitConfig: config,
            configUpdating: this.state.configUpdating.filter(function(value, index, arr) { return !new_keys.includes(value)}),
            configError: new_error
        });
    }

    handleSaveConfigUpdate(data) {
        let new_state = {
            configSaving: this.state.configSaving.filter(function(value, index, arr) { return value !== data.mirror.key})
        };
        if (['Accepted', 'RebootRequired'].includes(data.data.status)) {
            new_state.configUpdating = [...this.state.configUpdating, data.mirror.key];
            if (data.data.status === 'RebootRequired') {
                new_state.configError = this.saveConfigMessages.RebootRequired;
            }
            window.setTimeout(() => {
                let params = Object.assign({}, this.props.params);
                params.key = data.mirror.key;
                this.props.socket.emit('query-config', params);
            }, 1000);
        }
        else {
            new_state.configError = this.saveConfigMessages[data.data.status];
        }
        this.setState(new_state);
    }

    syncSingleConfig(key, evt) {
        evt.preventDefault();
        if (!this.validateKey(key)) {
            this.setState({
                configError: 'Schlüssel invalide'
            });
            return;
        }
        let params = Object.assign({}, this.props.params);
        params.key = key;
        this.setState({
            configUpdating: [...this.state.configUpdating, key]
        });
        this.props.socket.emit('query-config', params);
    }

    saveSingleConfig(key, value, evt) {
        evt.preventDefault();
        if (!this.validateKey(key)) {
            this.setState({
                configError: 'Schlüssel invalide'
            });
            return;
        }
        if (!this.validateValue(value)) {
            this.setState({
                configError: 'Wert invalide'
            });
            return;
        }
        let params = Object.assign({}, this.props.params);
        params.key = key;
        params.value = value;
        this.setState({
            configSaving: [...this.state.configSaving, key]
        });
        this.props.socket.emit('set-config', params);
    }

    validateKey(key) {
        return /^([a-zA-Z0-9-]+)$/.test(key);
    }

    validateValue(value) {
        return /^([a-zA-Z0-9:\-_./]*)$/.test(value);
    }

    render() {
        let rows = [];
        if (this.props.capabilities.includes('edit')) {
            rows.push(
                <tr key="new-config-value">
                    <td style={{position: 'relative'}}>
                        <input
                            type="text"
                            name="config-new-key"
                            className="form-control"
                            value={this.state.newConfigKey}
                            onChange={(evt) => { this.setState({newConfigKey: evt.target.value, configError: null})}}
                        />
                        {this.state.newConfigKey &&
                        <i
                            className="fa fa-times-circle-o"
                            aria-hidden="true"
                            style={{
                                position: 'absolute',
                                top: '1rem',
                                right: '1rem',
                                cursor: 'pointer',
                                fontSize: '2rem',
                                color: '#CCC'
                            }}
                            onClick={() => this.setState({newConfigKey: '', newConfigValue: ''})}
                        ></i>
                        }
                    </td>
                    <td>
                        <input
                            type="text"
                            name="config-new-value"
                            className="form-control"
                            value={this.state.newConfigValue}
                            onChange={(evt) => { this.setState({newConfigValue: evt.target.value, configError: null})}}
                        />
                    </td>
                    <td style={{color: '#CC0000'}}>{this.state.configError}</td>
                    <td>
                        <a onClick={this.syncSingleConfig.bind(this, this.state.newConfigKey)} className="btn btn-default btn-icon" data-placement="top" title="von der Ladesäule abrufen">
                            <i className={`fa fa-refresh${(this.state.configUpdating.includes(this.state.newConfigKey) ? ' fa-spin' : '')}`} aria-hidden="true"></i>
                        </a>
                        <a onClick={this.saveSingleConfig.bind(this, this.state.newConfigKey, this.state.newConfigValue)} className="btn btn-default btn-icon" data-placement="top" title="speichern">
                            <i className={`fa fa-floppy-o${(this.state.configSaving.includes(this.state.newConfigKey) ? ' fa-spin' : '')}`} aria-hidden="true"></i>
                        </a>
                    </td>
                </tr>
            );
        }
        for (let i = 0; i < this.state.unitConfig.length; i++) {
            rows.push(this.renderConfigLine(this.state.unitConfig[i]))
        }
        return(
            <div className="row row-form" key="config">
                <div className="col-md-12">
                    <h3>Konfiguration</h3>
                    <form onSubmit={(evt) => { evt.preventDefault(); }}>
                        <table className="table" id="tcc-config-table">
                            <thead>
                            <tr>
                                <th>Schlüssel</th>
                                <th>Wert</th>
                                <th>Update</th>
                                <th>Aktionen</th>
                            </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </table>
                    </form>
                </div>
            </div>
        );
    }

    renderConfigLine(line) {
        if (this.state.newConfigKey && line.key.toLowerCase().search(this.state.newConfigKey.toLowerCase()) === -1) {
            return;
        }
        let params = {};
        if (!line.readonly) {
            params.onClick = () => this.setState({newConfigKey: line.key, newConfigValue: line.value});
            params.style = {cursor: 'pointer', color: '#8DAA0B'};
        }
        return(
            <tr key={`config-${line.key}`}>
                <td {...params}>
                    {line.key}
                </td>
                <td>{line.value}</td>
                <td>{formatDatetime(line.updated, '', true)}</td>
                <td>
                    <a onClick={this.syncSingleConfig.bind(this, line.key)} className="btn btn-default btn-icon" data-placement="top" title="von der Ladesäule abrufen">
                        <i className={`fa fa-refresh${(this.state.configUpdating.includes(line.key) ? ' fa-spin' : '')}`} aria-hidden="true"></i>
                    </a>
                </td>
            </tr>
        )
    }
}