import React from "react";

const { Component } = React;

export default class FormMaps extends Component {
    defaultCenter = {lat: 51.163375, lng: 10.447683333333};

    componentDidMount() {
        this.setState({
            address: this.props.address,
            postalcode: this.props.postalcode,
            locality: this.props.locality
        });
        this.loadMap();
    }


    loadMap() {
        this.map = new google.maps.Map(document.getElementById('map'), {
            center: (this.props.fieldLon.exportValue() && this.props.fieldLat.exportValue()) ? {lat: this.props.fieldLat.exportValue(), lng: this.props.fieldLon.exportValue()} : this.defaultCenter,
            zoom: ((this.props.fieldLon.value && this.props.fieldLat.value) ? 12 : 6)
        });
        this.geocoder = new google.maps.Geocoder();
        if (this.props.fieldLat.exportValue() && this.props.fieldLon.exportValue()) {
            return this.initMarker({lat: this.props.fieldLat.exportValue(), lng: this.props.fieldLon.exportValue()})
        }
        if (this.props.address) {
            window.setTimeout(() => this.geocodeAddress(), 100);
        }
    }

    geocodeAddress () {
        var address = $('#address').val() + ', ' + $('#locality').val() + ', ' + $("#country option:selected").text();
        this.geocoder.geocode({ 'address': address }, (results, status) => this.geocodeAddressResult(results, status));
    };

    geocodeAddressResult(results, status) {
        if (status !== 'OK') {
            return;
        }
        this.map.setCenter(results[0].geometry.location);
        this.map.setZoom(12);
        this.props.fieldLat.setValue(results[0].geometry.location.lat());
        this.props.fieldLon.setValue(results[0].geometry.location.lng());
        if (this.marker) {
             return this.marker.setPosition(results[0].geometry.location);
        }
        this.initMarker(results[0].geometry.location);
    };

    initMarker(location) {
        this.marker = new google.maps.Marker({
            map: this.map,
            position: location,
            draggable: true
        });
        google.maps.event.addListener(this.marker, 'dragend', (marker) => {
            this.props.fieldLat.setValue(marker.latLng.lat());
            this.props.fieldLon.setValue(marker.latLng.lng());
        });

    }

    componentDidUpdate() {
        if (this.props.address === this.state.address && this.props.postalcode === this.state.postalcode && this.props.locality === this.state.locality)
            return;
        this.setState({
            address: this.props.address,
            postalcode: this.props.postalcode,
            locality: this.props.locality
        });
        window.setTimeout(this.checkGeocodeAddressResult.bind(this, this.props.address, this.props.postalcode, this.props.locality), 200);
    }

    checkGeocodeAddressResult(address, postalcode, locality) {
        if (this.props.address !== address || this.props.postalcode !== postalcode || this.props.locality !== locality)
            return;
        this.geocodeAddress();
    }

    render() {
        return (
            <div className={`col-md-${this.props.width}`}>
                <div id="map"></div>
            </div>
        )
    }
}