import React from "react";

import FormInput from '../../FormField/FormInput';
import FormSelect from '../../FormField/FormSelect';
import ViewBase from "../../View";


export default class ViewStation extends ViewBase {
    state = {};
    obj = 'station';

    constructor(props) {
        super(props);
        this.state[this.obj] = props[this.obj];
        this.state.units = props.units;
        console.log(props.units)
    }

    render() {
        return(
            <div className="container">
                <div className="row row-form">
                    <div className="col-md-12">
                        <h2>
                            Ladestation{' '}
                            {!this.state.station.deleted &&
                                <a href="#" className="btn btn-default btn-icon" onClick={this.deleteSelf.bind(this)}>
                                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                                </a>
                            }
                            {this.state.station.deleted &&
                                <a href="#" className="btn btn-default btn-icon" onClick={this.undeleteSelf.bind(this)}>
                                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                                    <i className="fa fa-undo fa-ml" aria-hidden="true"></i>
                                </a>
                            }
                            {(this.state.station.units.length > 0 && this.state.station.units[0].fields.id.value) &&
                                <a href={`/unit/${this.state.station.units[0].fields.id.value}/move-station`} className="btn btn-default btn-icon">
                                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                </a>
                            }
                        </h2>
                        {this.state.station.deleted &&
                            <ul id="flash-messages">
                                <li className="alert alert-danger alert-dismissible show" role="alert">
                                    <i className="fa fa-trash-o"></i> Ladestation soll gelöscht werden.
                                </li>
                            </ul>
                        }
                        <p>
                            Eine Ladestation ist ein Gerät, an dem ein oder mehrere Ladepunkte (also Stecker / Buchsen)
                            angeboten werden. Normalerweise gibt es 1-3 Ladepunkte, bei komplexen Anlagen z.B. in
                            Parkhäusern können es aber auch deutlich mehr Anschlüsse werden. In der Station befindet
                            sich die Kommunikations-Einheit (SIM, LAN, ...) zum Backend.
                        </p>
                    </div>
                </div>
                <div className="row row-form">
                    <FormInput
                        width="6"
                        field={this.state.station.fields.uid}
                        label="Stations-ID"
                    />
                    <FormInput
                        width="6"
                        field={this.state.station.fields.name}
                        label="Interne Bezeichnung"
                    />
                </div>
                <div className="row row-form">
                    <FormSelect
                        width="4"
                        field={this.state.station.fields.public}
                        label="Sichtbarkeit"
                    />
                    <FormSelect
                        width="4"
                        field={this.state.station.fields.hardware}
                        label="Hardware"
                    />
                    <FormSelect
                        width="4"
                        field={this.state.station.fields.tech_backend}
                        label="Technisches Backend"
                    />
                </div>
            </div>
        );
    }
}
