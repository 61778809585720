import Base from '../Models/Base';
import { StringField } from '../Fields/StringField';
import {ObjectField} from "../Fields/ObjectField";
import {SelectField} from "../Fields/SelectField";
import {filterUppercase} from "../Filter";

import { ValidateDataRequired } from "../Validators";


export default class EvseGeneratorModel extends Base {
    fields = {};

    constructor(data) {
        super();
        this.fields.operatorField = new ObjectField({
            key: 'operator',
            validators: [new ValidateDataRequired()]
        });
        this.fields.newOperatorLocalityField = new StringField({
            key: 'operatorLocality'
        });
        this.fields.stationUidField = new StringField({
            key: 'stationUid',
            liveFilters: [filterUppercase]
        });
        this.fields.unitCountField = new SelectField({
            options: [{key: 1, label: '1'}, {key: 2, label: '2'}, {key: 3, label: '3'}],
            value: 2
        });
    }

    validate() {
        super.validate();
        this.fields.newOperatorLocalityField.isValid = true;
        if (this.isValid && this.fields.operatorField.value.id === 0 && (!this.fields.newOperatorLocalityField.value || this.fields.newOperatorLocalityField.value.length < 3)) {
            this.isValid = false;
            this.fields.newOperatorLocalityField.isValid = false;
        }
        return this.isValid;
    }
}
