import { daterangepicker_locale } from '../Constants';


export default class UnitManagement {
    constructor() {
        if ($('#unit-group-form, #unit-form').length) {
            $.when(window.googleMapsLoaded).then(this.initMap());
            $('#address, #locality').change(() => this.geocodeAddress());
        }
        if (document.getElementById('unit-group-form')) {
            let refund_select = $('#refund');
            refund_select.change(function () {
                if ($(this).is(':checked')) {
                    $('#refund-box').slideDown(400);
                }
                else {
                    $('#refund-box').slideUp(400);
                }
            });
            refund_select.trigger('change');
        }
        if ($('#unit-form').length) {
            let choice_select = $('#unit_group_choice');
            choice_select.change(function () {
                if (parseInt($(this).val()) === 1) {
                    $('#unit-group-existing').slideUp(400, function () {
                        $('#unit-group-new').slideDown();
                    });
                }
                else {
                    $('#unit-group-new').slideUp(400, function () {
                        $('#unit-group-existing').slideDown();
                    });
                }
            });
            choice_select.trigger('change');
        }
        if (document.getElementById('refund_start')) {
            let daterangepicker_locale = Object.assign({}, daterangepicker_locale);
            daterangepicker_locale.format = 'DD.MM.YYYY, hh:mm';
            $('#refund_start').daterangepicker({
                singleDatePicker: true,
                timePicker: true,
                timePicker24Hour: true,
                locale: daterangepicker_locale
            });
            $('#refund_end').daterangepicker({
                singleDatePicker: true,
                timePicker: true,
                timePicker24Hour: true,
                locale: daterangepicker_locale,
            });
        }
    }

    initMap() {
        let lat = $('#lat');
        let lon = $('#lon');
        let set_marker = false;
        let center = {lat: 51.163375, lng: 10.447683333333};
        if (lat.val() && lon.val() ) {
            center = {lat: parseFloat(lat.val()), lng: parseFloat(lon.val())};
            set_marker = true;
        }

        this.map = new google.maps.Map(document.getElementById('map'), {
            center: center,
            zoom: ((set_marker) ? 12 : 6)
        });
        this.geocoder = new google.maps.Geocoder();
        if (!(lat.val() && lon.val()) && $('#address').val() && $('#locality').val()) {
            this.geocode_address();
        }
        else if (set_marker) {
            this.marker = new google.maps.Marker({
                map: this.map,
                position: center
            });
        }
    };

    geocodeAddress () {
        var address = $('#address').val() + ', ' + $('#locality').val() + ', ' + $("#country option:selected").text();
        this.geocoder.geocode({ 'address': address }, (results, status) => this.geocodeAddressResult(results, status));
    };

    geocodeAddressResult(results, status) {
        if (status === 'OK') {
            $('#lat').val(results[0].geometry.location.lat);
            $('#lon').val(results[0].geometry.location.lng);
            this.map.setCenter(results[0].geometry.location);
            this.map.setZoom(12);
            if (this.marker) {
                 this.marker.setPosition(results[0].geometry.location);
            }
            else {
                this.marker = new google.maps.Marker({
                    map: this.map,
                    position: results[0].geometry.location
                });
            }
        }
    };
};