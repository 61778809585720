import React from "react";
const { Component } = React;


import FormSelect from "../../FormField/FormSelect";


export default class ViewOperator extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.state.unitGroupField = props.unitGroupField;
        this.state.pricegroupField = props.pricegroupField;
    }

    submit() {
        let update = { unit_group_id: this.state.unitGroupField.exportValue() }
        if (this.state.pricegroupField) {
            update.pricegroup_id = this.state.pricegroupField.exportValue();
        }
        this.props.setUnitGroup(update);
    }

    render() {
        return(
            <div>
                <div className="row">
                    <FormSelect
                        width="6"
                        field={this.state.unitGroupField}
                        label="Ladegruppe"
                    />
                    <div className="col-md-6">
                        <label>&nbsp;</label>
                        <input type="submit" value="speichern" className="form-control" onClick={this.submit.bind(this)} />
                    </div>
                </div>
                {!!this.state.pricegroupField && <div className="row">
                    <FormSelect
                        width="6"
                        field={this.state.pricegroupField}
                        label="Preisgruppe"
                    />
                </div>}
            </div>
        );
    }
}