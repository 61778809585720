import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Unit from '../../Models/Unit';

const { Component } = React;

export default class Sidebar extends Component {

    addUnit() {
        let unit = new Unit({});
        if (this.props.units.length) {
            if (this.props.units[this.props.units.length - 1].fields.uid.value) {
                unit.fields.uid.value = this.props.units[this.props.units.length - 1].fields.uid.value.substring(0, this.props.units[this.props.units.length - 1].fields.uid.value.length - 2)
            }
            unit.fields.pricegroup.value = this.props.units[this.props.units.length - 1].fields.pricegroup.value;
            unit.fields.plug.value = this.props.units[this.props.units.length - 1].fields.plug.value;
            unit.fields.power.value = this.props.units[this.props.units.length - 1].fields.power.value;
            unit.fields.phases.value = this.props.units[this.props.units.length - 1].fields.phases.value;
        }
        this.props.units.push(unit);
        this.props.setView('unit', unit.clientUid);
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const [removed] = this.props.units.splice(result.source.index, 1);
        this.props.units.splice(result.destination.index, 0, removed);
        this.props.triggerStationUpdate();
    }

    render() {
        return (
            <ul>
                <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <li ref={provided.innerRef}>
                                {this.props.units.map((unit, index) => (
                                    <Draggable key={unit.clientUid} draggableId={unit.clientUid} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                className={`${(this.props.viewType === 'unit' && this.props.viewClientUid === unit.clientUid) ? 'active' : ''} ${(!unit.isValid) ? 'has-invalid' : ''}`}
                                                onClick={this.props.setView.bind(this, 'unit', unit.clientUid)}
                                            >
                                                <span {...provided.dragHandleProps}>
                                                    <i className="fa fa-bars" aria-hidden="true"></i>
                                                </span>
                                                {(unit.fields.uid.value) ?
                                                    unit.fields.uid.value :
                                                    <span style={{fontStyle: 'italic'}}>unbenannt</span>
                                                }
                                                {unit.deleted &&
                                                    <span>
                                                        {' '}<i className="fa fa-trash-o" aria-hidden="true"></i>
                                                    </span>
                                                }
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </li>
                        )}
                    </Droppable>
                </DragDropContext>
                <li>
                    <div onClick={this.addUnit.bind(this)}>
                        <i className="fa fa-plus-circle" aria-hidden="true"></i>Neuer Ladepunkt
                    </div>
                </li>
            </ul>
        );
    }
}