import React from "react";
import io from 'socket.io-client';
import moment from 'moment';
import { formatDatetime } from '../Format';

const { Component } = React;
import UnitTccConfig from "./UnitTccConfig";
import UnitTccAdmin from "./UnitTccAdmin";


export default class UnitTcc extends Component {
    state = {
        initialized: false,
        loadError: false,
        configUpdating: [],
        configSaving: [],
        newConfigKey: '',
        newConfigValue: '',
        unitConfig: []
    };
    params = {};
    capabilities = [];


    componentDidMount() {
        moment.tz("2014-06-01 12:00", "Europe/Berlin");
        this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $('.btn-icon').tooltip();
    }

    init() {
        let box = document.getElementById('unit-tcc-box');
        this.params.unit = box.getAttribute('data-unit');
        this.params.csrf_token = box.getAttribute('data-csrf-token');
        this.socketUrl = box.getAttribute('data-socket-url');
        this.capabilities = box.getAttribute('data-capabilities').split(',');
        this.iban = box.getAttribute('data-iban');

        this.socket = io(this.socketUrl + '/unit/tcc');
        this.socket.on('connect', () => {
            this.socket.emit('init', this.params);
        });
        this.socket.on('base', (data) => {
            if (!data || data.status) {
                this.setState({
                    loadError: true,
                    initialized: true
                });
                return;
            }
            let update = {
                initialized: true
            };
            if (data.data.config) {
                update.unitConfig = [...data.data.config];
                delete data.data.config;
            }
            update.unitData = data.data;
            this.setState(update)
        });
        this.socket.on('update', (data) => {
            if (data.type === 'StatusNotification') {
                this.handleStatusNotification(data);
            }
            if (data.type === 'MeterValues') {
                this.handleMeterValues(data);
            }
        });
    }

    handleStatusNotification(data) {
        let unit_data = this.state.unitData;
        if (!unit_data)
            unit_data = {};
        unit_data.status = data.data.status;
        unit_data.error = data.data.errorCode;

        this.setState({
            unitData: unit_data
        });
    }

    handleMeterValues(data) {
        if (!data.data.meterValue || !data.data.meterValue.length || !data.data.meterValue[0].sampledValue || !data.data.meterValue[0].sampledValue.length || !data.data.meterValue[0].sampledValue[0].value)
            return;
        let unit_data = this.state.unitData;
        if (!unit_data)
            unit_data = {};
        unit_data.meter_value = parseFloat(data.data.meterValue[0].meterValue[0].value);
        this.setState({
            unitData: unit_data
        });
    }

    render() {
        if (!this.state.initialized) {
            return (
                <div>... wird geladen ...</div>
            )
        }
        if (this.state.loadError) {
            return (
                <div>Fehler: das technische Backend ist zur Zeit nicht erreichbar.</div>
            )
        }
        return [
            this.renderBaseData(),
            <UnitTccAdmin
                socket={this.socket}
                capabilities={this.capabilities}
                params={this.params}
                iban={this.iban}
                unitStatus={this.state.unitData.status}
                key="admin"
            />,
            <UnitTccConfig
                socket={this.socket}
                capabilities={this.capabilities}
                params={this.params}
                unitConfig={this.state.unitConfig}
                key="config"
            />
        ];
    }

    renderBaseData() {
        return (
            <div className="row row-form" key="base-data">
                <div className="col-md-12">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>EVSE-ID (UID)</td>
                                <td>{this.state.unitData.uid}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>
                                    {this.state.unitData.connection === 'online' &&
                                        this.formatUnitStatus(this.state.unitData.status)
                                    }
                                    {this.state.unitData.connection === 'offline' &&
                                        <span>offline</span>
                                    }
                                    {!!this.state.unitData.ip && <span>
                                        {' '}mit IP {this.state.unitData.ip}
                                    </span>}
                                </td>
                            </tr>
                            <tr>
                                <td>Fehler-Status</td>
                                <td>
                                    {this.formatUnitError(this.state.unitData.error)}
                                    {(this.state.unitData.error_vendor || this.state.unitData.error_code) && <span>(</span>}
                                    {this.state.unitData.error_vendor &&
                                        <span>{this.state.unitData.error_vendor}</span>
                                    }
                                    {(this.state.unitData.error_vendor && this.state.unitData.error_code) && <span>: </span>}
                                    {this.state.unitData.error_code &&
                                        <span>{this.state.unitData.error_code}</span>
                                    }
                                    {(this.state.unitData.error_vendor || this.state.unitData.error_code) && <span>(</span>}
                                </td>
                            </tr>
                            {this.state.unitData.boot_notification &&
                                <tr>
                                    <td>Weitere Daten</td>
                                    <td>
                                        {this.state.unitData.boot_notification.chargePointModel &&
                                            <span>Modell: {this.state.unitData.boot_notification.chargePointModel}<br/></span>
                                        }
                                        {this.state.unitData.boot_notification.chargePointSerialNumber &&
                                            <span>Seriennummer: {this.state.unitData.boot_notification.chargePointSerialNumber}<br/></span>
                                        }
                                        {this.state.unitData.boot_notification.chargePointVendor &&
                                            <span>Hersteller: {this.state.unitData.boot_notification.chargePointVendor}<br/></span>
                                        }
                                        {this.state.unitData.boot_notification.firmwareVersion &&
                                            <span>Firmware-Version: {this.state.unitData.boot_notification.firmwareVersion}<br/></span>
                                        }
                                        {this.state.unitData.boot_notification.iccid &&
                                            <span>ICCID: {this.state.unitData.boot_notification.iccid}<br/></span>
                                        }
                                        {this.state.unitData.boot_notification.imsi &&
                                            <span>IMSI: {this.state.unitData.boot_notification.imsi}<br/></span>
                                        }
                                        {this.state.unitData.boot_notification.meterSerialNumber &&
                                            <span>Zähler-Seriennummer: {this.state.unitData.boot_notification.meterSerialNumber}<br/></span>
                                        }
                                        {this.state.unitData.boot_notification.meterType &&
                                            <span>Zähler-Typ: {this.state.unitData.boot_notification.meterType}<br/></span>
                                        }
                                    </td>
                                </tr>
                            }
                            <tr>
                                <td>Letzte Aktualisierung</td>
                                <td>{formatDatetime(this.state.unitData.last_update)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    formatUnitStatus(value) {
        if (value === 'Available')
            return 'verfügbar';
        if (value === 'Preparing')
            return 'bereitet Ladevorgang vor';
        if (value === 'Charging')
            return 'Fahrzeug lädt';
        if (value === 'SuspendedEVSE')
            return 'Ladevorgang wurde Backend-seitig beendet, Fahrzeug noch angeschlossen';
        if (value === 'SuspendedEV')
            return 'Fahrzeug angeschlossen, lädt aber nicht (mehr)';
        if (value === 'Finishing')
            return 'Ladevorgang abgeschlossen, Fahrzeug aber noch angeschlossen';
        if (value === 'Reserved')
            return 'reserviert';
        if (value === 'Unavailable')
            return 'nicht verfügbar';
        if (value === 'Faulted')
            return 'Fehler aufgetreten';
        return value;
    }

    formatUnitError(value) {
        if (value === 'NoError')
            return 'kein Fehler';
        if (value === 'ConnectorLockFailure')
            return 'Fehler in der Verriegelung';
        if (value === 'EVCommunicationError')
            return 'Fehler in der Kommunikation zwischen Säule und Fahrzeug';
        if (value === 'GroundFailure')
            return 'Erdungs-Fehler';
        if (value === 'HighTemperature')
            return 'Fehler durch zu hohe Temperatur';
        if (value === 'InternalError')
            return 'Software-Fehler in der Ladeäule';
        if (value === 'LocalListConflict')
            return 'Fehler durch einen Autorisierungs-Listen-Konflikt';
        return value;
    }
}