
export class Field {
    isValid = true;
    validators = [];
    liveFilters = [];
    exportFilters = [];
    initFilters = [];
    errors = [];

    load(params) {
        this.key = params.key;
        this.validationGroup = params.validationGroup;
        if (params.validators)
            this.validators.push(...params.validators);
        if (params.liveFilters)
            this.liveFilters.push(...params.liveFilters);
        if (params.exportFilters)
            this.exportFilters.push(...params.exportFilters);
        if (params.initFilters)
            this.initFilters.push(...params.initFilters);
        this.initValue(params.value);
    }

    validate() {
        this.isValid = true;
        this.errors = [];
        for (let i = 0; i < this.validators.length; i++) {
            let result = this.validators[i].validate(this.value);
            if (result === 'break') {
                this.isValid = true;
                this.errors = [];
                return true;
            }
            if (!result)
                this.errors.push(this.validators[i].getMessage());
            this.isValid = this.isValid && result;
        }
        return this.isValid;
    }

    initValue(value) {
        for (let i = 0; i < this.initFilters.length; i++) {
            value = this.initFilters[i](value);
        }
        this.value = value;
        this.originalValue = value;
    }

    setValue(value) {
        for (let i = 0; i < this.liveFilters.length; i++) {
            value = this.liveFilters[i](value);
        }
        this.value = value;
        this.validate();
    }

    exportValue() {
        let value = this.value;
        for (let i = 0; i < this.exportFilters.length; i++) {
            value = this.exportFilters[i](value);
        }
        return value;
    }

    getHasChanged() {
        return this.value !== this.originalValue;
    }
}