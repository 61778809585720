import Base from './Base';
import { DecimalField } from '../Fields/DecimalField';
import { IntegerField } from '../Fields/IntegerField';
import { StringField } from '../Fields/StringField';

import { ValidateDataRequired, ValidateOptionalIf, ValidateOneOf, ValidateFloat, ValidateOptional} from "../Validators";

export default class Pricegroup extends Base {
    fields = {
        id: new IntegerField({
            key: 'id',
            validators: [new ValidateOptional()]
        }),
        name: new StringField({
            key: 'name'
        }),
        fee_energy: new DecimalField({
            key: 'fee_energy'
        }),
        fee_rent: new DecimalField({
            key: 'fee_rent'
        })
    };

    constructor(data) {
        super();
        this.load(data);
    }
}
