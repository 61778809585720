import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableToken extends SearchTable {
    params = {
        sort_field: 'created',
        sort_order: 'desc',
        page: 1
    };
    formId = 'token-search-form';
    varPrefix = 'searchTableToken';
    loadParamsRegExp = [
        /\/token\/(.*)/g
    ];

    apiUrl = '/api/tokens';
    sortDef = [
        { key: 'type', name: 'Typ' },
        { key: 'identifier', name: 'Identifier'},
        { key: 'created', name: 'Erstellt' }
    ];

    colDef = [
        { sortField: 'identifier', text: 'Identifier' },
        { sortField: 'created', text: 'Erstellt' },
        { text: 'Konto'},
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr key={`token-${row.id}`}>
                {this.renderTableCellToken(row)}
                {this.renderTableCellCreated(row)}
                {this.renderTableCellAccount(row.account)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellStatus(row) {
        return(
            <td>
                {this.renderStatusIcon('eye', row.registered_ui)}
                {this.renderStatusIcon('ban', row.restricted)}
            </td>
        )
    }

    renderTableCellToken(row) {
        let meta_line = this.derefTokenType(row.type);
        if (row.type === 'girocard') {
            meta_line += ' mit Ablaufdatum ' + this.formatDate(row.expiration) + ' und Folgenummer ' + row.sequence_number;
        }
        return(
            <td>
                {this.showTokenIdentifier(row.identifier, row.type)}<br/>
                <small>{meta_line}</small>
            </td>
        )
    }

    derefTokenType(type) {
        if (type === 'girocard')
            return 'Girocard';
        if (type === 'static')
            return 'RFID-Karte';
        if (type === 'emobilitycard')
            return 'Sichere RFID-Karte';
        return type;
    }

    showTokenIdentifier(identifier, type) {
        if (type === 'girocard') {
            return identifier.substr(0, 3) + ' ' + identifier.substr(3, 5) + ' ' + identifier.substr(8);
        }
        if (['static', 'emobilitycard'].includes(type)) {
            return identifier.replace(/(\S{2})/g, '$1 ').replace(/(^\s+|\s+$)/,'')
        }
        return identifier;
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/token/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/token/${row.id}/edit`, 'edit', 'bearbeiten', this.capabilities.includes('edit'))}
            </td>
        )
    }
}