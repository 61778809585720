import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableManufacturer extends SearchTable {
    params = {
        sort_field: 'name',
        sort_order: 'asc',
        page: 1
    };
    apiUrl = '/api/manufacturers';
    formId = 'manufacturer-search-form';
    varPrefix = 'searchTableManufacturer';
    loadParamsRegExp = [
        /\/manufacturer\/(.*)/g
    ];

    sortDef = [
        { key: 'name', name: 'Name'},
        { key: 'created', name: 'Erstellt' },
        { key: 'modified', name: 'Bearbeitet' }
    ];

    colDef = [
        { sortField: 'text', text: 'Name' },
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr key={`manufacturer-${row.id}`}>
                {this.renderTableCellName(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellName(row) {
        return(
            <td>
                {row.name}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/manufacturer/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/manufacturer/${row.id}/edit`, 'pencil', 'bearbeiten', true)}
                {this.renderTableCellActionIcon(`/manufacturer/${row.id}/delete`, 'trash', 'löschen', true)}
            </td>
        )
    }
}