import React from "react";
import {Decimal} from 'decimal.js';
import { daterangepicker_ranges, daterangepicker_locale } from "../Constants";
import moment from 'moment';
import {formatPrice} from "../Format";

const { Component } = React;

export default class OrderStatistics extends Component {
    formId = 'order-statistics-product-form';
    state = {
        initialized: false
    };
    params = {
        daterange: moment().subtract(1, 'days').format('DD.MM.YYYY') + ' - ' + moment().subtract(1, 'days').format('DD.MM.YYYY'),
    };
    remoteUrl = '/api/statistics/order/products';

    componentDidMount () {
        this.init();
        this.updateData();
    };

    init() {
        this.params.csrf_token = $('#csrf_token').val();
        let start = moment().subtract(1, 'days');
        let end = moment().subtract(1, 'days');
        $('#daterange').daterangepicker({
            startDate: start,
            endDate: end,
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, (start, end, label) => this.daterangepickerSubmit(start, end, label));
        $('#' + this.formId + ' select').change((event) => this.formSubmit(event));
        document.getElementById(this.formId).onsubmit = (event) => this.formSubmit(event)
    }

    daterangepickerSubmit(start, end, label) {
        this.params.daterange = start.format('DD.MM.YYYY') + ' - ' + end.format('DD.MM.YYYY');
        this.updateData();
    }

    formSubmit(event) {
        event.preventDefault();
        this.updateParams();
    }

    updateParams() {
        let operator_id = parseInt($('#operator').val());
        if (operator_id)
            this.params.operator = operator_id;
        else {
            delete this.params.operator;
        }
        this.updateData();
    }

    updateData() {
        $.post(this.remoteUrl, this.params)
            .then(data => {
                this.setState({
                    count: data.count,
                    initialized: true,
                    aggs: data.aggs
                });
            });
    };


    render () {
        if (!this.state.initialized) {
            return (
                <div className={'search-table-loading'}>
                    ... wird geladen ...
                </div>
            );
        }

        let count = 0;
        let sum = new Decimal(0);
        let rows = [];
        for (let i = 0; i < this.state.aggs.products.length; i++) {
            let item = this.state.aggs.products[i];
            rows.push(
                <tr>
                    <td>{item.key}</td>
                    <td className="col-right">{item.amount.value}</td>
                    <td className="col-right">{formatPrice(new Decimal(item.value_total.value))}</td>
                </tr>
            );
            count += item.amount.value;
            sum = sum.plus(new Decimal(item.value_total.value));
        }
        rows.push(
            <tr>
                <th>Summe</th>
                <th className="col-right">{count}</th>
                <th className="col-right">{formatPrice(sum)}</th>
            </tr>
        );

        return (
            <table className="table table-striped table-bordered">
                <thead>
                <tr>
                    <th>Produkt</th>
                    <th className="col-right">Anzahl</th>
                    <th className="col-right">Umsatz</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        )
    };

};