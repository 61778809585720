import { Field } from './Field';
import {filterDate, filterIsoDate} from "../Filter";
import {ValidateDate} from "../Validators";

export class DateField extends Field {
    exportFilters = [ filterIsoDate ];
    initFilters = [ filterDate ];
    validators = [ new ValidateDate() ];

    constructor(params) {
        super();
        this.load(params);
    }
}