import { Field } from './Field';
import { filterString } from "../Filter";

export class ObjectField extends Field {

    constructor(params) {
        super();
        this.load(params);
    }

    exportValue() {
        if (!this.value)
            return {}
        return this.value;
    }
}
