import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableUnitGroup extends SearchTable {
    params = {
        sort_field: 'locality',
        sort_order: 'asc',
        page: 1
    };
    formId = 'unit-group-search-form';
    varPrefix = 'searchTableUnitGroup';
    loadParamsRegExp = [
        /\/unit-group\/(.*)/g,
        /\/unit\/(.*)/g
    ];

    apiUrl = '/api/unit-groups';
    sortDef = [
        { key: 'locality', name: 'Ort' },
        { key: 'created', name: 'Erstellungsdatum' }
    ];

    colDef = [
        { sortField: 'locality', text: 'Ort' },
        { text: 'Betreiber' },
        { text: 'Ladepunkte' },
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr key={`unit-group-${row.id}`}>
                {this.renderTableCellLocation(row)}
                {this.renderTableCellOperator(row)}
                {this.renderTableCellUnitCount(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellLocation(row) {
        return(
            <td>
                {!!row.name && <span>{row.name}<br/></span>}
                {row.address}, {row.postalcode} {row.locality}
            </td>
        )
    }

    renderTableCellUnitCount(row) {
        let stations = {};
        row.unit.forEach(unit => {
            if (!stations[unit.uid_station])
                stations[unit.uid_station] = {
                    uid: unit.uid_station,
                    name: (unit.name_station) ? unit.name_station : unit.uid_station,
                    units: []
                };
            stations[unit.uid_station].units.push(unit);
        })
        /*
        for (let i = 0; i < row.unit.length; i++) {
            units.push(
                <a key={`unit-${row.unit[i].id}`} href={'/unit/' + row.unit[i].id + '/show'}>
                    {this.formatUnitUid(row.unit[i].uid)}
                </a>
            );
            if (i < row.unit.length - 1) {
                units.push(<span key={`separator-${i}`}>, </span>);
            }

        }*/
        return (
            <td>
                {Object.values(stations).sort((a, b) => {
                    if ((((a.name) ? a.name : '') + a.uid).toUpperCase() < (((b.name) ? b.name : '') + b.uid).toUpperCase())
                        return -1;
                    return 1;
                }).map(station => {
                    return(
                        <div key={`station${station.uid}`}>
                            {station.name}: {' '}
                            <ul style={{marginBottom: '3px'}}>
                                {station.units.map((unit, i) => {
                                    return (
                                        <li key={`unit-${unit.uid}`}>
                                            {unit.uid}{' '}
                                            {(unit.tech_backend === 'tcc' && this.capabilities.includes('regular-operator')) &&
                                                <a href={`/unit/${unit.id}/tcc/status`} className="btn btn-default btn-icon btn-icon-mini">
                                                    <i className="fa fa-wrench" aria-hidden="true"></i>
                                                </a>
                                            }
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/unit-group/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/unit-group/${row.id}/edit`, 'edit', 'bearbeiten', this.capabilities.includes('edit'))}
                {this.renderTableCellActionIcon(`/unit-group/${row.id}/delete`, 'trash', 'löschen', this.capabilities.includes('delete'))}
                {this.renderTableCellActionIcon(row.goingelectric_url, 'link', 'auf GoingElectric.de aufrufen', row.goingelectric_url)}
            </td>
        )
    }
}