import React from "react";
import SearchTable from './SearchTable'
import {formatPrice} from "../Format";

export default class SearchTablePricegroup extends SearchTable {
    params = {
        sort_field: 'name',
        sort_order: 'asc',
        page: 1
    };
    formId = 'product-search-form';
    varPrefix = 'searchTableProduct';
    loadParamsRegExp = [
        /\/product\/(.*)/g
    ];

    apiUrl = '/api/products';
    sortDef = [
        { key: 'name', name: 'Name' },
        { key: 'value_total', name: 'Preis'},
        { key: 'sequence_weight', name: 'Gewichtung'},
        { key: 'created', name: 'Erstellungszeitpunkt' },
        { key: 'modified', name: 'Änderungszeitpunkt' }
    ];

    colDef = [
        { sortField: 'name', text: 'Name' },
        { text: 'Betreiber' },
        { sortField: 'value_total', text: 'Preis' },
        { text: 'Status'},
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr key={`product-${row.id}`}>
                {this.renderTableCellName(row)}
                {this.renderTableCellOperator(row)}
                {this.renderTableCellPrice(row)}
                {this.renderTableCellStatus(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellName(row) {
        return(
            <td>
                {row.name}
            </td>
        )
    }

    renderTableCellPrice(row) {
        return (
            <td>
                {formatPrice(row.value_total)}
            </td>
        )
    }

    renderTableCellStatus(row) {
        return (
            <td>
                {this.renderStatusIcon('eye', row.active)}
                {this.renderStatusIcon('eye-slash', !row.active)}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/product/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/product/${row.id}/edit`, 'edit', 'bearbeiten', this.capabilities.includes('edit'))}
                {this.renderTableCellActionIcon(`/product/${row.id}/delete`, 'trash', 'löschen', this.capabilities.includes('delete'))}
            </td>
        )
    }
}