import { Field } from './Field';
import { filterString } from "../Filter";

export class StringField extends Field {
    initFilters = [ filterString ];

    constructor(params) {
        super();
        this.load(params);
    }
}