import React from "react";
import { Bar, defaults } from 'react-chartjs-2';
import merge from 'lodash.merge';
import moment from 'moment';
import { daterangepicker_locale, daterangepicker_ranges } from "../Constants";

const { Component } = React;

export default class StatisticsGraph extends Component {
    default = {
        start: moment().subtract(1, 'month'),
        end: moment()
    };
    state = {
        aggs: {},
        initialized: false
    };
    params = {
        daterange: this.default.start.format('DD.MM.YYYY') + ' - ' + this.default.end.format('DD.MM.YYYY')
    };
    apiUrl = '';
    formId = '';
    varPrefix = '';
    formEnabled = true;

    componentDidMount() {
        this.init();
        this.updateData();
    }

    init() {
        merge(defaults, {
            global: {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {
                                fontSize: 14,
                            },
                            ticks: {
                                beginAtZero: true,
                                fontSize: 14
                            }
                        }
                    ],
                    xAxes: [
                        {
                            scaleLabel: {
                                fontSize: 14,
                            },
                            ticks: {
                                fontSize: 14
                            }
                        }
                    ]
                },
                tooltips: {
                    titleFontSize: 14,
                    bodyFontSize: 14
                }
            },
        });
        this.params.csrf_token = $('#csrf_token').val();
        if (!this.formEnabled)
            return;
        $('#' + this.formId + ' select').change((event) => this.formSubmit(event));
        document.getElementById(this.formId).onsubmit = (event) => this.formSubmit(event);

        $('#daterange').daterangepicker({
            startDate: this.default.start,
            endDate: this.default.end,
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, (start, end, label) => this.daterangepickerSubmit(start, end, label));
    }

    updateData() {
        if (this.formEnabled)
            this.saveParams();
        let send_params = [];
        for (let key of Object.keys(this.params)) {
            if (Array.isArray(this.params[key])) {
                for (let i = 0; i < this.params[key].length; i++) {
                    send_params.push({
                        'name': key,
                        'value': this.params[key][i]
                    });
                }
            } else {
                send_params.push({
                    'name': key,
                    'value': this.params[key]
                });
            }
        }
        this.postData(send_params)
    }

    postData(send_params) {
        $.post(this.apiUrl, send_params)
            .then(data => {
                if (data.status)
                    return;

                this.setState({
                    aggs: data.aggs,
                    initialized: true,
                });
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $(".selectpicker").selectpicker('refresh');
        $('.btn-icon').tooltip()
    }

    formSubmit(event) {
        event.preventDefault();
        this.updateParams();
        this.updateData();
    }

    daterangepickerSubmit(start, end, label) {
        this.params.page = 1;
        this.updateParams({
            daterange: start.format('DD.MM.YYYY') + ' - ' + end.format('DD.MM.YYYY')
        });
        this.updateData();
    }

    updateParams(overwrite) {
        if (!overwrite) {
            overwrite = {};
        }
        let ids = [];
        $('#' + this.formId + ' select, #' + this.formId + ' input, #' + this.formId + ' textarea').each(function () {
            if ($(this).attr('id')) {
                ids.push($(this).attr('id'));
            }
        });
        for (let i = 0; i < ids.length; i++){
            let item = $('#' + ids[i]);
            if (item.attr('name') === 'csrf_token')
                continue;
            if (item.attr('name') === 'submit')
                continue;
            if (Object.keys(overwrite).includes(item.attr('name'))) {
                this.params[item.attr('name')] = overwrite[item.attr('name')];
                continue;
            }
            if (item.val() && item.val() !== '_default' && item.val() !== '_all') {
                if (item.is(':checkbox')) {
                    this.params[item.attr('name')] = item.is(":checked")
                } else {
                    this.params[item.attr('name')] = item.val();
                }
                continue;
            }
            delete this.params[item.attr('name')];
        }

    }

    saveParams() {
        if (!window.common.storageAvailable)
            return;
        localStorage.setItem(this.varPrefix + 'Data', JSON.stringify(this.params))
    }

    render() {
        if (!this.state.initialized) {
            return (
                <div className="search-table-loading col-md-12">
                    ... wird geladen ...
                </div>
            );
        }
        this.chartjs_data.datasets[0].data = this.prepareData();
        return (
            <div className="col-md-12">
                {this.renderTitle()}
                <div className={'statistics-box'}>
                    <Bar
                        data={this.chartjs_data}
                        height={500}
                        options={this.chartjs_options}
                    />
                </div>
            </div>
        )
    }

    renderTitle() {

    }

    prepareData() {
        let data = [0, 0, 0, 0, 0, 0, 0];
        if (!this.state.aggs.weekday)
            return data;

        for (let i = 0; i < this.state.aggs.weekday.length; i++) {
            data[this.state.aggs.weekday[i].key - 1] = this.state.aggs.weekday[i].doc_count;
        }
        return data;
    }
}

