import React from "react";
const { Component } = React;

import FormSelect from "../../FormField/FormSelect";

export default class ViewOperator extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.state.operatorField = props.operatorField;
    }

    submit() {
        this.props.setOperator(this.state.operatorField.exportValue());
    }

    render() {
        return(
            <div className="row">
                <FormSelect
                    width="6"
                    field={this.state.operatorField}
                    label="Betreiber"
                />
                <div className="col-md-6">
                    <label>&nbsp;</label>
                    <input type="submit" value="weiter" className="form-control" onClick={this.submit.bind(this)} />
                </div>
            </div>
        );
    }
}