
class BaseValidator {
    message = 'Fehler';

    getMessage() {
        return this.message;
    }
}

export class ValidateEmail extends BaseValidator {
    message = 'Invalide E-Mail';

    validate(value) {
        if (!value)
            return true;
        return value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null;
    }
}

export class ValidateUrl extends BaseValidator {
    message = 'Invalide URL';

    validate(value) {
        if (!value)
            return true;
        return value.match(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=+$,\w]+@)?[A-Za-z0-9.\-]+|(?:www\.|[\-;:&=+$,\w]+@)[A-Za-z0-9.\-]+)((?:\/[+~%\/.\w\-_]*)?\??(?:[\-+=&;%@.\w_]*)#?(?:[.!\/\\\w]*))?)/) !== null;
    }
}

export class ValidateLength extends BaseValidator {
    message = 'Invalide Länge'

    constructor(limits) {
        super();
        this.limits = limits;
    }

    validate(value) {
        if (!value)
            return false;
        if (this.limits.max && value > this.limits.max)
            return false;
        if (this.limits.min && value < this.limits.min)
            return false;
        return true;
    }
}

export class ValidateOneOf extends BaseValidator {
    getMessage() {
        return 'Muss eine der folgenden Werte sein: ' + this.allowedValues.join(', ');
    }

    constructor(allowedValues) {
        super();
        this.allowedValues = allowedValues;
    }

    validate(value) {
        return this.allowedValues.includes(value);
    }
}

export class ValidateNoneOf extends BaseValidator {
    getMessage() {
        return 'Darf nicht eine der folgenden Werte sein: ' + this.allowedValues.join(', ');
    }

    constructor(disallowedValues) {
        super();
        this.disallowedValues = disallowedValues;
    }

    validate(value) {
        return !this.disallowedValues.includes(value);
    }
}

export class ValidateDataRequired extends BaseValidator {
    message = 'Wird benötigt';

    validate(value) {
        return !!value
    }
}

export class ValidateInteger extends BaseValidator {
    message = 'Muss eine ganze Zahl sein';

    validate(value) {
        return String(parseInt(value)) === value;
    }
}

export class ValidateFloat extends BaseValidator {
    message = 'Muss eine Zahl sein';

    validate(value) {
        return !isNaN(String(value).replace(',', '.'))
    }
}

export class ValidateOptional extends BaseValidator {
    validate(value) {
        if (!value)
            return 'break';
        return true;
    }
}

export class ValidateOptionalIf extends BaseValidator {
    message = 'Wird benötigt';

    constructor(condition) {
        super();
        this.condition = condition;
    }

    validate(value) {
        if (this.condition() && !value)
            return 'break';
        return true;
    }
}

export class ValidateDate extends BaseValidator {
    message = 'Muss ein Datum im Format dd.mm.yyyy sein'

    constructor(condition) {
        super();
    }

    validate(value) {
        return value instanceof Date;
    }
}
