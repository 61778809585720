import React from "react";
import moment from 'moment';
import { Bar, Line, defaults } from 'react-chartjs-2';
import StatisticsGraph from "./StatisticsGraph";
import {formatEnergy, formatPrice} from "../Format";
import { multiselect_search_options } from '../Constants';
export default class ChargeDiagrams extends StatisticsGraph {

    apiUrl = '/api/charge_diagram';
    formId = 'charge-diagram-form';
    varPrefix = 'chargeDiagrams';
    formEnabled = true;
    state = Object.assign({}, this.state, {
        diagram_data: null,
        comparison_data: null,
        x_axis: null,
        y_axis: null,
        diagram_type: 'bar'
    })
    params = Object.assign({}, this.params, {x_axis: 'day', y_axis: 'value_subtotal'})

    get_chartjs_options() {
        if(!this.state.diagram_data) {
            return {}
        }
        return {
            scales: {
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: $('#y_axis option:selected').text()
                        },
                        ticks: {
                            beginAtZero: true,
                            callback: (value, index, values) => {
                                if (this.state.y_axis === 'value_total' || this.state.y_axis === 'value_subtotal') {
                                    return formatPrice(value);
                                }
                                if (this.state.y_axis === 'energy') {
                                    return formatEnergy(value)
                                }
                                return value
                            }
                        }
                    }
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: $('#x_axis option:selected').text()
                        }
                    }
                ]
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => this.renderTooltipLabel(tooltipItem, data)
                }
            }
        };
    }

    get_chartjs_data() {
        if(!this.state.diagram_data) {
            return {}
        }
        const result = {
            "labels": this.get_x_labels(),
            "datasets": [this.create_dataset(
                this.get_data_values(this.state.diagram_data),
                '',
                'rgba(54, 162, 235, 0.2)',
                'rgb(54, 162, 235)')
            ]
        };
        if (this.state.comparison_data) {
            result.datasets.push(this.create_dataset(
                this.get_data_values(this.state.comparison_data),
                'Vergleichsdaten',
                'rgba(54, 162, 100, 0.2)',
                'rgb(54, 162, 100)')
            )
        }

        return result
    }

    get_data_values(data) {
        if (this.state.y_axis === 'count') {
            return data.map(agg_result => agg_result.doc_count)
        }
        return data.map(agg_result => agg_result.charge.value)
    }


    create_dataset(data, label, backgroundColor, borderColor) {
        return{
            data,
            label,
            "fill": false,
            "borderWidth": 1,
            backgroundColor,
            borderColor
        }
    }

    get_x_labels() {
        return this.state.diagram_data.map( (agg_result) => {
            const date = moment(agg_result.key_as_string)
            switch (this.state.x_axis) {
                case 'hour':
                    return date.format('DD.MM. HH:MM')
                case 'day':
                    return date.format('DD.MM.')
                case 'week':
                    return 'KW ' + date.format('WW')
                case 'month':
                    return date.format('MM.YYYY')
            }
        })
    }



    init() {
        const multiselectFields = $('#units, #unit_groups, #units_2, #unit_groups_2, #operators, #operators_2');
        multiselectFields.multiselect(multiselect_search_options);
        $("#compare").change((event) => {
            $('#compare_criteria').slideToggle();
            this.formSubmit(event)
        });

        super.init()
    }

    renderTooltipLabel(tooltipItem, data) {
        if (this.state.y_axis === 'value_total' || this.state.y_axis === 'value_subtotal') {
            return formatPrice(tooltipItem.yLabel);
        }
        if (this.state.y_axis === 'energy') {
            return formatEnergy(tooltipItem.yLabel)
        }
        return tooltipItem.yLabel
    }


    postData(sendParams) {
        $.post(this.apiUrl, sendParams)
            .then(data => {
                if (data.status)
                    return;

                this.setState({
                    diagram_data: data.data.diagram_data.end,
                    comparison_data: data.data.comparison_data && data.data.comparison_data.end,
                    x_axis: data.data.x_axis,
                    y_axis: data.data.y_axis,
                    initialized: true,
                });
            });
    }

    render() {
        if (!this.state.initialized) {
            return (
                <div className="search-table-loading col-md-12">
                    ... wird geladen ...
                </div>
            );
        }
        return (
            <div>
                <div className='row'>
                    <div className='col-md-3'>
                        <label for='diagram_type'>Diagrammart</label>
                        <select id='diagram_type' className='form-control' value={this.state.diagram_type} onChange={(e) => this.setState({diagram_type: e.target.value})}>
                            <option value='bar'>Balkendiagramm</option>
                            <option value='line'>Liniendiagramm</option>
                        </select>
                    </div>
                    <div className='col-md-9'>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className="col-md-12">
                        <div className={'statistics-box'}>
                            {this.state.diagram_type === 'bar' ? <Bar
                                data={this.get_chartjs_data()}
                                height={500}
                                options={this.get_chartjs_options()}
                            /> :  <Line
                                data={this.get_chartjs_data()}
                                height={500}
                                options={this.get_chartjs_options()}
                            />}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}