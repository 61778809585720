import React from "react";
import FormField from './FormField';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

export default class FormInput extends FormField {
    state = {
        isLoading: false,
        value: '',
        options: []
    };
    url = '/api/unit/operators';

    constructor(props) {
        super(props);
        this.state.field = props.field;
    }


    updateOptions(query) {
        if (query.length < 4) {
            this.updateValue([{id: 0, label: query}]);
            this.setState({options: [{id: 0, label: query}]});
            return;
        }
        this.setState({isLoading: true});
        $.getJSON(this.url, {search: query}, (result) => {
            this.updateValue([{id: 0, label: query}]);
            this.setState({
                isLoading: false,
                options: [{id: 0, label: query}, ...result]
            });
        });
    }

    updateValue(value) {
        if (!value)
            return;
        if (!value.length)
            return;
        this.state.field.setValue(value[0]);
        this.setState({ field: this.state.field});
        if (this.props.updateParent)
            this.props.updateParent(value[0]);
    }

    render() {
        return (
            <div className={`col-md-${this.props.width}`}>
                <label htmlFor={this.props.field.key}>{this.props.label}</label>
                {(!this.props.unit) ?
                    this.renderInput() :
                    <span className={`unit unit-${this.props.unit}`}>{this.renderInput()}</span>
                }
            </div>
        )
    }

    renderInput() {
        return (
            <AsyncTypeahead
                id={`${this.props.field.key}-typeahead`}
                isLoading={this.state.isLoading}
                align={'left'}
                isInvalid={!this.state.field.isValid}
                onSearch={this.updateOptions.bind(this)}
                options={this.state.options}
                onChange={this.updateValue.bind(this)}
            />
        );
    }
}
