import { Field } from './Field';
import {filterEnsureList} from "../Filter";

export class ListField extends Field {
    value = [];
    initFilters = [ filterEnsureList ];

    constructor(params) {
        super();
        this.load(params);
    }
}