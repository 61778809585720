import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableOperator extends SearchTable {
    params = {
        sort_field: 'name',
        sort_order: 'asc',
        page: 1
    };
    formId = 'operator-search-form';
    varPrefix = 'searchTableOperator';
    loadParamsRegExp = [
        /\/operator\/(.*)/g
    ];

    apiUrl = '/api/operators';
    sortDef = [
        { key: 'name', name: 'Name' },
        { key: 'created', name: 'Erstellungsdatum' },
        { key: 'modified', name: 'Änderungsdatum' }
    ];

    colDef = [
        { sortField: 'name', text: 'Name' },
        { text: 'Adresse' },
        { text: '# Ladepunkte' },
        { text: 'Status'},
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr key={`operator-${row.id}`}>
                {this.renderTableCellName(row)}
                {this.renderTableCellAddress(row)}
                {this.renderTableCellUnits(row)}
                {this.renderTableCellStatus(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellName(row) {
        return(
            <td>
                {row.name}<br/>
                <small>{this.formatOperatorType(row.operator_type)}</small><br/>
                {row.account_iban && <small>{this.formatAccountIBAN(row.account_iban)}</small>
                }
            </td>
        )
    }

    renderTableCellAddress(row) {
        return (
            <td>
                {row.address}, {row.postalcode} {row.locality}
            </td>
        )
    }

    renderTableCellUnits(row) {
        return (
            <td>
                {row.unit_count}
            </td>
        )
    }

    renderTableCellStatus(row) {
        return (
            <td>
                {this.renderStatusIcon('eur', row.billing)}
            </td>
        );
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/operator/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/operator/${row.id}/edit`, 'edit', 'bearbeiten', true)}
                {this.renderTableCellActionIcon(`/operator/${row.id}/delete`, 'trash', 'löschen', true)}
                {this.renderTableCellActionIcon(`/operator/${row.id}/users`, 'users', 'Nutzer', true)}
                {this.renderTableCellActionIcon(`/operator/${row.id}/customer`, 'shopping-cart', 'Kunden-Einstellungen', true)}
                {this.renderTableCellActionIcon(`/operator/${row.id}/invoice-designer`, 'paint-brush', 'Rechnungsdesigner', true)}
                {this.renderTableCellActionIcon(`/operator/${row.id}/invoice-number`, 'file-text-o', 'Rechnungsnummern', true)}
                {this.renderTableCellActionIcon(`/operator/${row.id}/related-operators`, 'handshake-o', 'Verbundene Betreiber', false)}
                {this.renderTableCellActionIcon(`/operator/${row.id}/user-switch`, 'arrow-right', 'In Admin-Nutzeransicht wechseln', true)}
            </td>
        )
    }

    formatOperatorType(value) {
        if (value === 'regular')
            return 'Regulärer Betreiber';
        if (value === 'employee')
            return 'Mitarbeitende*r';
        if (value === 'light')
            return 'Sub-Betreiber';
    }
}