import React from "react";
import moment from 'moment';
import { daterangepicker_ranges, daterangepicker_locale } from "../Constants";
import SearchTable from './SearchTable'
import {formatPrice} from "../Format";

export default class SearchTableInvoice extends SearchTable {
    params = {
        sort_field: 'created',
        sort_order: 'desc',
        daterange: moment().startOf('month').format('DD.MM.YYYY') + ' - ' + moment().endOf('month').format('DD.MM.YYYY'),
        page: 1
    };
    formId = 'invoice-search-form';
    varPrefix = 'searchTableInvoice';
    loadParamsRegExp = [
        /\/invoice\/(.*)/g
    ];

    apiUrl = '/api/invoices';
    sortDef = [
        { key: 'created', name: 'Erstellt' },
        { key: 'value', name: 'Umsatz'}
    ];
    colDef = [
        { sortField: 'created', text: 'Datum' },
        { text: 'Betreiber' },
        { sortField: 'value', text: 'Umsatz' },
        { text: 'Status' },
        { text: 'Aktionen' }
    ];

    init() {
        super.init();
        this.operatorEnabled = true;
        $('#daterange').daterangepicker({
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month'),
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, (start, end, label) => this.daterangepickerSubmit(start, end, label));
    }
    
    renderTableRow(row) {
        return (
            <tr key={`invoice-${row.id}`}>
                {this.renderDate(row)}
                {this.renderTableCellOperator(row)}
                {this.renderTableCellValuePrice(row)}
                {this.renderStatus(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderDate(row) {
        return(
            <td>
                {this.formatDate(row.created)}
            </td>
        )
    }

    renderTableCellAccount(row) {
        return (
            <td>
                <a href={'/operator/' + row.operator.id + '/show'}>{row.operator.name}</a>
            </td>
        )
    }

    renderTableCellValuePrice(row) {
        return(
            <td>
                {formatPrice(row.value_total)}<br/>
                <small>Netto: {formatPrice(row.value_subtotal)}</small>
            </td>
        )
    }

    renderStatus(row) {
        return (
            <td>
                {this.renderStatusIcon('eye', row.seen_by_user_at)}
                {this.renderStatusIcon('ban', row.rejected)}
                {this.renderStatusIcon('check-circle', row.completed)}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon('/invoice/' + row.id + '/show', 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon('/invoice/' + row.id + '/edit', 'edit', 'bearbeiten', !row.uid)}
                {this.renderTableCellActionIcon('/invoice/' + row.id + '/delete', 'trash', 'löschen', !row.uid)}
                {this.renderTableCellActionIcon('/invoice/' + row.id + '/generate', 'cog', 'generieren', !row.uid)}
            </td>
        )
    }
}