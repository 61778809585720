import React from "react";
import FormField from './FormField';

export default class FormInput extends FormField {
    render() {
        return (
            <div className={`col-md-${this.props.width}`}>
                <label htmlFor={this.props.field.key}>{this.props.label}</label>
                {(!this.props.unit) ?
                    this.renderInput() :
                    <span className={`unit unit-${this.props.unit}`}>{this.renderInput()}</span>
                }
            </div>
        )
    }

    renderInput() {
        return (
            <input
                className={`form-control${(!this.state.field.isValid) ? ' is-invalid' : ''}`}
                id={this.props.field.key}
                value={this.renderFilter(this.props.field.value) || ''}
                name={this.props.field.key}
                onChange={this.updateValue.bind(this)}
            />
        );
    }
}