import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableUser extends SearchTable {
    params = {
        sort_field: 'created',
        sort_order: 'desc',
        page: 1
    };
    apiUrl = '/api/accounts';
    formId = 'account-search-form';
    varPrefix = 'searchTableAccount';
    loadParamsRegExp = [
        /\/account\/(.*)/g
    ];

    sortDef = [
        { key: 'identifier', name: 'IBAN / Identifier' },
        { key: 'created', name: 'Erstellt' }
    ];

    colDef = [
        { text: 'Typ'},
        { sortField: 'identifier', text: 'IBAN / Identifier' },
        { sortField: 'created', text: 'Erstellt' },
        { text: 'Status'},
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr key={`account-${row.id}`}>
                {this.renderTableCellAccountType(row)}
                {this.renderTableCellAccount(row)}
                {this.renderTableCellCreated(row)}
                {this.renderTableCellStatus(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellStatus(row) {
        return(
            <td>
                {this.renderStatusIcon('user', row.registered_ui)}
                {this.renderStatusIcon('ban', row.restricted)}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/account/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/account/${row.id}/edit`, 'pencil', 'bearbeiten', true)}
            </td>
        )
    }
}