import React from "react";
const { Component } = React;

export default class ViewStatus extends Component {
    render() {
        let counter = 0;
        for (let i = 0; i < this.props.stations.length; i++) {
            counter += this.props.stations[i].units.length;
        }
        return(
            <div className="row row-form">
                <div className="col-md-12">
                    <h2>Übersicht</h2>
                    <p>
                        In {this.props.unitGroup.fields.address.value}, {this.props.unitGroup.fields.postalcode.value}{' '}
                        {this.props.unitGroup.fields.locality.value} befinden sich {this.props.stations.length}{' '}
                        Stationen mit{' '} {counter} Ladepunkten.
                    </p>
                    {false && this.props.stations.map((station, id) => (
                        <div key={id}>
                            <h4>Station {station.fields.uid.value}</h4>
                            <p>
                                <span>{station.fields.public.valueLabel}</span>
                                {station.fields.hardware.valueLabel !== null &&
                                    <span> | Hardware: {station.fields.hardware.valueLabel}</span>
                                }
                                {station.fields.tech_backend.valueLabel !== null &&
                                    <span> | Backend: {station.fields.tech_backend.valueLabel}</span>
                                }
                            </p>
                            <ul>
                                {station.units.map((unit, id) => (
                                    <li key={id}>
                                        {unit.fields.uid.value}: {unit.fields.plug.valueLabel} {unit.fields.power.value} kW
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}