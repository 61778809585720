import { Field } from './Field';

export class SelectField extends Field {
    defaultParams = {
        transformKey: (obj) => obj.key,
        transformLabel: (obj) => obj.label
    };

    constructor(params) {
        super();
        params = Object.assign({}, this.defaultParams, params);
        this.options = params.options.map((item, id) => {
            return {key: params.transformKey(item), label: params.transformLabel(item)}
        });
        this.load(params);
    }

    initValue(value) {
        super.initValue(value);
        let option = this.options.find((item) => item.key === value);
        this.valueLabel = (option) ? option.label : null;
        if (!this.value && this.options.length) {
            this.value = this.options[0].key;
        }
    }

    setValue(value) {
        super.setValue(value);
        let option = this.options.find((item) => item.key === value);
        this.valueLabel = (option) ? option.label : null;
    }

    validate() {
        if (!this.options.find((item) => item.key === this.value)) {
            this.isValid = false;
        }
        return super.validate();
    }
}