import React from "react";
const { Component } = React;

export default class ViewBase extends Component {
    state = {};
    obj = 'undefined';

    constructor(props) {
        super(props);
        this.state[this.obj] = props[this.obj];
    }

    componentDidUpdate(prevProps) {
        if (prevProps[this.obj].clientUid === this.props[this.obj].clientUid)
            return;
        this.setState({
            [this.obj]: this.props[this.obj]
        });
    }

    deleteSelf(evt) {
        evt.preventDefault();
        this.setState({
            [this.obj]: Object.assign(this.state[this.obj], {deleted: true})
        });
    }

    undeleteSelf(evt) {
        evt.preventDefault();
        this.setState({
            [this.obj]: Object.assign(this.state[this.obj], {deleted: false})
        });
    }


    updateParent() {
        this.setState({
            [this.obj]: this.state[this.obj]
        });
    }
}