import Base from './Base';
import { SelectField } from '../Fields/SelectField';
import { StringField } from '../Fields/StringField';
import { FloatField } from '../Fields/FloatField';
import { IntegerField } from '../Fields/IntegerField';
import {ValidateDataRequired, ValidateOptionalIf, ValidateOneOf, ValidateFloat, ValidateOptional} from "../Validators";
import { filterUppercase, filterInteger, filterString, filterDiv1000, filterMul1000 } from "../Filter";
import { formatPrice } from "../../Format";

export default class Unit extends Base {
    fields = {
        id: new IntegerField({
            key: 'id',
            validators: [new ValidateOptional()]
        }),
        uid: new StringField({
            key: 'uid',
            validators: [new ValidateDataRequired()],
            liveFilters: [ filterUppercase ]
        }),
        public: new SelectField({
            key: 'public',
            options: [
                {key: 'public', label: 'öffentlich'},
                {key: 'hidden', label: 'nichtöffentlich'}
            ]
        }),
        pricegroup: new SelectField({
            key: 'pricegroup',
            options: pricegroupData.filter((pricegroup => pricegroup.type === 'default')),
            exportFilters: [ filterInteger ],
            initFilters: [ filterString ],
            transformKey: (obj) => String(obj.id),
            transformLabel: (obj) => obj.name + ' (' + formatPrice(obj.fee_base) + ' + ' + formatPrice(obj.fee_energy) + '/kWh + ' + formatPrice(obj.fee_time) + '/h)'
        }),
        plug: new SelectField({
            key: 'plug',
            options: plugData
        }),
        power: new FloatField({
            key: 'power',
            exportFilters: [ filterMul1000, filterInteger ],
            initFilters: [ filterDiv1000 ],
            validators: [
                new ValidateDataRequired(),
                new ValidateFloat()
            ]
        }),
        phases: new IntegerField({
            key: 'phases',
            validators: [
                new ValidateDataRequired(),
                new ValidateOneOf(['1', '3']),
                new ValidateOptionalIf(() => { return !['typ2', 'typ2-wired', 'typ1', 'typ1-wired'].includes(this.fields.plug.value)} )
            ]
        }),
        public_key: new StringField({
            key: 'public_key'
        }),
        public_key_meter: new StringField({
            key: 'public_key_meter'
        })
    };

    constructor(data) {
        super();
        this.load(data);
    }
}
