import React from "react";

const { Component } = React;

export default class UnitTcc extends Component {
    state = {
        shownAction: null,
        runningActions: [],
        unitStatus: null
    };

    constructor() {
        super();
        if (firmwares.length)
            this.state.firmwareId = firmwares[0].id;
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $('.btn-icon').tooltip();
    }

    init() {
        this.setState({
            unitConfig: this.props.unitConfig,
            chargeIban: this.props.iban,
            unitStatus: this.props.unitStatus
        });
        this.props.socket.on('update', (data) => {
            if (data.type === 'unlock') {
                this.handleUnlockResult(data);
            }
            if (data.type === 'reset') {
                this.handleResetResult(data);
            }
            if (data.type === 'query-config') {
                this.handleConfigResult(data);
            }
            if (data.type === 'StatusNotification') {
                this.handleStatusNotification(data);
            }
            if (data.type === 'DiagnosticsStatusNotification') {
                this.handleDiagnosticsStatusNotification(data);
            }
            if (data.type === 'FirmwareStatusNotification') {
                this.handleFirmwareStatusNotification(data);
            }
            if (data.type === 'unset-ip') {
                this.handleUnsetIp(data);
            }
            if (data.type === 'reset-basicauth') {
                this.handleResetBasicAuth(data);
            }
        });
    }

    triggerAction(action) {
        this.setState({
            shownAction: (action !== this.state.shownAction) ? action : null
        });
    }

    submitUnlock(evt) {
        evt.preventDefault();
        let params = Object.assign({}, this.props.params);
        this.setState({unlockResult: null});
        this.props.socket.emit('unlock', params);
    }

    submitReset(evt) {
        evt.preventDefault();
        let params = Object.assign({}, this.props.params);
        params.type = (this.state.resetHard === true) ? 'Hard' : 'Soft';
        this.setState({resetResult: null});
        this.props.socket.emit('reset', params);
    }

    submitChargeStart(evt) {
        evt.preventDefault();
        let params = Object.assign({}, this.props.params);
        params.iban = this.state.chargeIban;
        this.setState({chargeStartResult: null, chargeStartWaiting: true});
        this.props.socket.emit('charge-start', params);
    }

    submitChargeStop(evt) {
        evt.preventDefault();
        let params = Object.assign({}, this.props.params);
        this.setState({chargeStopResult: null, chargeStopWaiting: true});
        this.props.socket.emit('charge-stop', params);
    }

    submitConfig(evt) {
        evt.preventDefault();
        let params = Object.assign({}, this.props.params);
        this.setState({configResult: null, configError: 'Komplettabruf wird versucht ...'});
        this.props.socket.emit('query-config', params);
    }

    submitGetDiagnostics(evt) {
        evt.preventDefault();
        let params = Object.assign({}, this.props.params);
        this.setState({diagnosticsResult: null});
        this.props.socket.emit('query-diagnostics', params);
    }

    submitFirmwareUpdate(evt) {
        evt.preventDefault();
        let params = Object.assign({}, this.props.params);
        params.firmwareId = this.state.firmwareId;
        this.setState({firmwareResult: null});
        this.props.socket.emit('update-firmware', params);
    }

    submitUnsetIp(evt) {
        evt.preventDefault();
        this.props.socket.emit('unset-ip', Object.assign({}, this.props.params));
    }

    submitResetBasicAuth(evt) {
        evt.preventDefault();
        this.props.socket.emit('reset-basicauth', Object.assign({}, this.props.params));
    }

    handleUnlockResult(data) {
        this.setState({
            unlockResult: (data.data.status === 'Unlocked') ? 'ok' : 'fail'
        });
    }

    handleDiagnosticsStatusNotification(data) {
        this.setState({
            diagnosticsUploadResult: data.data.status
        });
    }

    handleFirmwareStatusNotification(data) {
        this.setState({
            firmwareUpdateResult: data.data.status
        });
    }

    handleResetResult(data) {
        this.setState({
            resetResult: (data.data.status === 'Accepted') ? 'ok' : 'fail'
        });
    }

    handleConfigResult(data) {
        if (data && data.data && data.data.configurationKey && data.data.configurationKey.length) {
            if (data.data.configurationKey.length < 10)
                return;
            this.setState({
                configResult: 'ok',
                configError: null
            });
        }
    }

    handleStatusNotification(data) {
        let update = {
            unitStatus: data.data.status
        };
        if (this.state.chargeStartWaiting && ['Charging', 'Preparing'].includes(data.data.status)) {
            update.chargeStartWaiting = null;
            update.chargeStartResult = 'ok';
        }
        if (this.state.chargeStopWaiting && ['Finishing', 'Available'].includes(data.data.status)) {
            update.chargeStopWaiting = null;
            update.chargeStopResult = 'ok';
        }
        this.setState(update);
    }

    handleUnsetIp(data) {
        this.setState({
            unsetIpResult: data.status
        });
    }

    handleResetBasicAuth(data) {
        this.setState({
            resetBasicAuthResult: data.status,
            resetBasicAuthResultCode: data.data.password
        });
    }

    render() {
        let result = [
            <div className="row row-form" key="admin-head">
                <div className="col-md-12">
                    <h3>Administrative Funktionen</h3>
                    <p>
                        <a onClick={this.triggerAction.bind(this, 'unlock')} className="btn btn-default btn-icon" data-placement="top" title="Ladesäule entriegeln">
                            <i className="fa fa-unlock" aria-hidden="true"></i>
                        </a>{' '}
                        <a onClick={this.triggerAction.bind(this, 'reset')} className="btn btn-default btn-icon" data-placement="top" title="Ladesäule neu starten">
                            <i className="fa fa-bolt" aria-hidden="true"></i>
                        </a>{' '}
                        {['Available', 'Preparing'].includes(this.state.unitStatus) &&
                            <a onClick={this.triggerAction.bind(this, 'charge-start')} className="btn btn-default btn-icon" data-placement="top" title="Remote Start">
                                <i className="fa fa-play" aria-hidden="true"></i>
                            </a>
                        }
                        {['Charging', 'SuspendedEVSE', 'SuspendedEV'].includes(this.state.unitStatus) &&
                            <a onClick={this.triggerAction.bind(this, 'charge-stop')} className="btn btn-default btn-icon" data-placement="top" title="Remote Stop">
                                <i className="fa fa-stop" aria-hidden="true"></i>
                            </a>
                        }
                        {' '}
                        <a onClick={this.triggerAction.bind(this, 'config')} className="btn btn-default btn-icon" data-placement="top" title="Konfigurations-Sync">
                            <i className="fa fa-refresh" aria-hidden="true"></i>
                        </a>
                        {' '}
                        <a onClick={this.triggerAction.bind(this, 'diagnostics')} className="btn btn-default btn-icon" data-placement="top" title="Diagnose abrufen">
                            <i className="fa fa-stethoscope" aria-hidden="true"></i>
                        </a>
                        {' '}
                        <a onClick={this.triggerAction.bind(this, 'firmware-update')} className="btn btn-default btn-icon" data-placement="top" title="Firmware-Update">
                            <i className="fa fa-arrow-up" aria-hidden="true"></i>
                        </a>
                        {' '}
                        <a onClick={this.triggerAction.bind(this, 'unset-ip')} className="btn btn-default btn-icon" data-placement="top" title="IP-Zuordnung zurücksetzen">
                            <i className="fa fa-chain-broken" aria-hidden="true"></i>
                        </a>
                        {' '}
                        <a onClick={this.triggerAction.bind(this, 'reset-basicauth')} className="btn btn-default btn-icon" data-placement="top" title="Zugangspasswort zurücksetzen">
                            <i className="fa fa-key" aria-hidden="true"></i>
                        </a>
                    </p>
                </div>
            </div>
        ];
        result.push(this.renderActionUnlock());
        result.push(this.renderActionReset());
        result.push(this.renderActionChargeStart());
        result.push(this.renderActionChargeStop());
        result.push(this.renderActionConfig());
        result.push(this.renderActionDiagnostics())
        result.push(this.renderActionFirmware())
        result.push(this.renderActionUnsetIp())
        result.push(this.renderActionResetBasicAuth())
        return result;
    }

    renderActionDiagnostics() {
        if (this.state.shownAction !== 'diagnostics')
            return;
        return (
            <div className="row row-form" key="admin-diagnostics">
                <div className="col-md-8">
                    <input type="submit" className="form-control" onClick={this.submitGetDiagnostics.bind(this)} value="Diagnose abrufen" />
                </div>
                <div className="col-md-4">
                    {!!this.state.diagnosticsUploadResult && <span>
                        {this.formatDiagnosticsStatus(this.state.diagnosticsUploadResult)}
                    </span>}
                </div>
            </div>
        );
    }

    renderActionFirmware() {
        if (this.state.shownAction !== 'firmware-update')
            return;
        return (
            <div className="row row-form" key="admin-charge-start">
                <div className="col-md-5">
                    <label htmlFor="firmware">IBAN</label>
                    <select
                        name="firmware"
                        id="firmware"
                        className="form-control"
                        value={this.state.firmwareId}
                        onChange={(evt) => this.setState({firmwareId: evt.target.value})}
                    >
                        {firmwares.map((firmware) => <option value={firmware.id} key={`firmware-${firmware.id}`}>{firmware.name}</option>)}
                    </select>
                </div>
                <div className="col-md-3">
                    <label htmlFor="charge-submit">&nbsp;</label>
                    <input type="submit" id="firmware-submit" name="firmware-submit" className="form-control" onClick={this.submitFirmwareUpdate.bind(this)} value="Firmware-Update starten" />
                </div>
                <div className="col-md-4">
                    {!!this.state.firmwareUpdateResult && <span>
                        {this.formatFirmwareStatus(this.state.firmwareUpdateResult)}
                    </span>}
                </div>
            </div>
        );
    }

    renderActionUnlock() {
        if (this.state.shownAction !== 'unlock')
            return;
        return (
            <div className="row row-form" key="admin-unlock">
                <div className="col-md-8">
                    <input type="submit" className="form-control" onClick={this.submitUnlock.bind(this)} value="entriegeln" />
                </div>
                <div className="col-md-4">
                    {this.state.unlockResult === 'ok' &&
                        <i className="fa fa-2x fa-check-circle-o" aria-hidden="true"></i>
                    }
                    {this.state.unlockResult === 'fail' &&
                        <i className="fa fa-2x ban" aria-hidden="true"></i>
                    }{' '}
                    {this.state.unlockError}
                </div>
            </div>
        );
    }

    renderActionReset() {
        if (this.state.shownAction !== 'reset')
            return;
        return (
            <div className="row row-form" key="admin-reset">
                <div className="col-md-5">
                    <label>
                        <input
                            type="checkbox"
                            name="reset-hard"
                            id="reset-hard"
                            value={this.state.resetHard}
                            onChange={(evt) => this.setState({resetHard: evt.target.checked})}
                        />
                        {' '} Harten Reset durchführen (Datenverlust möglich)
                    </label>
                </div>
                <div className="col-md-3">
                    <input type="submit" className="form-control" onClick={this.submitReset.bind(this)} value="neu starten" />
                </div>
                <div className="col-md-4">
                    {this.state.resetResult === 'ok' &&
                        <i className="fa fa-2x fa-check-circle-o" aria-hidden="true"></i>
                    }
                    {this.state.resetResult === 'fail' &&
                        <i className="fa fa-2x ban" aria-hidden="true"></i>
                    }{' '}
                    {this.state.resetError}
                </div>
            </div>
        );
    }

    renderActionChargeStart() {
        if (this.state.shownAction !== 'charge-start')
            return;
        return (
            <div className="row row-form" key="admin-charge-start">
                <div className="col-md-5">
                    <label htmlFor="charge-iban">IBAN</label>
                    <input
                        type="text"
                        name="charge-iban"
                        id="charge-iban"
                        className="form-control"
                        value={this.state.chargeIban}
                        onChange={(evt) => this.setState({chargeIban: evt.target.value})}
                    />
                </div>
                <div className="col-md-3">
                    <label htmlFor="charge-submit">&nbsp;</label>
                    <input type="submit" id="charge-submit" name="charge-submit" className="form-control" onClick={this.submitChargeStart.bind(this)} value="Ladevorgang starten" />
                </div>
                <div className="col-md-4">
                    {this.state.chargeStartResult === 'ok' &&
                        <i className="fa fa-2x fa-check-circle-o" aria-hidden="true"></i>
                    }
                    {this.state.chargeStartResult === 'fail' &&
                        <i className="fa fa-2x ban" aria-hidden="true"></i>
                    }{' '}
                    {this.state.chargeStartError}
                </div>
            </div>
        );
    }

    renderActionChargeStop() {
        if (this.state.shownAction !== 'charge-stop')
            return;
        return (
            <div className="row row-form" key="admin-charge-stop">
                <div className="col-md-8">
                    <input type="submit" className="form-control" onClick={this.submitChargeStop.bind(this)} value="Ladevorgang beenden" />
                </div>
                <div className="col-md-4">
                    {this.state.chargeStopResult === 'ok' &&
                        <i className="fa fa-2x fa-check-circle-o" aria-hidden="true"></i>
                    }
                    {this.state.chargeStopResult === 'fail' &&
                        <i className="fa fa-2x ban" aria-hidden="true"></i>
                    }{' '}
                    {this.state.chargeStopError}
                </div>
            </div>
        );
    }

    renderActionConfig() {
        if (this.state.shownAction !== 'config')
            return;
        return (
            <div className="row row-form" key="admin-charge">
                <div className="col-md-4">
                    Der vollständige Abruf der Konfiguration kann je nach Datenverbindung zur Säule recht lange brauchen.
                </div>
                <div className="col-md-4">
                    <input type="submit" className="form-control" onClick={this.submitConfig.bind(this)} value="abrufen" />
                </div>
                <div className="col-md-4">
                    {this.state.configResult === 'ok' &&
                        <i className="fa fa-2x fa-check-circle-o" aria-hidden="true"></i>
                    }
                    {this.state.configResult === 'fail' &&
                        <i className="fa fa-2x ban" aria-hidden="true"></i>
                    }{' '}
                    {this.state.configError}
                </div>
            </div>
        );
    }

    renderActionUnsetIp() {
        if (this.state.shownAction !== 'unset-ip')
            return;
        return (
            <div className="row row-form" key="admin-charge-stop">
                <div className="col-md-12">
                    Im Fall der Giro-e SIM-Karte wird eine Säule fest einer SIM-Karte und damit einer IP-Adresse
                    zugeordnet, so dass die Verbindung nicht gekapert werden kann. Sollte die SIM-Karte einer Säule
                    ausgetauscht werden (und nur dann!), so muss auch die IP-Zuordnung zurückgesetzt werden.
                </div>
                <div className="col-md-8">
                    <input type="submit" className="form-control" onClick={this.submitUnsetIp.bind(this)} value="IP-Zuordnung zurücksetzen" />
                </div>
                <div className="col-md-4">
                    {this.state.unsetIpResult === 'ok' &&
                        <i className="fa fa-2x fa-check-circle-o" aria-hidden="true"></i>
                    }
                </div>
            </div>
        )
    }

    renderActionResetBasicAuth() {
        if (this.state.shownAction !== 'reset-basicauth')
            return;
        return (
            <div className="row row-form" key="admin-charge-stop">
                <div className="col-md-12">
                    Ist eine Säule via Netzwerkkabel oder mit einer fremden SIM-Karte online, so wird die OCPP
                    Security Extension 2: "TLS with Basic Authentication" vorausgesetzt. Das Passwort können Sie hier
                    zurücksetzen.
                </div>
                <div className="col-md-8">
                    <input type="submit" className="form-control" onClick={this.submitResetBasicAuth.bind(this)} value="Passwort zurücksetzen" />
                </div>
                <div className="col-md-4">
                    {this.state.resetBasicAuthResult === 'ok' &&
                        <span>
                            <i className="fa fa-2x fa-check-circle-o" aria-hidden="true"></i>
                            Neuer Passcode: {this.state.resetBasicAuthResultCode}
                        </span>
                    }
                </div>
            </div>
        )
    }

    formatFirmwareStatus(status) {
        if (status === 'Downloaded')
            return 'Download erfolgreich';
        if (status === 'DownloadFailed')
            return 'Download fehlgeschlagen';
        if (status === 'Downloading')
            return 'wird heruntergeladen';
        if (status === 'InstallationFailed')
            return 'Installation fehlgeschlagen';
        if (status === 'Installing')
            return 'wird installiert';
        if (status === 'Installed')
            return 'Installation erfolgreich';
        return '';
    }

    formatDiagnosticsStatus(status) {
        if (status === 'Uploaded')
            return 'Upload erfolgreich';
        if (status === 'UploadFailed')
            return 'Upload fehlgeschlagen';
        if (status === 'Uploading')
            return 'wird hochgeladen';
        return '';
    }
}