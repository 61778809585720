import { multiselect_options } from '../Constants';
import { formatPrice } from "../Format";

export default class ProductManagement {
    constructor() {
        $('#value_total, #tax_rate').change(() => this.calulateAmount());
        this.calulateAmount();
        $('#product_category').multiselect(multiselect_options);
        $('#cashpoint').multiselect(multiselect_options);
    };

    calulateAmount () {
        let value = parseFloat($('#value_total').val().replace(',', '.'));
        let tax_rate = parseFloat($('#tax_rate').val());
        let brutto = value;
        let netto = brutto / (1 + tax_rate);
        let tax = brutto - netto;
        let text = 'Dies ergibt ein Netto-Preis von ' + formatPrice(netto);
        text += ', einen Brutto-Preis von ' + formatPrice(brutto);
        text += ' und eine Mehrwertsteuer von ' +formatPrice(tax) + '.';
        $('#product-calculation').text(text);
    };
};