import React from "react";
import moment from 'moment';
import {formatDatetime} from "../Format";
const { Component } = React;

export default class DashboardLeftManagement extends Component {
    formId = 'dashboard-form';
    state = {
        initialized: false
    };
    params = {
        results: 5,
        sort_field: 'created',
        sort_order: 'desc',
        daterange: moment().subtract(3, 'month').format('DD.MM.YYYY') + ' - ' + moment().format('DD.MM.YYYY'),
    };
    capabilities = [];
    userType = null;

    componentDidMount() {
        this.init();
    }

    init() {
        this.params.csrf_token = document.getElementById('csrf_token').value;
        let base = $('#dashboard');
        this.capabilities = base.data('capabilities').split(' ');
        this.userType = base.data('user-type');
        if (this.capabilities.indexOf('charge') !== -1 && this.userType === 'admin') {
            $.post('/api/notifications', this.params)
                .then(data => {
                    this.setState({
                        initialized: true,
                        notifications: data.data
                    });
                });
        }
    }

    render() {
        if (this.userType !== 'admin')
            return (<div></div>);
        if (!this.state.initialized) {
            return (
                <div className="text-center">
                    <i className="fa fa-spinner fa-pulse fa-fw"></i>
                </div>
            );
        }
        return (
            <div className="container container-noborder">
                <div className="row">
                    {this.renderNotifications()}
                </div>
            </div>
        );
    }

    renderNotifications() {
        if (!this.state.notifications)
            return;
        if (!this.state.notifications.length)
            return;
        let items = [];
        for (let i = 0; i < this.state.notifications.length; i++) {
            items.push(this.renderNotification(this.state.notifications[i]));
        }
        return (
            <div className="col-md-12">
                <h2>
                    <a href="/notifications">Letzte Benachrichtigungen</a>
                </h2>
                <table className="table table-bordered table-hover table-sm">
                    <tbody>
                        {items}
                    </tbody>
                </table>
            </div>
        )
    }

    renderNotification(row) {
        return (
            <tr key={`notification-${row.id}`} onClick={this.linkUrl.bind(this, '/notification/' + row.id + '/show')}>
                <td>
                    {formatDatetime(row.created)}<br/>
                </td>
                <td>
                    <small>{row.subject}</small>
                </td>
            </tr>
        )
    }

    linkUrl(url) {
        window.location.href = url;
    }
}