import React from "react";
import SearchTable from './SearchTable'
import {formatPrice} from "../Format";

export default class SearchTablePricegroup extends SearchTable {
    params = {
        sort_field: 'name',
        sort_order: 'asc',
        page: 1
    };
    formId = 'pricegroup-search-form';
    varPrefix = 'searchTablePricegroup';
    loadParamsRegExp = [
        /\/pricegroup\/(.*)/g
    ];

    apiUrl = '/api/pricegroups';
    sortDef = [
        { key: 'name', name: 'Name' },
        { key: 'created', name: 'Erstellungszeitpunkt' },
        { key: 'modified', name: 'Änderungszeitpunkt' }
    ];

    colDef = [
        { sortField: 'name', text: 'Name' },
        { text: 'Betreiber' },
        { text: 'Details' },
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr key={`pricegroup-${row.id}`}>
                {this.renderTableCellName(row)}
                {this.renderTableCellOperator(row)}
                {this.renderTableCellDetails(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellName(row) {
        return(
            <td>
                {row.name}
            </td>
        )
    }

    renderTableCellDetails(row) {
        return (
            <td>
                {formatPrice(row.fee_base)} + {formatPrice(row.fee_energy)} / kWh + {formatPrice(row.fee_time)} / h<br/>
                {row.type === 'refund' && <span>Refund-Preisgruppe</span>}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/pricegroup/${row.id}/show`, 'info', 'Details', true)}
                {this.renderTableCellActionIcon(`/pricegroup/${row.id}/edit`, 'edit', 'bearbeiten', this.capabilities.includes('edit'))}
                {this.renderTableCellActionIcon(`/pricegroup/${row.id}/delete`, 'trash', 'löschen', this.capabilities.includes('delete'))}
            </td>
        )
    }
}