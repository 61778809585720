import React from "react";
import { daterangepicker_ranges, daterangepicker_locale } from "../Constants";
const { Component } = React;
import moment from 'moment';
import {formatDuration, formatEnergy, formatPrice} from "../Format";

export default class ChargeStatistics extends Component {
    formId = 'charge-statistics-product-form';
    state = {
        initialized: false
    };
    params = {
        daterange: moment().subtract(1, 'days').format('DD.MM.YYYY') + ' - ' + moment().subtract(1, 'days').format('DD.MM.YYYY'),
    };
    remoteUrl = '/api/statistics/charge/products';


    componentDidMount () {
        this.init();
        this.updateData();
    };

    init() {
        this.params.csrf_token = $('#csrf_token').val();
        let start = moment().subtract(1, 'days');
        let end = moment().subtract(1, 'days');
        $('#daterange').daterangepicker({
            startDate: start,
            endDate: end,
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, (start, end, label) => this.daterangepickerSubmit(start, end, label));
        $('#' + this.formId + ' select').change((event) => this.formSubmit(event));
        document.getElementById(this.formId).onsubmit = (event) => this.formSubmit(event)
    }

    daterangepickerSubmit(start, end, label) {
        this.params.daterange = start.format('DD.MM.YYYY') + ' - ' + end.format('DD.MM.YYYY');
        this.updateData();
    }

    formSubmit(event) {
        event.preventDefault();
        this.updateParams();
    }

    updateParams() {
        let operator_id = parseInt($('#operator').val());
        if (operator_id)
            this.params.operator = operator_id;
        else {
            delete this.params.operator;
        }
        this.updateData();
    }

    updateData() {
        $.post(this.remoteUrl, this.params)
            .then(data => {
                this.setState({
                    count: data.count,
                    initialized: true,
                    aggs: data.aggs
                });
            });
    };

    render () {
        if (!this.state.initialized) {
            return (
                <div className={'search-table-loading'}>
                    ... wird geladen ...
                </div>
            );
        }
        return(
            <table className="key-value-table">
                <tr><td colSpan="2"><h3>{this.state.count} Ladevorgänge</h3></td></tr>
                <tr><td>Umsatz:</td><td className="col-right">{formatPrice(this.state.aggs.value_total.value)}</td></tr>
                <tr><td colSpan="2"><h4>Start</h4></td></tr>
                <tr><td>Umsatz:</td><td className="col-right">{formatPrice(this.state.aggs.value_base.value)}</td></tr>
                <tr><td>Ohne Discount:</td><td className="col-right">{formatPrice(this.state.aggs.nodiscount_base.value)}</td></tr>
                <tr><td>Discount:</td><td className="col-right">{formatPrice(this.state.aggs.discount_base.value)}</td></tr>
                <tr><td colSpan="2"><h4>Verbrauch</h4></td></tr>
                <tr><td>Abgegebene Energie:</td><td className="col-right">{formatEnergy(this.state.aggs.energy_raw.value)}</td></tr>
                <tr><td>Abgerechnete Energie:</td><td className="col-right">{formatEnergy(this.state.aggs.energy.value)}</td></tr>
                <tr><td>Umsatz:</td><td className="col-right">{formatPrice(this.state.aggs.value_energy.value)}</td></tr>
                <tr><td>Ohne Discount:</td><td className="col-right">{formatPrice(this.state.aggs.nodiscount_energy.value)}</td></tr>
                <tr><td>Discount:</td><td className="col-right">{formatPrice(this.state.aggs.discount_energy.value)}</td></tr>
                <tr><td colSpan="2"><h4>Zeit</h4></td></tr>
                <tr><td>Genutzte Zeit:</td><td className="col-right">{formatDuration(this.state.aggs.duration_raw.value)}</td></tr>
                <tr><td>Abgerechnete Zeit:</td><td className="col-right">{formatDuration(this.state.aggs.duration.value)}</td></tr>
                <tr><td>Umsatz:</td><td className="col-right">{formatPrice(this.state.aggs.value_time.value)}</td></tr>
                <tr><td>Ohne Discount:</td><td className="col-right">{formatPrice(this.state.aggs.nodiscount_time.value)}</td></tr>
                <tr><td>Discount:</td><td className="col-right">{formatPrice(this.state.aggs.discount_time.value)}</td></tr>
                <tr><td colSpan="2"><h4>Discount</h4></td></tr>
                <tr><td>Discount:</td><td className="col-right">{formatPrice(this.state.aggs.value_discount.value)}</td></tr>
            </table>
        )
    };
};
