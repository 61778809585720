import {Decimal} from 'decimal.js';

var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
jQueryBridget( 'isotope', Isotope, $ );

export default class CashpointPreview {
    constructor() {
        this.categories = new CashpointPreviewCategories();
        this.categories.sync_save(config.categories);
        this.products = new CashpointPreviewProducts();
        this.products.sync_save(config.products);
    };
};

export class CashpointPreviewProducts {
    constructor() {
        this.products = [];
    };

    get = function(id) {
        for (var i = 0; i < this.products.length; i++) {
            if (this.products[i].id === id)
                return this.products[i];
        }
        return null;
    };

    sync_save (data) {
        for (var i = 0; i < data.length; i++) {
            var product = new CashpointPreviewProduct;
            product.set_data(data[i]);
            this.products.push(product);
        }
        this.show();
    };

    show () {
        var html = '';
        for (var i = 0; i < this.products.length; i++) {
            html += this.products[i].get_choose_html();
        }
        $('#products').html(html);
        var max_height = 0;
        $('#products h3').each(function () {
            var text_length = $(this).text().length;
            if (text_length < 10) {
                $(this).addClass('shorttitle');
            }
            if (text_length > 25) {
                $(this).addClass('longtitle');
            }
            if (max_height < $(this).height())
                max_height = $(this).height();
        });
        $('#products h3').height(max_height);
        $('#products').isotope({
            itemSelector: '.product'
        });
    };

    category_filter (id) {
        $('#products').isotope({
            filter: '.product.category-' + String(id)
        });
    }
}


export class CashpointPreviewProduct {
    set_data = function(data) {
        this.id = data.id;
        this.external_id = data.external_id;
        this.modified = data.modified;
        this.name = data.name;
        this.description = data.description;
        this.value_subtotal = new Decimal(data.value_subtotal);
        this.value_total = new Decimal(data.value_total);
        this.value_tax = new Decimal(data.value_tax);
        this.tax_rate = new Decimal(data.tax_rate);
        this.image = data.image;
        this.categories = data.categories;
    };

    get_data () {
        return {
            external_id: this.external_id,
            name: this.name,
            description: this.description,
            value_subtotal: this.value_subtotal,
            value_total: this.value_total,
            value_tax: this.value_tax,
            tax_rate: this.tax_rate
        };
    };

    get_choose_html () {
        var classes = [];
        for (var i = 0; i < this.categories.length; i++) {
            classes.push('category-' + String(this.categories[i]))
        }
        var html = '<div class="product ' + classes.join(' ') + '" data-id="' + this.id + '">';
        if (this.image) {
            html += '<div class="product-image" style="background-image: url(\'/static/images/product/' + String(this.external_id) + '.' + this.image + '?v=' + this.modified + '\');"></div>';
        }
        html += '<h3>' + this.name + '</h3>';
        html += '<p>' + this.eurofy(this.value_total) + '</p>';
        html += '</div>';
        return html;
    };

    eurofy = function(value) {
        if (value || value === 0) {
            var value = String(parseInt(Decimal.round(value * 100)));
            while (value.length < 3)
                value = '0' + value;
            return (value.substr(0, value.length - 2) + ',' + value.substr(value.length - 2) + ' €');
        }
        else
            return '0,00 €';
    };
}

export class CashpointPreviewCategories  {
    constructor () {
        this.categories = [];
    };

    get (id) {
        for (var i = 0; i < this.categories.length; i++) {
            if (this.categories[i].id === id)
                return this.categories[i];
        }
        return null;
    };

    sync_save (data) {
        for (var i = 0; i < data.length; i++) {
            var category = new CashpointPreviewCategory;
            category.set_data(data[i]);
            this.categories.push(category);
        }
        this.show();
    };


    show () {
        var html = '';
        for (var i = 0; i < this.categories.length; i++) {
            html += this.categories[i].get_button();
        }
        $('#categories').html(html).show();
        $('.category-button').click(function() {
            window.cashpointPreview.categories.get(parseInt($(this).data('category-id'))).select();
        });
    };
}


export class CashpointPreviewCategory {

    set_data (data) {
        this.id = data.id;
        this.external_id = data.external_id;
        this.name = data.name;
        this.description = data.description;
    };

    get_button () {
        var html = '<button id="category-button-' + this.id + '" class="category-button btn btn-outline-success" data-category-id="' + this.id + '">' + this.name + '</button>';
        return html;
    };

    select () {
        $('.category-button').addClass('btn-outline-success').removeClass('btn-success');
        $('#category-button-' + String(this.id)).addClass('btn-success').removeClass('btn-outline-success');
        window.cashpointPreview.products.category_filter(this.id);
    };
}