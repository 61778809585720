import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableDiscount extends SearchTable {
    params = {
        sort_field: 'name',
        sort_order: 'asc',
        page: 1
    };
    formId = 'discount-search-form';
    varPrefix = 'searchTableDiscount';
    loadParamsRegExp = [
        /\/discount\/(.*)/g 
    ];

    apiUrl = '/api/discounts';
    sortDef = [
        { key: 'name', name: 'Name' },
        { key: 'created', name: 'Erstellungszeitpunkt' },
        { key: 'modified', name: 'Änderungszeitpunkt' }
    ];

    colDef = [
        { sortField: 'name', text: 'Name' },
        { text: 'Betreiber' },
        { text: 'Details' },
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr key={`discount-${row.id}`}>
                {this.renderTableCellName(row)}
                {this.renderTableCellOperator(row)}
                {this.renderTableCellDetails(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellName(row) {
        return(
            <td>
                {row.name}
            </td>
        )
    }

    renderTableCellDetails(row) {
        return (
            <td>
                {row.discount_recipient.length} Empfänger<br/>
                <small>{this.formatDate(row.start)} - {(row.end.substr(0, 10) === '2099-12-31') ? 'unbegrenzt' : this.formatDate(row.end)}</small>
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/discount/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/discount/${row.id}/edit`, 'pencil', 'bearbeiten', this.capabilities.includes('edit'))}
                {this.renderTableCellActionIcon(`/discount/${row.id}/delete`, 'trash', 'löschen', this.capabilities.includes('delete'))}
                {this.renderTableCellActionIcon(`/discount/${row.id}/recipients`, 'users', 'Empfänger', this.capabilities.includes('discount-recipient-view'))}
            </td>
        )
    }
}