import React from "react";
const { Component } = React;

export default class DiscountRecipientTestManagement extends Component {
    formId = 'discount-recipient-test-form';
    apiUrl = '/api/discount/test/recipient';
    state = {
        initialized: false
    };

    componentDidMount() {
        this.init();
    }

    init() {
        document.getElementById(this.formId).onsubmit = (event) => this.formSubmit(event)
    }


    formSubmit(event) {
        event.preventDefault();
        this.updateData();
    }

    updateData() {
        let params = {};
        let fields = $('#' + this.formId).serializeArray();
        for (let i = 0; i < fields.length; i++){
            if (fields[i].value && fields[i].value !== '_default' && fields[i].value !== '_all')
                params[fields[i].name] = fields[i].value;
        }
        $.post(this.apiUrl, params)
            .then(data => {
                if (data.status)
                    return;
                this.setState({
                    data: data.data,
                    initialized: true
                });
            });
    }
    render() {
        if (!this.state.initialized) {
            return (
                <p className="italic-comment">
                    Bitte Optionen wählen.
                </p>
            );
        }
        return (
            <div className="row">
                <div className="col-md-12">
                    <h2>Ergebnis</h2>
                    {this.renderRows()}
                </div>
            </div>
        );
    }

    renderRows() {
        if (!this.state.data.length) {
            return (
                <p className="italic-comment">
                    Keine Treffer.
                </p>
            )
        }
        let result = [];
        for (let i = 0; i < this.state.data.length; i++) {
            result.push(this.renderRow(this.state.data[i]));
        }
        return result;
    }

    renderRow(row) {
        return(
            <div>
                <h3>
                    <a href={'/discount/' + row.discount.id + '/show'}>
                        {row.discount.name}
                    </a>
                </h3>
                <p>
                    Gültig für Ladepunkte des Betreibers{" "}
                    <a href={'/operator/' + row.operator.id + '/show'}>{row.operator.name}</a>
                    {" "}durch den Rabatt-Empfänger{" "}
                    <a href={'/discount/' + row.id + '/recipient/' + row.id + '/show'}>
                        {row.type}: {row.selector} {row.identifier}
                    </a>
                </p>

            </div>
        );
    }

}