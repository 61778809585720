import React from "react";
const { Component } = React;

import SidebarUnits from "./SidebarUnits";
import Station from "../../Models/Station";


export default class Sidebar extends Component {
    addStation() {
        let station = new Station();
        this.props.stations.push(station);
        this.props.setView('station', station.clientUid);
    }

    render() {
        let result = this.props.unitGroup.getHasChanged();
        this.props.stations.map((station, id) => {result = station.getHasChanged() || result});
        return (
            <ul id="unit-group-sidebar" style={{border: '1px solid #1e7e34', borderTop: 0}}>
                <li
                    className={`pointer btn btn-success btn-block`}
                    style={{marginBottom: '10px'}}
                    onClick={this.props.setView.bind(this, 'save', null)}
                >
                    speichern
                </li>
                <li
                    className={`pointer${(this.props.viewType === 'status') ? ' active' : ''}`}
                    key='status'
                    onClick={this.props.setView.bind(this, 'status', null)}
                >
                    Übersicht
                </li>
                <li
                    className={`pointer${(this.props.viewType === 'settings') ? ' active' : ''} ${(!this.props.unitGroup.isValidByGroup('settings')) ? 'has-invalid' : ''}`}
                    key='settings'
                    onClick={this.props.setView.bind(this, 'settings', null)}
                >
                    Ladegruppe / Ort
                </li>
                {(window.currentUser.hasOperatorCapability('charge-client') || window.currentUser.hasOperatorCapability('charge-employee')) &&
                    <li
                        className={`pointer${(this.props.viewType === 'refund') ? ' active' : ''} ${(!this.props.unitGroup.isValidByGroup('refund')) ? 'has-invalid' : ''}`}
                        key='refund'
                        onClick={this.props.setView.bind(this, 'refund', null)}
                    >
                        Refund
                    </li>
                }
                {this.props.stations.map((station, index) => (
                    <li
                        key={station.clientUid}
                    >
                        <div
                            className={`${(this.props.viewType === 'station' && this.props.viewClientUid === station.clientUid) ? 'active' : ''} ${(!station.isValid) ? 'has-invalid' : ''}`}
                            onClick={this.props.setView.bind(this, 'station', station.clientUid)}>
                            {(station.fields.uid.value) ? station.fields.uid.value :
                                <span style={{fontStyle: 'italic'}}>
                                    unbenannt
                                </span>
                            }
                            {station.deleted &&
                                <span>
                                    {' '}<i className="fa fa-trash-o" aria-hidden="true"></i>
                                </span>
                            }
                        </div>
                        <SidebarUnits
                            units={station.units}
                            setView={this.props.setView}
                            viewType={this.props.viewType}
                            viewClientUid={this.props.viewClientUid}
                        />
                    </li>
                ))}
                <li key='new-station' onClick={this.addStation.bind(this)} className="pointer">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    Neue Station
                </li>
                <li
                    className={`pointer btn btn-danger btn-block`}
                    style={{marginTop: '10px', marginBottom: 0}}
                    onClick={() => { window.location.href='/unit-groups' }}
                >
                    abbrechen
                </li>
            </ul>
        );
    }


}