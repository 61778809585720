import React from "react";
import BasePicker from "./BasePicker";

export default class UnitPicker extends BasePicker {

    includeQuery = true;

    getUrl() {
        return '/api/unitpicker'
    }

    sanitizeOption(option) {
        return option.replace(' ', '').toUpperCase()
    }

    beforeSend(value) {
        return value.toUpperCase();
    }

    filterByFunction() {
        return (option, props) => {
            return option.toUpperCase().includes(props.text.toUpperCase().split(' ').join(''))
        }
    }
}