import { Field } from './Field';
import {ValidateInteger} from "../Validators";
import {filterInteger, filterString} from "../Filter";

export class IntegerField extends Field {
    validators = [ new ValidateInteger() ];
    exportFilters = [ filterInteger ];
    initFilters = [ filterString ];

    constructor(params) {
        super();
        this.load(params);
    }
}