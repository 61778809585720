import Base from './Base';
import { SelectField } from '../Fields/SelectField';
import { StringField } from '../Fields/StringField';
import { FloatField } from '../Fields/FloatField';
import { IntegerField } from '../Fields/IntegerField';
import { DateField } from '../Fields/DateField';
import { ValidateUrl, ValidateOptional, ValidateDataRequired } from "../Validators";
import { filterInteger, filterString } from "../Filter";
import { formatPrice } from "../../Format";

export default class UnitGroup extends Base {
    isRefundValid = true;

    fields = {
        id: new IntegerField({
            key: 'id',
            validators: [new ValidateOptional()]
        }),
        name: new StringField({
            key: 'name'
        }),
        goingelectric_url: new StringField({
            key: 'goingelectric_url',
            validators: [new ValidateUrl(), new ValidateOptional()],
            validationGroup: 'settings'
        }),
        address: new StringField({
            key: 'address',
            validators: [new ValidateDataRequired()],
            validationGroup: 'settings'
        }),
        postalcode: new StringField({
            key: 'postalcode',
            validators: [new ValidateDataRequired()],
            validationGroup: 'settings'
        }),
        locality: new StringField({
            key: 'locality',
            validators: [new ValidateDataRequired()],
            validationGroup: 'settings'
        }),
        description: new StringField({
            key: 'description',
            validationGroup: 'settings'
        }),
        lat: new FloatField({
            key: 'lat',
            validationGroup: 'settings'
        }),
        lon: new FloatField({
            key: 'lon',
            validationGroup: 'settings'
        }),
        sub_operator_type: new SelectField({
            key: 'sub_operator_type',
            options: [
                {key: 'none', label: 'keiner'}
            ],
            validationGroup: 'refund'
        }),
        sub_operator_client: new SelectField({
            key: 'sub_operator_client',
            exportFilters: [ filterInteger ],
            initFilters: [ filterString ],
            options: subOperators.filter(operator => operator.type === 'light'),
            transformKey: (obj) => String(obj.id),
            transformLabel: (obj) => obj.name,
            validationGroup: 'refund',
            validators: [ new ValidateOptional() ]
        }),
        sub_operator_employee: new SelectField({
            key: 'sub_operator_employee',
            exportFilters: [ filterInteger ],
            initFilters: [ filterString ],
            options: subOperators.filter(operator => operator.type === 'employee'),
            transformKey: (obj) => String(obj.id),
            transformLabel: (obj) => obj.name,
            validationGroup: 'refund',
            validators: [ new ValidateOptional() ]
        }),
        refund_start: new DateField({
            key: 'refund_start',
            validators: [  new ValidateOptional() ],
            validationGroup: 'refund'
        }),
        refund_end: new DateField({
            key: 'refund_end',
            validators: [ new ValidateOptional() ],
            validationGroup: 'refund'
        }),
        refund_pricegroup: new SelectField({
            key: 'refund_pricegroup',
            options: [{}, ...pricegroupData, ...[{id: '0', name: 'Neue Preisgruppe anlegen', type: 'refund'}]].filter((pricegroup => pricegroup.type === 'refund')),
            exportFilters: [ filterInteger ],
            initFilters: [ filterString ],
            transformKey: (obj) => String(obj.id),
            transformLabel: (obj) => obj.name + ((obj.id !== '0') ? ' (' + formatPrice(obj.fee_energy) + '/kWh + ' + formatPrice(obj.fee_rent) + '/Monat)' : '')
        }),
    };

    constructor(data) {
        super();
        this.load(data);
        if (window.currentUser.hasOperatorCapability('charge-client')) {
            this.fields.sub_operator_type.options.push({key: 'charge-client', label: 'Sub-Betreiber'})
        }
        if (window.currentUser.hasOperatorCapability('charge-employee')) {
            this.fields.sub_operator_type.options.push({key: 'charge-employee', label: 'Mitarbeiter*in'})
        }
    }
}
