import Base from './Base';
import { SelectField } from '../Fields/SelectField';
import { StringField } from '../Fields/StringField';
import { ValidateDataRequired } from "../Validators";
import { filterUppercase, filterString, filterInteger } from "../Filter";

export default class Station extends Base {
    units = [];
    fields = {
        uid: new StringField({
            key: 'uid',
            validators: [new ValidateDataRequired()],
            liveFilters: [filterUppercase]
        }),
        name: new StringField({
            key: 'name'
        }),
        public: new SelectField({
            key: 'public',
            options: [
                {key: 'hidden', label: 'Nichtöffentlich'},
                {key: 'public', label: 'Öffentlich'}
            ]
        }),
        tech_backend: new SelectField({
            key: 'tech_backend',
            options: backendData
        }),
        hardware: new SelectField({
            key: 'hardware',
            options: hardwareData,
            initFilters: [ filterString ],
            exportFilters: [ filterInteger ],
            transformKey: (obj) => String(obj.id),
            transformLabel: (obj) => obj.manufacturer + ' ' + obj.name
        })
    };

    constructor(data) {
        super();
        this.load(data);
    }

    validate() {
        this.errors = [];
        super.validate();
        if (!this.deleted && !this.units.filter(unit => !unit.deleted).length) {
            this.errors.push(['', 'Eine Station muss Ladepunkte haben.']);
            this.isValid = false;
        }
        this.isValid = this.isValid || this.deleted;
        if (this.deleted && !this.units.filter(unit => unit.deleted).length) {
            this.errors.push(['', 'Säule kann nicht gelöscht werden, wenn nicht alle Punkte auch gelöscht werden.']);
            this.isValid = false;
        }
        return this.isValid;
    }

    getHasChanged(includeChildren) {
        let result = super.getHasChanged();
        if (includeChildren)
            this.units.map((unit, id) => {result = unit.getHasChanged() || result});
        return result;
    }
}
