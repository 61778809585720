import React from "react";
import moment from 'moment';
import { daterangepicker_ranges, daterangepicker_locale } from "../Constants";
import SearchTable from './SearchTable';
import {formatPrice} from "../Format";

export default class SearchTableTransfer extends SearchTable {
    params = {
        sort_field: 'created',
        sort_order: 'desc',
        daterange: moment().startOf('month').format('DD.MM.YYYY') + ' - ' + moment().endOf('month').format('DD.MM.YYYY'),
        page: 1
    };
    formId = 'transfer-search-form';
    varPrefix = 'searchTableTransfer';
    loadParamsRegExp = [
        /\/transfer\/(.*)/g
    ];
    submit_proceed = [
        'download',
        'download_details'
    ];

    apiUrl = '/api/transfers';
    sortDef = [
        { key: 'created', name: 'Erstellt' },
        { key: 'iban', name: 'Konto: IBAN'},
        { key: 'bic', name: 'Konto: BIC'},
        { key: 'value', name: 'Umsatz'},
        { key: 'invoice_number'}
    ];
    colDef = [
        { sortField: 'created', text: 'Datum' },
        { sortField: 'iban', text: 'Konto' },
        { sortField: 'invoice_number', text: 'Rechnungsnummer'},
        { sortField: 'value', text: 'Umsatz' },
        { text: 'Status' },
        { text: 'Aktionen' }
    ];

    init() {
        super.init();
        $('#daterange').daterangepicker({
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month'),
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, (start, end, label) => this.daterangepickerSubmit(start, end, label));
    }
    
    renderTableRow(row) {
        return (
            <tr key={`transfer-${row.id}`}>
                {this.renderDate(row)}
                {this.renderTableCellAccount(row)}
                {this.renderInvoiceNumber(row)}
                {this.renderTableCellValuePrice(row)}
                {this.renderStatus(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderDate(row) {
        return(
            <td>
                {this.formatDate(row.created)}<br/>
                <small>{row.uid}</small>
            </td>
        )
    }

    renderTableCellAccount(row) {
        return (
            <td>
                <a href={'/account/' + row.account_id + '/show'}>{this.formatAccountIdentifier(row.account_type, row.account_identifier)}</a><br/>
                <small>BIC: {row.account_bic}</small>
            </td>
        )
    }

    renderInvoiceNumber(row) {
        return(
            <td>
                {row.invoice_number}<br/>
                {this.renderOperatorSubline(row)}
            </td>
        )
    }

    renderOperatorSubline(row) {
        if (!this.operatorEnabled)
            return '';
        if (!row.operator)
            return '';
        return (
            <small><a href={'/operator/' + row.operator.id + '/show'}>{row.operator.name}</a></small>
        )
    }

    renderTableCellValuePrice(row) {
        return(
            <td>
                {formatPrice(row.value)}<br/>
                <small>Netto: {formatPrice(row.value_subtotal)}</small>
            </td>
        )
    }

    renderStatus(row) {
        return (
            <td>
                {this.renderStatusIcon('eye', row.seen_by_user_at, 'Beleg abgerufen ' + this.formatDate(row.seen_by_user_at))}
                {this.renderStatusIcon('ban', row.rejected)}
                {this.renderStatusIcon('check-circle', row.completed, 'Abgerechnet: ' + this.formatDate(row.processed_at))}
                {this.renderStatusIcon('file-text', row.invoice_generated_at, 'Rechnung generiert: ' + this.formatDate(row.invoice_generated_at))}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon('/transfer/' + row.id + '/show', 'info', 'anzeigen', true)}
            </td>
        )
    }
}