import { Field } from './Field';
import { ValidateFloat } from "../Validators";

export class DecimalField extends Field {
    validators = [ new ValidateFloat() ];

    constructor(params) {
        super();
        this.load(params);
    }
}