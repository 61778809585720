import {Decimal} from 'decimal.js';

export default class PricegroupManagement {
    constructor() {
        let tax_inputs = $('.tax');
        tax_inputs.change(function () {
            //$('#' + $(this).attr('id') + '_notax').val((String(Math.round($(this).val().replace(',', '.') / (1 + pricegroup_config.vat) * 100) / 100)).replace('.', ','));
            if (!$(this).val())
                return;
            let value = Decimal($(this).val().replace(',', '.'));
            value = value.div(Decimal(pricegroup_config.vat).plus(1));
            $('#' + $(this).attr('id') + '_notax').val(value.toFixed(2).replace('.', ','));
        });
        $('.notax').change(function () {
            let value = Decimal($(this).val().replace(',', '.'));
            value = value.mul(Decimal(pricegroup_config.vat).plus(1));
            $('#' + $(this).attr('id').substr(0, $(this).attr('id').length - 6)).val(value.toFixed(2).replace('.', ','));
        });
        tax_inputs.trigger('change');

        $('#fee_time_switch').change(function () {
            if ($('#fee_time_switch').is(':checked')) {
                $('#fee_time_all').parent().parent().parent().slideUp(400, function () {
                    if ($('#fee_time_switch').is(':checked'))
                        $('#fee_time_36, #fee_time_74, #fee_time_110, #fee_time_high').parent().parent().parent().slideDown();
                });
            }
            else {
                $('#fee_time_36, #fee_time_74, #fee_time_110, #fee_time_high').parent().parent().parent().slideUp(400, function () {
                    if (!$('#fee_time_switch').is(':checked'))
                        $('#fee_time_all').parent().parent().parent().slideDown();
                });
            }
        });
        $('#fee_time_disable').change(function () {
            if ($('#fee_time_disable').is(':checked')) {
                $('#fee_time_disable_start').parent().parent().parent().slideDown();
                $('#invoice_text_time_disable').parent().parent().slideDown();
            }
            else {
                $('#fee_time_disable_start').parent().parent().parent().slideUp();
                $('#invoice_text_time_disable').parent().parent().slideUp();
            }
        });
        $('#pricegroup-form').submit(function () {
            if ($('#fee_time_switch').is(':checked')) {
                $('#fee_time_all, #fee_time_all_notax').val('0');
            }
            else {
                $('#fee_time_36, #fee_time_74, #fee_time_110, #fee_time_high').val($('#fee_time_all').val());
            }
            if (!$('#fee_time_disable').is(':checked')) {
                $('#fee_time_disable_start, #fee_time_disable_end').val('00:00');
            }
        });
    }
};