import React from "react";
import moment from 'moment';
import { multiselect_options, daterangepicker_ranges, daterangepicker_locale } from '../Constants';
import SearchTable from './SearchTable'

export default class SearchTableUnitTechBackendLog extends SearchTable {
    params = {
        sort_field: 'created',
        sort_order: 'desc',
        page: 1,
        daterange: moment().subtract(1, 'week').format('DD.MM.YYYY') + ' - ' + moment().format('DD.MM.YYYY')
    };
    formId = 'unit-tech-backend-log-search-form';
    varPrefix = 'searchTableUnitTechBackendLog';
    loadParamsRegExp = [];

    sortDef = [
        { key: 'created', name: 'Erstellungsdatum' }
    ];

    colDef = [
        { sortField: 'created', text: 'Zeitpunkt' },
        { text: 'Typ' },
        { text: 'Inhalt' },
        { text: 'Aktionen' }
    ];

    init() {
        super.init();
        this.unitId = parseInt($('#' + this.formId).data('unit-id'));
        this.apiUrl = '/api/unit/' + String(this.unitId) + '/tech-backend-log';
        this.update_run = false;
        window.setTimeout(() => this.checkUpdate(), 200);
        let start = moment().subtract(1, 'week');
        let end = moment();
        $('#message_types').multiselect(multiselect_options);
        $('#daterange').daterangepicker({
            startDate: start,
            endDate: end,
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, (start, end, label) => this.daterangepickerSubmit(start, end, label));
    }

    checkUpdate() {
        if (this.last_update + 5000 < Date.now() && this.update_run === false) {
            this.update_run = true;
            this.updateData();
        }
        window.setTimeout(() => this.checkUpdate(), 200);
    }

    afterUpdate() {
        this.last_update = Date.now();
        this.update_run = false;
    }

    renderTableRow(row) {
        return (
            <tr key={`message-${row.id}`}>
                {this.renderTableCellCreated(row, true)}
                {this.renderTableCellType(row)}
                {this.renderTableCellData(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellType(row) {
        return(
            <td>
                {row.type}
            </td>
        )
    }

    renderTableCellData(row) {
        return (
            <td>
                {row.data}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td></td>
        )
    }
}