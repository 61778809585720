import React from "react";
import io from 'socket.io-client';
import moment from 'moment';

const { Component } = React;

export default class UnitsTcc extends Component {
    state = {
        initialized: false,
        loadError: false,
        configUpdating: [],
        configSaving: [],
        newConfigKey: '',
        newConfigValue: '',
        unitConfig: [],
        filterUnitUid: '',
        filterStationUid: ''
    };
    params = {};
    capabilities = [];


    componentDidMount() {
        moment.tz("2014-06-01 12:00", "Europe/Berlin");
        this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $('.btn-icon').tooltip();
    }

    init() {
        let box = document.getElementById('units-tcc-box');
        this.params.csrf_token = box.getAttribute('data-csrf-token');
        this.socketUrl = box.getAttribute('data-socket-url');

        this.socket = io(this.socketUrl + '/units/tcc');
        this.socket.on('connect', () => {
            this.socket.emit('init', this.params);
        });
        this.socket.on('base', (data) => {
            if (!data || data.status) {
                this.setState({
                    loadError: true,
                    initialized: true
                });
                return;
            }
            let units = Object.assign({}, window.units);
            for (let i = 0; i < data.data.length; i++) {
                if (!units[data.data[i].id])
                    continue;
                units[data.data[i].id] = Object.assign(units[data.data[i].id], data.data[i]);
            }
            this.setState({
                initialized: true,
                units: units
            })
        });
        this.socket.on('update', (data) => {
            let units = this.state.units;
            if (data.type === 'ConnectionChange') {
                units[data.meta.unit].connection = data.data.status;
            }
            if (data.type === 'StatusNotification') {
                units[data.meta.unit].error = data.data.errorCode;
                units[data.meta.unit].status = data.data.status;
                units[data.meta.unit].error_vendor = data.data.vendorId;
                units[data.meta.unit].error_code = data.data.vendorErrorCode;
            }
            this.setState({
                units: units
            });
        });
    }

    extendUnitGroup(unit_group_id) {
        this.setState({
            unitGroupExtended: (unit_group_id === this.state.unitGroupExtended) ? undefined : unit_group_id
        })
    }

    render() {
        if (!this.state.initialized) {
            return (
                <div>... wird geladen ...</div>
            )
        }
        if (this.state.loadError) {
            return (
                <div>Fehler: das technische Backend ist zur Zeit nicht erreichbar.</div>
            )
        }
        let unit_groups = {};
        for (let [unit_id, unit] of Object.entries(this.state.units)) {
            if (!unit_groups[unit.unit_group_id]) {
                unit_groups[unit.unit_group_id] = {
                    id: unit.unit_group.id,
                    name: unit.unit_group.name,
                    address: unit.unit_group.address,
                    locality: unit.unit_group.locality,
                    postalcode: unit.unit_group.postalcode,
                    stations: {}
                }
            }
            if (!unit_groups[unit.unit_group_id].stations[unit.uid_station]) {
                unit_groups[unit.unit_group_id].stations[[unit.uid_station]] = {
                    uid: unit.uid_station,
                    name: (unit.name_station) ? unit.name_station : unit.uid_station,
                    units: []
                }
            }
            unit_groups[unit.unit_group_id].stations[unit.uid_station].units.push(unit)
        }

        return (
            <div>
                <div className="row row-form">
                    <div className="col-md-6">
                        <label>EVSEID</label>
                        <input
                            className="form-control"
                            value={this.state.filterUnitUid}
                            onChange={evt => this.setState({filterUnitUid: evt.target.value})}
                        />
                    </div>
                    <div className="col-md-6">
                        <label>Stations-ID</label>
                        <input
                            className="form-control"
                            value={this.state.filterStationUid}
                            onChange={evt => this.setState({filterStationUid: evt.target.value})}
                        />
                    </div>
                </div>
                {Object.values(unit_groups).map(unit_group => {
                    return this.renderUnitGroup(unit_group);
                })}
            </div>
        );
    }

    renderUnitGroup(unit_group) {
        if (this.state.filterStationUid || this.state.filterUnitUid) {
            let foundStation = false;
            let foundUnit = false;
            Object.values(unit_group.stations).forEach(station => {
                if (this.state.filterStationUid && station.uid.indexOf(this.state.filterStationUid) !== -1)
                    foundStation = true;
                if (this.state.filterUnitUid) {
                    station.units.forEach(unit => {
                        if (unit.uid.indexOf(this.state.filterUnitUid) !== -1)
                            foundUnit = true;
                    });
                }
            });
            if ((this.state.filterStationUid && !foundStation) || (this.state.filterUnitUid && !foundUnit))
                return;
        }
        return (
            <div className="row" style={{borderBottom: '1px solid #666', padding: '3px 0'}} key={`unit-group-${unit_group.id}`}>
                <div className="col-md-3" style={{alignSelf: 'center'}}>
                    {Object.values(unit_group.stations).map(station => {
                        return station.units.map(unit => {
                            return this.getStatusCircle(unit);
                        })
                    })}
                </div>
                <div className="col-md-6" style={{cursor: 'pointer'}} onClick={this.extendUnitGroup.bind(this, unit_group.id)}>
                    {unit_group.name}<br/>
                    <small>{unit_group.address} {unit_group.postalcode} {unit_group.locality}</small>
                </div>
                <div className="col-3">

                </div>
                {this.renderUnitGroupExtended(unit_group)}
            </div>
        );
    }

    renderUnitGroupExtended(unit_group) {
        if (this.state.unitGroupExtended !== unit_group.id)
            return;
        return (
            <div className="col-md-12" style={{padding: 0}}>
                <table className="table">
                    <tbody>
                    {Object.values(unit_group.stations).map(station => {
                        return station.units.map((unit, unit_i) => {
                            return(
                                <tr key={`unit-${unit.uid}`}>
                                    <td style={{paddingLeft: '15px'}}>{this.getStatusCircle(unit)}</td>
                                    <td><a href={`/unit/${unit.id}/show`}>{unit.uid}</a></td>
                                    <td>
                                        {unit.connection === 'online' &&
                                            <span>
                                                {this.formatUnitStatus(unit.status)}
                                                {unit.error !== 'NoError' &&
                                                    <span>{this.formatUnitError(unit.error)}</span>
                                                }
                                            </span>
                                        }
                                        {unit.connection === 'offline' &&
                                            <span>offline</span>
                                        }
                                    </td>
                                    {unit_i === 0 &&
                                        <td rowSpan={station.units.length} style={{verticalAlign: 'middle'}}>{station.name}</td>
                                    }
                                </tr>
                            );
                        });
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

    getStatusCircle(unit) {
        let connection_color = '#6c757d';
        if (unit.connection === 'offline' || (unit.connection === 'online' && unit.error !== 'NoError')) {
            connection_color = '#dc3545';
        }
        else if (unit.connection === 'online' && unit.status === 'Available') {
            connection_color = '#28a745';
        }
        else if (unit.connection === 'online' && unit.status === 'Charging') {
            connection_color = '#007bff';
        }
        else if (unit.connection === 'online') {
            connection_color = '#ffc107';
        }
        return <i className="fa fa-circle" aria-hidden="true" style={{color: connection_color, marginRight: '2px'}} key={`circle-${unit.uid}`}></i>;
    }

    renderUnitRow(unit) {
        let connection_color = '#6c757d';
        if (unit.connection === 'offline' || (unit.connection === 'online' && unit.error !== 'NoError')) {
            connection_color = '#dc3545';
        }
        else if (unit.connection === 'online' && unit.status === 'Available') {
            connection_color = '#28a745';
        }
        else if (unit.connection === 'online' && unit.status === 'Charging') {
            connection_color = '#007bff';
        }
        else if (unit.connection === 'online') {
            connection_color = '#ffc107';
        }
        return (
            <tr key={`unit-details-${unit.id}`}>
                <td>
                    <i className="fa fa-circle" aria-hidden="true" style={{color: connection_color}}></i>
                </td>
                <td><a href={`/unit/${unit.id}/show`}>{unit.uid}</a></td>
                <td>
                    {(unit.unit_group.name !== undefined) &&
                        <span>{unit.unit_group.name}</span>
                    }
                    {unit.uid_station}
                </td>
                <td>
                    {unit.connection === 'online' &&
                        <span>
                            {this.formatUnitStatus(unit.status)}
                            {unit.error !== 'NoError' &&
                                <span>{this.formatUnitError(unit.error)}</span>
                            }
                        </span>
                    }
                    {unit.connection === 'offline' &&
                        <span>offline</span>
                    }
                </td>
            </tr>
        )
    }

    formatUnitStatus(value) {
        if (value === 'Available')
            return 'verfügbar';
        if (value === 'Preparing')
            return 'bereitet Ladevorgang vor';
        if (value === 'Charging')
            return 'Fahrzeug lädt';
        if (value === 'SuspendedEVSE')
            return 'Stromabgabe von Ladepunkt beendet, Ladekabel noch angeschlossen';
        if (value === 'SuspendedEV')
            return 'Stromaufnahme von Fahrzeug beendet, Ladekabel noch angeschlossen';
        if (value === 'Finishing')
            return 'Ladevorgang beendet / abgebrochen, Ladekabel noch angeschlossen';
        if (value === 'Reserved')
            return 'reserviert';
        if (value === 'Unavailable')
            return 'nicht verfügbar';
        if (value === 'Faulted')
            return 'Fehler aufgetreten';
        return value;
    }

    formatUnitError(value) {
        if (value === 'NoError')
            return 'kein Fehler';
        if (value === 'ConnectorLockFailure')
            return 'Fehler in der Verriegelung';
        if (value === 'EVCommunicationError')
            return 'Fehler in der Kommunikation zwischen Säule und Fahrzeug';
        if (value === 'GroundFailure')
            return 'Erdungs-Fehler';
        if (value === 'HighTemperature')
            return 'Fehler durch zu hohe Temperatur';
        if (value === 'InternalError')
            return 'Software-Fehler in der Ladeäule';
        if (value === 'LocalListConflict')
            return 'Fehler durch einen Autorisierungs-Listen-Konflikt';
        return value;
    }
}