import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableTokenFleet extends SearchTable {
    params = {
        sort_field: 'created',
        sort_order: 'desc',
        page: 1
    };
    formId = 'token-fleet-search-form';
    varPrefix = 'searchTableTokenFleet';
    loadParamsRegExp = [
        /\/token\/fleet\/(.*)/g
    ];

    apiUrl = '/api/tokens/fleet';
    sortDef = [
        { key: 'identifier', name: 'Identifier'},
        { key: 'created', name: 'Erstellt' }
    ];

    colDef = [
        { sortField: 'identifier', text: 'Identifier' },
        { text: 'Betreiber'},
        { text: 'Beschreibung'},
        { text: 'Aktionen' }
    ];

    renderTableRow(row) {
        return (
            <tr key={`token-${row.id}`}>
                {this.renderTableCellToken(row)}
                {this.renderTableCellOperator(row)}
                {this.renderTableCellText(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellToken(row) {
        return(
            <td>
                {row.name}<br/>
                <small>{row.identifier.replace(/(\S{2})/g, '$1 ').replace(/(^\s+|\s+$)/,'')}</small>
            </td>
        )
    }

    renderTableCellText(row) {
        return(
            <td>{row.description_internal}</td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/token/fleet/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/token/fleet/${row.id}/edit`, 'edit', 'bearbeiten', this.capabilities.includes('edit'))}
                {this.renderTableCellActionIcon(`/token/fleet/${row.id}/delete`, 'trash', 'löschen', this.capabilities.includes('delete'))}
            </td>
        )
    }
}