import React from "react";
const { Component } = React;
import io from 'socket.io-client';
import {Typeahead} from 'react-bootstrap-typeahead';

export default class LiveLog extends Component {
    io = null

    errorLevels = [
        {key: 'debug', level: 6, label: 'Debug'},
        {key: 'info', level: 5, label: 'Info'},
        {key: 'warn', level: 4, label: 'Warning'},
        {key: 'error', level: 3, label: 'Error'},
        {key: 'exception', level: 2, label: 'Exception'},
        {key: 'critical', level: 1, label: 'Critical'},
    ]

    errorLevelsByLevel = {}

    constructor() {
        super();
        this.errorLevels.forEach(level => this.errorLevelsByLevel[level.key] = level.level)
    }

    pre = null

    state = {
        errorLevel: {key: 'info', level: 5, label: 'Info'},
        logNames: [],
        logName: null,
        logMessages: [],
        autoScroll: true
    }

    filteredMessages() {
        return this.state.logMessages.filter(message => {
            if (!this.state.errorLevel) {
                return false
            }
            return this.errorLevelsByLevel[message.level] <= this.state.errorLevel.level
        })
    }

    onLogSelectionchange(val) {
        if (!val.length) {
            return
        }
        const newVal =  val[0]
        if (newVal === this.state.logName) {
            return
        }
        if (this.state.logName) {
            this.io.emit('unsubscribe-log', {log_name: this.state.logName})
        }
        this.setState({logName: newVal, logMessages: []})
        this.io.emit('subscribe-log', {log_name: newVal})
    }

    scrollToBottom = () => {
        this.pre.scrollIntoView({ behavior: "smooth", block: 'end', inline: 'start' });
    }

    arrayfy(val) {
        return val ? [val] : []
    }

    componentDidMount() {
        this.io = io()
        this.io.on('log-entry', (message) => {
            this.setState(prevState => ({logMessages: [...prevState.logMessages, message]}))
            if (this.state.autoScroll) {
                this.scrollToBottom()
            }
        })
        $.getJSON('/api/log-names', (result) => {
            this.setState({logNames: result})
        })
    }

    componentWillUnmount() {
        if (this.state.logName) {
            this.io.emit('unsubscribe-log', {log_name: this.state.logName})
        }
    }

    render() {
        return <div>
            <div className='row'>
                <div className='col'>
                    <label>Logname</label>
                    <Typeahead
                        id='logNames'
                        options={this.state.logNames}
                        placeholder='Log auswählen..'
                        onChange={(e) => this.onLogSelectionchange(e)}
                    />
                </div>
                <div className='col'>
                    <label>Loglevel</label>
                    <Typeahead
                        id='logLevels'
                        labelKey="label"
                        options={this.errorLevels}
                        placeholder='Level auswählen..'
                        onChange={(e) => this.setState({errorLevel: e.length ? e[0] : null})}
                        selected={this.arrayfy(this.state.errorLevel)}
                    />
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col'>

                        <input type='checkbox' onChange={() => this.setState({autoScroll: !this.state.autoScroll})} checked={this.state.autoScroll}/>
                        <label className='ml-2 form-check-label'>Auto scroll</label>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col'>
                    <pre style={{height: '70vh', overflow: 'scroll'}}>
                        <code ref={el => this.pre = el} >
                                    {this.filteredMessages().map(message => message.message).join('\n')
                                    }
                        </code>
                    </pre>
                </div>

            </div>
        </div>
    }
}