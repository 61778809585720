import React from "react";
import moment from 'moment';
import { multiselect_options, daterangepicker_ranges, daterangepicker_locale } from '../Constants';
import SearchTable from './SearchTable'

export default class SearchTableNotification extends SearchTable {
    params = {
        sort_field: 'created',
        sort_order: 'desc',
        page: 1,
        daterange: moment().subtract(1, 'week').format('DD.MM.YYYY') + ' - ' + moment().format('DD.MM.YYYY'),
    };
    formId = 'notification-search-form';
    varPrefix = 'searchTablenNotification';
    loadParamsRegExp = [
        /\/notification\/(.*)/g
    ];

    apiUrl = '/api/notifications';
    sortDef = [
        { key: 'created', name: 'Erstellungsdatum' },
    ];

    colDef = [
        { sortField: 'created', text: 'Zeitpunkt' },
        { text: 'Level' },
        { text: 'Kategorie' },
        { text: 'Nachricht' },
        { text: 'Aktionen' }
    ];

    init() {
        super.init();
        this.last_update = Date.now();
        this.update_run = false;
        window.setTimeout(() => this.checkUpdate(), 200);
        $('#category').multiselect(multiselect_options);
        $('#level').multiselect(multiselect_options);
        let start = moment().subtract(1, 'week');
        let end = moment();
        $('#daterange').daterangepicker({
            startDate: start,
            endDate: end,
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, (start, end, label) => this.daterangepickerSubmit(start, end, label));
    }

    checkUpdate() {
        if (this.last_update + 5000 < Date.now() && this.update_run === false) {
            this.update_run = true;
            this.updateData();
        }
        window.setTimeout(() => this.checkUpdate(), 200);
    }

    afterUpdate() {
        this.last_update = Date.now();
        this.update_run = false;
    }

    renderTableRow(row) {
        return (
            <tr key={`notification-${row.id}`}>
                {this.renderTableCellCreated(row, true)}
                {this.renderTableCellLevel(row)}
                {this.renderTableCellCategory(row)}
                {this.renderTableCellSubject(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellCategory(row) {
        return(
            <td>
                {row.category}
            </td>
        )
    }

    renderTableCellLevel(row) {
        return (
            <td>
                {row.level}
            </td>
        )
    }
    renderTableCellSubject(row) {
        return (
            <td>
                {row.subject}
            </td>
        )
    }
    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/notification/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/notification/${row.id}/download`, 'download', 'downloaden', row.download)}
            </td>
        )
    }
}