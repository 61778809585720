import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableFirmware extends SearchTable {
    params = {
        sort_field: 'modified',
        sort_order: 'desc',
        page: 1
    };
    apiUrl = '/api/firmwares';
    formId = 'firmware-search-form';
    varPrefix = 'searchTableFirmware';
    loadParamsRegExp = [
        /\/firmware\/(.*)/g
    ];

    sortDef = [
        { key: 'firmware_version', name: 'Version' },
        { key: 'created', name: 'Erstellt' },
        { key: 'modified', name: 'Bearbeitet' }
    ];

    colDef = [
        { sortField: 'firmware_version', text: 'Version' },
        { text: 'Hardware' },
        { text: 'Status'},
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr key={`firmware-${row.id}`}>
                {this.renderTableCellFirmwareVersion(row)}
                {this.renderTableCellHardware(row)}
                {this.renderTableCellStatus(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellFirmwareVersion(row) {
        return(
            <td>
                {row.firmware_version}
            </td>
        )
    }

    renderTableCellHardware(row) {
        return(
            <td>
                {row.hardware.map(hardware =>
                    <span key={`hardware-${hardware.id}`}>
                        <a href={`/hardware/${hardware.id}/show`}>
                            {hardware.name}
                        </a><br/>
                    </span>
                )}

            </td>
        )
    }

    renderTableCellStatus(row) {
        return(
            <td>
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/firmware/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/firmware/${row.id}/edit`, 'pencil', 'bearbeiten', true)}
            </td>
        )
    }
}