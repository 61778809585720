import React from "react";
const { Component } = React;

import FormSelect from '../../FormField/FormSelect';
import FormDate from "../../FormField/FormDate";
import ViewBase from '../../View';
import FormInput from "../../FormField/FormInput";


export default class ViewRefund extends ViewBase {
    obj = 'unitGroup';

    constructor(props) {
        super(props);
        this.state[this.obj] = props[this.obj];
        this.state.refundPricegroup = props.refundPricegroup;
    }

    render() {
        return (
            <div className="container">
                <div className="row row-form">
                    <div className="col-md-12">
                        <h2>Refund</h2>
                    </div>
                </div>
                <div className="row row-form">
                    <FormSelect
                        field={this.state.unitGroup.fields.sub_operator_type}
                        label='Refund-Empfänger'
                        width='6'
                        updateParent={this.updateParent.bind(this)}
                    />
                    {this.state.unitGroup.fields.sub_operator_type.value === 'charge-client' &&
                        <FormSelect
                            field={this.props.unitGroup.fields.sub_operator_client}
                            label='Refund-Empfänger'
                            width='6'
                            updateParent={this.updateParent.bind(this)}
                        />
                    }
                    {this.state.unitGroup.fields.sub_operator_type.value === 'charge-employee' &&
                        <FormSelect
                            field={this.props.unitGroup.fields.sub_operator_employee}
                            label='Refund-Empfänger'
                            width='6'
                            updateParent={this.updateParent.bind(this)}
                        />
                    }
                </div>
                {this.state.unitGroup.fields.sub_operator_type.value !== 'none' &&
                    <div className="row row-form">
                        <FormDate
                            field={this.props.unitGroup.fields.refund_start}
                            label='Beginn des Refunds'
                            width='6'
                        />
                        <FormDate
                            field={this.props.unitGroup.fields.refund_end}
                            label='Ende des Refunds'
                            width='6'
                        />
                    </div>
                }
                {this.state.unitGroup.fields.sub_operator_type.value !== 'none' &&
                    <div className="row row-form">
                        <FormSelect
                            width="12"
                            field={this.state.unitGroup.fields.refund_pricegroup}
                            label="Preisgruppe"
                            updateParent={this.updateParent.bind(this)}
                        />
                    </div>
                }
                {(this.state.unitGroup.fields.sub_operator_type.value !== 'none' && this.state.unitGroup.fields.refund_pricegroup.value === '0') &&
                    <div className="row row-form">
                        <FormInput
                            width="12"
                            field={this.state.refundPricegroup.fields.name}
                            label="Name"
                        />
                    </div>
                }
                {(this.state.unitGroup.fields.sub_operator_type.value !== 'none' && this.state.unitGroup.fields.refund_pricegroup.value === '0') &&
                    <div className="row row-form">
                        <FormInput
                            width="6"
                            field={this.state.refundPricegroup.fields.fee_energy}
                            label="Erstattete Energiekosten pro kWh"
                            unit="euro"
                        />
                        <FormInput
                            width="6"
                            field={this.state.refundPricegroup.fields.fee_rent}
                            label="Monatliche Miete"
                            unit="euro"
                        />
                    </div>
                }
            </div>
        )
    }
}