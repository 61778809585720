import React from "react";
import { base64ToUint8Array } from '../Common';
const { Component } = React;

export default class PushNotificationSettings extends Component {
    state = {
        initialized: false
    };

    componentDidMount() {
        this.applicationServerPublicKeyBase64 = document.getElementById('push-notification-settings').getAttribute('data-public-key');
        localStorage.setItem('glsApplicationServerPublicKey', this.applicationServerPublicKeyBase64);
        this.applicationServerPublicKey = base64ToUint8Array(this.applicationServerPublicKeyBase64);
        this.csrfToken = document.getElementById('push-notification-settings').getAttribute('data-csrf-token');

        if (!('serviceWorker' in navigator && 'PushManager' in window) || !window.common.getStorageAvailable()) {
            this.setState({
                unsupported: true,
                initialized: true
            });
            return;
        }
        navigator.serviceWorker.register("/static/lib/service.js")
            .then((serviceWorker) => {
                serviceWorker.update();
                this.serviceWorker = serviceWorker;
                this.loadInterface();
                this.setState({
                    subscriptions: JSON.parse(document.getElementById('push-notification-settings').getAttribute('data-subscriptions')),
                    ownSubscriptionId: localStorage.getItem('glsOwnSubscribtionId')
                });
            })
            .catch((error) => {
                this.setState({
                    unsupported: true,
                    initialized: true
                });
            });
    }

    loadInterface() {
        this.serviceWorker.pushManager.getSubscription()
            .then((subscription) => {
                this.setState({
                    initialized: true,
                    subscribed: !(subscription === null)
                });
            });
    }

    subscribeUser(evt) {
        evt.preventDefault();
        this.serviceWorker.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: this.applicationServerPublicKey
            })
            .then((subscription) => {
                $.post('/api/account/subscribe', {data: JSON.stringify(subscription), csrf_token: this.csrfToken})
                    .then((data) => {
                        localStorage.setItem('glsOwnSubscribtionId', data.data.id);
                        this.setState({
                            subscribed: true,
                            ownSubscriptionId: data.data.id,
                            subscriptions: data.data.subscriptions
                        });
                    });
            })
    }

    unsubscribeUser() {
        this.serviceWorker.pushManager.getSubscription()
            .then((subscription) => {
                if (subscription) {
                    subscription.unsubscribe();
                    $.post('/api/account/unsubscribe', {data: JSON.stringify(subscription), csrf_token: this.csrfToken})
                        .then((data) => {
                            this.setState({
                                subscribed: false,
                                subscriptions: data.subscriptions
                            });
                        });
                }
            })

    }

    render() {
        if (!this.state.initialized)
            return <div>...</div>;
        if (this.state.unsupported)
            return <div>Push-Benachrichtigungen werden von Ihrem Browser nicht unterstützt.</div>;
        return (
            <div>
                {!this.state.subscribed &&
                    <p>
                        <button className="btn" onClick={this.subscribeUser.bind(this)}>
                            Push-Nachrichten mit diesem Browser aktivieren
                        </button>
                    </p>
                }
                {this.state.subscribed &&
                    <p>
                        <button className="btn" onClick={this.unsubscribeUser.bind(this)}>
                            Push-Nachrichten mit diesem Browser deativieren
                        </button>
                    </p>
                }
                {this.renderSubscriptions()}
            </div>
        )
    }

    renderSubscriptions() {
        if (!this.state.subscriptions || !this.state.subscriptions.length) {
            return <div>Bislang keine Push-Nachrichten aktiviert</div>
        }
        let subscriptions = [];
        for (let i = 0; i < this.state.subscriptions.length; i++) {
            subscriptions.push(
                <li key={this.state.subscriptions[i].id}>
                    {this.state.subscriptions[i].user_agent}
                </li>
            )
        }
        return (
            <ul>
                {subscriptions}
            </ul>
        )
    }
}