import Base from './Base';
import { IntegerField } from '../Fields/IntegerField';
import { ListField } from '../Fields/ListField';
import { StringField } from '../Fields/StringField';
import { ValidateOptional } from "../Validators";


export default class User extends Base {
    units = [];
    fields = {
        id: new IntegerField({
            key: 'id',
            validators: [new ValidateOptional()]
        }),
        type: new StringField({
            key: 'type',
            validators: [new ValidateOptional()]
        }),
        capabilities: new ListField({
            key: 'capabilities',
            validators: [new ValidateOptional()]
        }),
        operator_capabilities: new ListField({
            key: 'operator_capabilities',
            validators: [new ValidateOptional()]
        })
    };

    constructor(data) {
        super();
        this.load(data);
    }

    getHasChanged(includeChildren) {
        let result = super.getHasChanged();
        if (includeChildren)
            this.units.map((unit, id) => {result = unit.getHasChanged() || result});
        return result;
    }

    hasCapability(capability) {
        if (['admin', 'tenant'].includes(this.fields.type.value))
            return true;
        return this.fields.capabilities.value.includes(capability);
    }

    hasOperatorCapability(capability) {
        return this.fields.operator_capabilities.value.includes(capability);
    }
}
