import React from "react";
import moment from 'moment';
import { Scatter } from 'react-chartjs-2';
const { Component } = React;

export default class ChargeMeterTimeline extends Component {
    state = {
        initialized: false
    };

    componentDidMount() {
        this.chartjs_options = {
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                yAxes: [
                    {
                        id: 'energy',
                        position: 'left',
                        scaleLabel: {
                            fontSize: 14,
                            labelString: 'Energie [kWh]',
                            display: true
                        },
                        ticks: {
                            fontSize: 14
                        }
                    },
                    {
                        id: 'power',
                        position: 'right',
                        scaleLabel: {
                            fontSize: 14,
                            labelString: 'Leistung [kW]',
                            display: true
                        },
                        ticks: {
                            fontSize: 14
                        }
                    }
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            fontSize: 14,
                            labelString: 'Zeit [Minuten]',
                            display: true
                        },
                        ticks: {
                            fontSize: 14
                        }
                    }
                ]
            },
            tooltips: {
                titleFontSize: 14,
                bodyFontSize: 14,
                callbacks: {
                    label: (tooltipItem, data) => this.renderTooltipLabel(tooltipItem, data)
                }
            }
        };
        let measurements = [];
        let powers = [];
        let times = Object.keys(meter_timeline_data).map(Number).sort((a, b) => a - b);
        for (let i = 0; i < times.length; i++) {
            measurements.push({
                x: times[i] / 60,
                y: meter_timeline_data[String(times[i])] / 1000
            });
            if (i === 0)
                continue;
            if (i === 1) {
                powers.push({
                    x: 0,
                    y: (meter_timeline_data[String(times[i])] - meter_timeline_data[String(times[i - 1])]) / (times[i] - times[i - 1]) * 3.6
                });
            }
            powers.push({
                x: (times[i - 1] + (times[i] - times[i - 1]) / 2) / 60,
                y: (meter_timeline_data[String(times[i])] - meter_timeline_data[String(times[i - 1])]) / (times[i] - times[i - 1]) * 3.6
            });
            if (i === times.length - 1) {
                powers.push({
                    x: times[i] / 60,
                    y: (meter_timeline_data[String(times[i])] - meter_timeline_data[String(times[i - 1])]) / (times[i] - times[i - 1]) * 3.6
                });
            }
        }
        this.chartjs_data = {
            labels: ['Scatter'],
            datasets: [
                {
                    label: 'Energie',
                    borderColor: 'rgba(215, 236, 3, 1)',
                    backgroundColor: 'rgba(215, 236, 3, 1)',
                    showLine: true,
                    fill: false,
                    yAxisID: 'energy',
                    data: measurements
                },
                {
                    label: 'Leistung',
                    borderColor: 'rgba(215, 10, 3, 1)',
                    backgroundColor: 'rgba(215, 10, 3, 1)',
                    showLine: true,
                    fill: false,
                    yAxisID: 'power',
                    data: powers
                }
            ]
        };
        this.setState({
            initialized: true
        });
    }

    renderTooltipLabel(tooltipItem, data) {
        if (tooltipItem.datasetIndex === 0) {
            return 'Messung in Minute '
                + this.renderMinutes(tooltipItem.xLabel)
                + ': ' + this.renderEnergy(tooltipItem.yLabel)
                + ' kWh';
        }
        if (tooltipItem.datasetIndex === 1) {
            return 'Ladeleistung in Minute '
                + this.renderMinutes(tooltipItem.xLabel)
                + ': ' + this.renderEnergy(tooltipItem.yLabel)
                + ' kW';
        }
    }


    render() {
        if (!this.state.initialized)
            return (
                <div>...</div>
            );
        return(
            <Scatter
                data={this.chartjs_data}
                height={500}
                options={this.chartjs_options}
            />
        );
    }

    renderMinutes(value) {
        return Math.floor(value) + ':' + this.prependZero(Math.floor(value % 1 * 60));
    }

    prependZero(value) {
        if (value < 10)
            return '0' + String(value);
        return String(value);
    }

    renderEnergy(value) {
        return String(Math.round(value * 1000) / 1000).replace('.', ',');
    }
}