import React from "react";
const { Component } = React;

import { SelectField } from "../Fields/SelectField";
import { filterInteger, filterString } from "../Filter";
import { requestGet, requestPost } from "../../Common";
import ViewOperator from "./Views/ViewOperator";
import ViewUnitGroup from "./Views/ViewUnitGroup";


export default class StationMove extends Component {
    state = {
        initialized: false,
        viewState: 'operator'
    };

    constructor(props) {
        super(props);
        this.state.operators = validOperators;
        this.state.pricegroups = pricegroupData;
        this.state.unitGroups = unitGroupData;
        if (this.state.operators.length) {
            this.state.viewState = 'operator';
        }
    }

    componentDidMount() {
        if (this.state.operators.length === 1) {
            return this.setOperator(this.state.operators[0].id)
        }
        let operatorField = new SelectField({
            key: 'operator',
            options: this.state.operators,
            initFilters: [ filterString ],
            exportFilters: [ filterInteger ],
            transformKey: (obj) => String(obj.id),
            transformLabel: (obj) => obj.name
        });
        this.setState({
            initialized: true,
            operatorField: operatorField
        });
    }

    setOperator(operator_id) {
        let update = {
            initialized: true,
            viewState: 'unitGroup',
            selectedOperator: operator_id,
            unitGroupField: new SelectField({
                key: 'unitGroup',
                options: [
                    ...this.state.unitGroups.filter(unitGroup => unitGroup.operator_id === operator_id),
                    {id: 0, name: 'Neue Ladegruppe'}
                ],
                initFilters: [ filterString ],
                exportFilters: [ filterInteger ],
                transformKey: (obj) => String(obj.id),
                transformLabel: (obj) => ((obj.name) ? obj.name + ((!obj.address) ? '' : ', ') : '') + ((obj.address) ? obj.address + ', ' + obj.postalcode + ' ' + obj.locality : '')
            })
        };
        if (operator_id === this.state.operators[0].id) {
            return this.setState(update);
        }
        update.pricegroupField = new SelectField({
            key: 'pricegroup',
            options: [
                ...this.state.pricegroups.filter(pricegroup => pricegroup.operator_id === operator_id),
                {id: 0, name: 'Neue Preisgruppe'}
            ],
            initFilters: [ filterString ],
            exportFilters: [ filterInteger ],
            transformKey: (obj) => String(obj.id),
            transformLabel: (obj) => obj.name
        });
        this.setState(update);
    }

    setUnitGroup(update) {
        let data_send = {
            unit_id: currentUnitId,
            operator_id: this.state.selectedOperator,
            ...update
        };
        requestGet('/api/common/csrf').then((data) => {
            requestPost('/api/unit-station/move', data_send,  data.csrf)
                .then((data) => {
                    if (data.status) {
                        this.setState({
                            state: 'error'
                        });
                        return;
                    }
                    window.location = '/unit-groups';
                    this.setState({
                        state: 'success'
                    });
                });
        });
    }

    render() {
        if (!this.state.initialized)
            return <div>... wird geladen ...</div>;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <h1>Station verschieben</h1>
                    </div>
                </div>
                {this.state.viewState === 'operator' &&
                    <ViewOperator
                        operatorField={this.state.operatorField}
                        setOperator={this.setOperator.bind(this)}
                    />
                }
                {this.state.viewState === 'unitGroup' &&
                    <ViewUnitGroup
                        unitGroupField={this.state.unitGroupField}
                        pricegroupField={this.state.pricegroupField}
                        setUnitGroup={this.setUnitGroup.bind(this)}
                    />
                }
            </div>
        )
    }
}