import React from "react";
import moment from 'moment';
import { daterangepicker_ranges, daterangepicker_locale } from "../Constants";
import SearchTable from './SearchTable';

export default class SearchTablePreRegisterToken extends SearchTable {
    params = {
        sort_field: 'created',
        sort_order: 'desc',
        daterange: moment().subtract(29, 'days').format('DD.MM.YYYY') + ' - ' + moment().format('DD.MM.YYYY'),
        page: 1
    };
    formId = 'token-pre-register-search-form';
    varPrefix = 'searchTableToken';
    loadParamsRegExp = [
        /\/token\/(.*)/g
    ];

    apiUrl = '/api/tokens/pre-register';
    sortDef = [
        { key: 'identifier', name: 'Identifier'},
        { key: 'created', name: 'Erstellt' },

    ];

    colDef = [
        { sortField: 'identifier', text: 'Identifier' },
        { sortField: 'created', text: 'Erstellt' },
        { text: 'Auth-Code'},
        { text: 'Quelle'},
        { text: 'Status'},
        { text: 'Aktionen' }
    ];

    init() {
        super.init();
        var start = moment().subtract(29, 'days');
        var end = moment();
        $('#daterange').daterangepicker({
            startDate: start,
            endDate: end,
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, (start, end, label) => this.daterangepickerSubmit(start, end, label));
    }
    
    renderTableRow(row) {
        return (
            <tr key={`token-pre-register-${row.id}`}>
                {this.renderTableCellIdentifier(row)}
                {this.renderTableCellCreated(row, true)}
                {this.renderTableCellAuth(row)}
                {this.renderTableCellSource(row)}
                {this.renderTableCellStatus(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellIdentifier(row) {
        return(
            <td>
                {row.identifier}<br/>
                <small>{row.visible_until && new Date(row.visible_until) > new Date() && `Sichtbar bis ${this.formatDateTime(row.visible_until)}`}</small>
            </td>
        )
    }

    renderTableCellAuth(row) {
        return(
            <td>
                {row.auth}
            </td>
        )
    }

    renderTableCellSource(row) {
        if (row.cashpoint)
            return(
                <td>
                    <a href={`/cashpoint/${row.cashpoint.id}/show`}>Kasse {row.cashpoint.name}</a>
                </td>
            );
        if (row.unit)
            return(
                <td>
                    <a href={`/unit/${row.unit.id}/show`}>Ladepunkt {row.unit.uid}</a>
                </td>
            );
        return(
            <td></td>
        );
    }

    renderTableCellStatus(row) {
        return(
            <td>
                {this.renderStatusIcon('eye', row.seen_by_user_at)}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/token/pre-register/${row.id}/show`, 'info', 'anzeigen', true)}
                {(!row.visible_until || new Date(row.visible_until) > new Date()) && this.renderTableCellActionIcon(`/token/fleet/new?id=${encodeURIComponent(row.identifier)}`, 'car', 'als Flottenkarte registrieren', this.capabilities.includes('fleet-edit') && !row.anonymous)}
                {this.renderTableCellActionIcon(`/token/pre-register/${row.id}/delete`, 'trash', 'löschen', this.capabilities.includes('delete'))}
            </td>
        )
    }
}