import { multiselect_options } from '../Constants';

export default class AccountManagement {
    constructor() {
        if (document.getElementById('operators')) {
            $('#operators').multiselect(multiselect_options);
        }

        $('#provider').change(() => this.updateProvider());

        $('#register_ui').change(() => this.updateRegisterUi());
        //$('#type').change(() => this.updateType());
    };

    updateProvider() {
        let provider = document.getElementById('provider').checked;
        let provider_items = $('#provider-items');
        if (provider) {
            provider_items.slideDown();
        }
        else {
            provider_items.slideUp();
        }
    }

    updateRegisterUi() {
        let register_ui = document.getElementById('register_ui').checked;
        let register_ui_item = $('#register-ui-fields');
        if (register_ui) {
            register_ui_item.slideDown();
        }
        else {
            register_ui_item.slideUp();
        }
    }

    updateType() {
        let type_val = $('#type').val();
        let iban = $('#iban').parent();
        let identifier = $('#identifier').parent();
        if (type_val === 'standard') {
            identifier.hide();
            iban.show();
        }
        else {
            iban.hide();
            identifier.show();
        }
    }
}
