import React from "react";

const { Component } = React;

export default class FormField extends Component {
    state = {
        error: false
    };

    static defaultProps = {
        validators: [],
        filters: [],
        renderFilters: []
    };

    constructor(props) {
        super(props);
        this.state.field = props.field;
    }

    componentDidUpdate(prevProps) {
        if (this.state.field.clientUid === this.props.field.clientUid)
            return;
        this.setState({
            field: this.props.field
        });
    }

    validate(value) {
        for (let i = 0; i < this.props.validators.length; i++) {
            let result = this.props.validators[i].validate(value);
            if (result === 'break')
                return true;
            if (result === false)
                return false;
        }
        return true;
    }

    filter(value) {
        for (let i = 0; i < this.props.filters.length; i++) {
            value = this.props.filters[i](value);
        }
        return value;
    }

    renderFilter(value) {
        for (let i = 0; i < this.props.renderFilters.length; i++) {
            value = this.props.renderFilters[i](value);
        }
        return value;
    }

    updateValue(evt) {
        this.state.field.setValue(evt.target.value);
        this.setState({ field: this.state.field });
        if (this.props.updateParent)
            this.props.updateParent(evt.target.value);
    }
}
