
import { generateUid } from '../Helper';

export default class Base {
    isValid = true;
    hasChanged = false;
    fields = {};
    deleted = false;
    parent = null;
    errors = [];

    load(data) {
        this.clientUid = generateUid();
        if (!data)
            return;
        if (data.parent)
            this.parent = data.parent;
        for (const [key, field] of Object.entries(this.fields)) {
            field.clientUid = this.clientUid;
        }
        for (const [key, value] of Object.entries(data)) {
            if (!Object.keys(this.fields).includes(key))
                continue;
            this.fields[key].initValue(value);
        }
    }

    validate() {
        this.errors = [];
        if (this.deleted)
            return true;
        this.isValid = true;
        for (const [key, field] of Object.entries(this.fields)) {
            this.isValid = field.validate() && this.isValid;
        }
        return this.isValid;
    }

    isValidByGroup(validation_group) {
        let is_valid = true;
        for (const [key, field] of Object.entries(this.fields)) {
            if (field.validationGroup !== validation_group)
                continue;
            is_valid = field.validate() && is_valid
        }
        return is_valid;
    }

    getErrors() {
        if (this.deleted)
            return [];
        let result = [...this.errors];
        for (const [key, field] of Object.entries(this.fields)) {
            result.push(...field.errors.map((item, id) => [field.key, item]));
        }
        return result;
    }

    getHasChanged() {
        if (this.deleted)
            return true;
        let result = this.hasChanged;
        for (const [key, field] of Object.entries(this.fields)) {
            result = field.getHasChanged() || result;
        }
        return result;
    }

    dump() {
        let result = {};
        for (const [key, field] of Object.entries(this.fields)) {
            result[key] = field.exportValue();
        }
        result.deleted = this.deleted;
        return result;
    }
}
