import React from "react";
import FormField from './FormField';
import DatePicker from "react-datepicker";
import { zerofy } from '../../Format';

export default class FormInput extends FormField {

    updateValue(date) {
        this.state.field.setValue(date);
        this.setState({ field: date });
        if (this.props.updateParent)
            this.props.updateParent();
    }

    render() {
        return (
            <div className={`col-md-${this.props.width}`}>
                <label htmlFor={this.props.field.key}>{this.props.label}</label>
                {(!this.props.unit) ?
                    this.renderInput() :
                    <span className={`unit unit-${this.props.unit}`}>{this.renderInput()}</span>
                }
            </div>
        )
    }

    renderInput() {
        return (
            <DatePicker
                className={`form-control${(!this.state.field.isValid) ? ' is-invalid' : ''}`}
                id={this.props.field.key}
                name={this.props.field.key}
                selected={this.props.field.value}
                onChange={this.updateValue.bind(this)}
                isClearable
                placeholderText=''
                dateFormat="dd.MM.yyyy"
            />
        );
    }
}