import React from "react";
import { Decimal } from 'decimal.js';
import moment from 'moment';
import { multiselect_options, daterangepicker_ranges, daterangepicker_locale } from "../Constants";
import SearchTable from './SearchTable'
import {formatDaterange, formatDatetime, formatDuration, formatEnergy, formatPrice} from "../Format";

export default class SearchTableCharge extends SearchTable {


    params = {
        sort_field: 'auth_datetime',
        sort_order: 'desc',
        daterange: moment().subtract(3, 'month').format('DD.MM.YYYY') + ' - ' + moment().format('DD.MM.YYYY'),
        page: 1
    };
    formId = 'charge-search-form';
    varPrefix = 'searchTableCharge';
    loadParamsRegExp = [
        /\/charge\/(.*)/g
    ];

    submit_proceed = [
        'download',
    ];

    apiUrl = '/api/charges';
    sortDef = [
        { key: 'auth_datetime', name: 'Autorisierungszeitpunkt' },
        { key: 'unit_uid', name: 'Ladepunkt' },
        { key: 'energy', name: 'Verbrauch'},
        { key: 'duration', name: 'Dauer'},
        { key: 'value_total', name: 'Brutto'}
    ];

    colDef = [
        { sortField: 'auth_datetime', text: 'Datum & Zeit' },
        { sortField: 'unit_uid', text: 'Ladepunkt' },
        { text: 'Konto' },
        { sortField: 'energy', text: 'Verbrauch' },
        { sortField: 'value_total', text: 'Umsatz' },
        { text: 'Aktionen' }
    ];

    validityStyles = {
        'TAINTED': 'table-warning',
        'CRITICAL': 'table-danger'
    };

    init() {
        this.itemsPerPage = $('#results').val();
        super.init();
        $('#fields_export').multiselect(multiselect_options);
        $('#status').multiselect(multiselect_options);
        let start = moment().subtract(3, 'month');
        let end = moment();
        $('#daterange').daterangepicker({
            startDate: start,
            endDate: end,
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, (start, end, label) => this.daterangepickerSubmit(start, end, label));
        $('#results').change(() => {
            this.itemsPerPage = $('#results').val();
        });
        $(".bootstrap-select-search").selectpicker({liveSearch: true});
        $("#clear").click(() => this.reset())
    }

    reset() {
        localStorage.setItem(this.varPrefix + 'Data', null);
        window.location.reload()
    }

    renderTableRow(row) {
        return (
            <tr className={(giro_e_config.userType === 'operator') ? '' : (this.validityStyles[row.validity] || '')} key={`charge-${row.id}`}>
                {this.renderTableCellAuthDatetimeBeginEnd(row)}
                {this.renderTableCellUnit(row)}
                {this.renderTableCellAccount(row)}
                {this.renderTableCellEnergy(row)}
                {this.renderTableCellValuePrice(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellAuthDatetimeBeginEnd(row) {
        return(
            <td>
                {(row.status === 'auth') ? formatDatetime(row.auth_datetime) : formatDaterange(row.begin, row.end)}<br/>
                <small>{(row.duration_raw) ? formatDuration(row.duration_raw) : ''}</small>
            </td>
        )
    }

    renderTableCellUnit(row) {
        return(
            <td>
                {row.name &&
                    <span>{row.name}</span>
                }
                <a href={'/unit/' + row.unit_id + '/show'}>{this.formatUnitUid(row.unit_uid)}</a><br/>
                <small>{row.postalcode} {row.locality}, {row.address} </small>
            </td>
        )
    }

    renderTableCellAccount(row) {
        return(
            <td className="nowrap">
                <span className="span-link" onClick={this.setAccountSearch.bind(this, row.account_type, row.account_identifier)}>
                    {this.formatAccountIdentifier(row.account_type, row.account_identifier, row.account_name)}
                </span><br/>
                <small>
                    {(row.token_type === 'static') ? ((row.token_source === 'fleet') ? ' Flotten-' : ' Nutzer-') : ''}
                    {this.formatTokenType(row.token_type).replace('-Karte', '')}
                    {(row.token_name) ? ': ' + row.token_name : ''}
                    {' '}
                    {this.renderStatusIcon('lock', row.token_valid)}
                    {this.renderStatusIcon('exclamation-triangle', row.token_valid === false)}
                    {row.discount_name && ` - ${row.discount_name}`}
                </small>
            </td>
        )
    }

    setAccountSearch(type, identifier) {
        $('#account_identifier').val(this.formatAccountIdentifier(type, identifier));
        this.params.page = 1;
        this.updateParams();
        this.updateData();
    }

    renderTableCellEnergy(row) {
        if (row.status !== 'complete')
            return(
                <td>-</td>
            );
        let amount = '- €/kWh';
        if (row.energy_raw)
            amount = formatPrice(new Decimal(row.value_total).times(1000).div(row.energy_raw)) + '/kWh';
        return(
            <td className="nowrap">
                {formatEnergy(row.energy_raw)}<br/>
                <small>{amount}</small>
            </td>
        )
    }

    renderTableCellValuePrice(row) {
        if (row.status !== 'complete')
            return(
                <td>-</td>
            );
        return(
            <td className="nowrap">
                {formatPrice(row.value_total)}<br/>
                <small>Netto: {formatPrice(row.value_subtotal)}</small>
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon('/charge/' + row.id + '/show', 'info', 'anzeigen', true)}
            </td>
        )
    }
}