import React from "react";
import SearchTable from './SearchTable'
import {formatPrice} from "../Format";

export default class SearchTableTransferTransaction extends SearchTable {
    params = {
        sort_field: 'auth_datetime',
        sort_order: 'asc',
        page: 1
    };
    formId = 'transfer-transaction-search-form';
    varPrefix = 'searchTableTransferTransaction';
    loadParamsRegExp = [

    ];

    itemsPerPage = 10;


    apiUrl = '/api/transfer/transaction';
    sortDef = [
        { key: 'uid', name: 'ID' },
        { key: 'auth_datetime', 'name': 'Autorisierungs-Zeitpunkt'},
        { key: 'value_total', name: 'Bruttopreis'}
    ];

    colDef = [
        { sortField: 'uid', text: 'ID' },
        { sortField: 'auth_datetime', text: 'Position' },
        { sortField: 'value_total', text: 'Bruttopreis' },
    ];


    renderTableRow(row) {
        return (
            <tr key={`transaction-${row.id}`}>
                <td>{row.uid}</td>
                <td><a href={row.details_url}>{row.label}</a></td>
                <td>{formatPrice(row.value_total)}</td>
            </tr>
        )
    }
}