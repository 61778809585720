import React from "react";
import moment from 'moment';
import { multiselect_options, daterangepicker_ranges, daterangepicker_locale } from '../Constants';
import SearchTable from './SearchTable'
import {formatDaterange, formatDatetime, formatDurationByTimestamps, formatPrice} from "../Format";

export default class SearchTableOrder extends SearchTable {
    params = {
        sort_field: 'auth_datetime',
        sort_order: 'desc',
        daterange: moment().subtract(3, 'month').format('DD.MM.YYYY') + ' - ' + moment().format('DD.MM.YYYY'),
        page: 1
    };
    formId = 'order-search-form';
    varPrefix = 'searchTableOrder';
    loadParamsRegExp = [
        /\/charge\/(.*)/g
    ];
    submit_proceed = [
        'download'
    ];

    apiUrl = '/api/orders';
    sortDef = [
        { key: 'auth_datetime', name: 'Autorisierungs-Zeitpunkt' },
        { key: 'end', name: 'Abrechnungs-Zeitpunkt'},
        { key: 'account_identifier', name: 'Konto'},
        { key: 'value_total', name: 'Brutto'}
    ];

    colDef = [
        { sortField: 'auth_datetime', text: 'Datum & Zeit' },
        { text: 'Kasse' },
        { sortField: 'account_identifier', text: 'Konto' },
        { text: 'Produkte' },
        { sortField: 'value_total', text: 'Umsatz' },
        { text: 'Aktionen' }
    ];

    init() {
        this.params.type = $('#type').val();
        if (this.params.type === 'external') {
            this.colDef[1].text = 'Client';
        }
        this.itemsPerPage = $('#results').val();
        super.init();
        $('#fields_export').multiselect(multiselect_options);
        let start = moment().subtract(3, 'month');
        let end = moment();
        $('#daterange').daterangepicker({
            startDate: start,
            endDate: end,
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, (start, end, label) => this.daterangepickerSubmit(start, end, label));
        $('#results').change(() => {
            this.itemsPerPage = $('#results').val();
        });
        $(".bootstrap-select-search").selectpicker({liveSearch: true});
    }
    
    renderTableRow(row) {
        return (
            <tr key={`order-${row.id}`}>
                {this.renderTableCellAuthDatetimeBeginEnd(row)}
                {this.renderTableCellCashpoint(row)}
                {this.renderTableCellAccount(row)}
                {this.renderTableCellProducts(row)}
                {this.renderTableCellValuePrice(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    setAccountSearch(type, identifier) {
        $('#account_identifier').val(this.formatAccountIdentifier(type, identifier));
        this.params.page = 1;
        this.updateParams();
        this.updateData();
    }

    renderTableCellAuthDatetimeBeginEnd(row) {
        let content;
        if (this.params.type === 'external') {
            if (row.status === 'auth') {
                return <td>
                    { formatDatetime(row.auth_datetime)}
                </td>
            } else {
                return <td>
                    {formatDaterange(row.auth_datetime, row.end)}<br/>
                    <small>{formatDurationByTimestamps(row.auth_datetime, row.end)}</small>
                </td>
            }
        } else {
            return <td>
                {formatDatetime(row.end)}
            </td>
        }
    }

    renderTableCellCashpoint(row) {
        if (this.params.type === 'external') {
            return (
                <td>
                    {row.cashpoint_name && row.cashpoint_name.join(', ')}
                </td>
            )
        }
        return(
            <td>
                <a href={'/cashpoint/' + row.cashpoint_id + '/show'}>{row.cashpoint_name}</a>
            </td>
        )
    }
    
    renderTableCellAccount(row) {
        return(
            <td className="nowrap">
                <span className="span-link" onClick={this.setAccountSearch.bind(this, row.account_type, row.account_identifier)}>
                    {this.formatAccountIdentifier(row.account_type, row.account_identifier)}
                </span><br/>
                <small>
                    {(row.token_type === 'static') ? ((row.token_source === 'fleet') ? ' Flotten-' : ' Nutzer-') : ''}
                    {this.formatTokenType(row.token_type).replace('-Karte', '')}
                    {(row.token_name) ? ': ' + row.token_name : ''}
                    {' '}
                    {this.renderStatusIcon('lock', row.token_valid)}
                    {this.renderStatusIcon('exclamation-triangle', row.token_valid === false)}
                </small>
            </td>
        )
    }

    renderTableCellProducts(row) {
        if (!row.item)
            return(
                <td></td>
            );
        let products = [];
        for (let i = 0; i < row.item.length; i++) {
            products.push(row.item[i].amount + 'x ' + row.item[i].name)
        }
        return(
            <td>
                <small>{products.join(', ')}</small>
            </td>
        )
    }

    renderTableCellValuePrice(row) {
        if (row.status !== 'complete')
            return (
                <td></td>
            );
        return(
            <td className="nowrap">
                {formatPrice(row.value_total)}<br/>
                <small>Netto: {formatPrice(row.value_subtotal)}</small>
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon('/' + this.params.type + '/' + row.id + '/show', 'info', 'anzeigen', true)}
            </td>
        )
    }
}