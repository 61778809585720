import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableHardware extends SearchTable {
    params = {
        sort_field: 'name_short',
        sort_order: 'asc',
        page: 1
    };
    apiUrl = '/api/hardwares';
    formId = 'hardware-search-form';
    varPrefix = 'searchTableHardware';
    loadParamsRegExp = [
        /\/hardware\/(.*)/g
    ];

    sortDef = [
        { key: 'name_short', name: 'Name'},
        { key: 'created', name: 'Erstellt' },
        { key: 'modified', name: 'Bearbeitet' }
    ];

    colDef = [
        { sortField: 'name_short', text: 'Name' },
        { text: 'Hersteller' },
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr key={`hardware-${row.id}`}>
                {this.renderTableCellName(row)}
                {this.renderTableCellManufacturer(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellName(row) {
        return(
            <td>
                {row.name_short}<br/>
                <small>{this.formatHardwareType(row.type)}</small>
            </td>
        )
    }

    renderTableCellManufacturer(row) {
        return(
            <td>
                {(row.manufacturer) ? row.manufacturer.name : '?'}
            </td>
        )
    }

    formatHardwareType(value) {
        if (value === 'cashpoint')
            return 'Cashpoint';
        if (value === 'unit')
            return 'Ladesäule';
        return value;
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/hardware/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/hardware/${row.id}/edit`, 'pencil', 'bearbeiten', true)}
                {this.renderTableCellActionIcon(`/hardware/${row.id}/delete`, 'trash', 'löschen', true)}
            </td>
        )
    }
}