import React from "react";

import ViewBase from '../../View';
import { requestGet, requestPost } from "../../../Common";


export default class ViewSave extends ViewBase {
    obj = 'unitGroup';
    state = {
        state: 'loading'
    };

    constructor(props) {
        super(props);
        this.state[this.obj] = props[this.obj];
    }

    componentDidMount() {
        let errorStatus = !this.props.unitGroup.validate();
        let unitGroupToSave = (this.props.unitGroup.getHasChanged()) ? this.props.unitGroup.dump() : null;
        if (unitGroupToSave && unitGroupToSave.sub_operator_type !== 'none') {
            unitGroupToSave.refund_type = 'full';
            if (unitGroupToSave.sub_operator_type === 'charge-employee')
                unitGroupToSave.operator_refunded = unitGroupToSave.sub_operator_employee
            else
                unitGroupToSave.operator_refunded = unitGroupToSave.sub_operator_client
        }
        else if (unitGroupToSave) {
            unitGroupToSave.refund_type = 'none';
        }
        let refundPricegroupToSave = (this.props.refundPricegroup.getHasChanged()) ? this.props.refundPricegroup.dump() : null;
        this.props.refundPricegroup.getErrors()
        let unitsToSave = [];
        let errorMessages = this.props.refundPricegroup.getErrors();
        this.props.stations.map((station, id) => {
            errorStatus = !station.validate() || errorStatus;
            if (errorStatus) {
                station.getErrors().map((error, id) => {
                    errorMessages.push('Station ' + station.fields.uid.value + ': ' + error[0] + ': ' + error[1]);
                });
            }
            if (!station.getHasChanged(true))
                return;
            station.units.map((unit, id) => {
                errorStatus = !unit.validate() || errorStatus;
                if (errorStatus) {
                    unit.getErrors().map((error, id) => {
                        errorMessages.push('Ladepunkt ' + unit.fields.uid.value + ' ' + error[0] + ': ' + error[1]);
                    });
                }
                if (!unit.getHasChanged() && !station.getHasChanged()) {
                    return;
                }
                let unit_dump = Object.assign(unit.dump(), station.dump(), {
                    uid: unit.dump().uid,
                    uid_station: station.dump().uid,
                    name_station: station.dump().name,
                    deleted: unit.dump().deleted
                });
                unit_dump.unit_group = this.props.unitGroup.id;
                unit_dump.connector_id = id + 1;
                unitsToSave.push(unit_dump)
            });
        });
        if (errorStatus) {
            return this.setState({
                state: 'errors',
                errorMessages: errorMessages
            });
        }
        if (!unitsToSave.length && !unitGroupToSave) {
            window.location = '/unit-groups';
        }
        let data_send = {
            id: this.props.unitGroup.fields.id.value,
            unitGroup: unitGroupToSave,
            units: unitsToSave,
            refundPricegroup: refundPricegroupToSave,
            operator_id: unitGroupData.operator_id
        };
        requestGet('/api/common/csrf').then((data) => {
            requestPost('/api/unit-group/save', data_send,  data.csrf)
                .then((data) => {
                    if (data.status) {
                        this.setState({
                            state: 'error'
                        });
                        return;
                    }
                    window.location = '/unit-groups';
                    this.setState({
                        state: 'success'
                    });
                });
        });
    }

    render() {
        return (
            <div className="container">
                <div className="row row-form">
                    <div className="col-md-12">
                        <h2>Speichern</h2>
                        {this.renderMessage()}
                    </div>
                </div>
            </div>
        )
    }

    renderMessage() {
        if (this.state.state === 'loading')
            return <p><i className="fa fa-spinner fa-pulse fa-fw"></i> wird gespeichert ...</p>;
        if (this.state.state === 'errors') {
            return (
                <div>
                    <p>Fehlerhafte Einträge (rot markiert). Speichern nicht möglich.</p>
                    <ul>
                        {this.state.errorMessages.map((message, id) => {
                            return <li key={id}>{message}</li>
                        })}
                    </ul>
                </div>
            );
        }
        if (this.state.state === 'no-save')
            return <p>Nichts zu speichern.</p>;
        if (this.state.state === 'error')
            return <p>Speichern fehlgeschlagen.</p>
        if (this.state.state === 'success')
            return <p>Speichern erfolgreich.</p>

    }
}