import { Field } from './Field';
import {ValidateFloat} from "../Validators";
import {filterFloat, filterFloatToString} from "../Filter";

export class FloatField extends Field {
    validators = [ new ValidateFloat() ];
    exportFilters = [ filterFloat ];
    initFilters = [ filterFloatToString ];

    constructor(params) {
        super();
        this.load(params);
    }
}