import React from "react";
const { Component } = React;

import FormInput from '../../FormField/FormInput';
import FormMaps from "../../FormField/FormMaps";
import FormTextArea from "../../FormField/FormTextArea";
import ViewBase from '../../View';


export default class ViewSettings extends ViewBase {
    obj = 'unitGroup';

    constructor(props) {
        super(props);
        this.state[this.obj] = props[this.obj];
    }

    render() {
        return (
            <div className="container">
                <div className="row row-form">
                    <div className="col-md-12">
                        <h2>Einstellungen</h2>
                    </div>
                    <FormInput
                        field={this.props.unitGroup.fields.name}
                        label='Interne Bezeichnung'
                        width='6'
                    />
                    <FormInput
                        field={this.props.unitGroup.fields.goingelectric_url}
                        label='GoingElectric URL'
                        width='6'
                    />
                </div>
                <div className="row row-form">
                    <FormInput
                        field={this.props.unitGroup.fields.address}
                        label='Adresse'
                        width='6'
                        updateParent={this.updateParent.bind(this)}
                    />
                    <FormInput
                        field={this.props.unitGroup.fields.postalcode}
                        label='Postleitzahl'
                        width='2'
                        updateParent={this.updateParent.bind(this)}
                    />
                    <FormInput
                        field={this.props.unitGroup.fields.locality}
                        label='Ort'
                        width='4'
                        updateParent={this.updateParent.bind(this)}
                    />
                </div>
                <div className="row row-form">
                    <FormTextArea
                        field={this.props.unitGroup.fields.description}
                        label='Genaue Standort-Beschreibung'
                        width='12'
                    />
                </div>
                <div className="row row-form">
                    <FormMaps
                        fieldLat={this.props.unitGroup.fields.lat}
                        fieldLon={this.props.unitGroup.fields.lon}
                        width="12"
                        address={this.props.unitGroup.fields.address.value}
                        postalcode={this.props.unitGroup.fields.postalcode.value}
                        locality={this.props.unitGroup.fields.locality.value}
                    />
                </div>
            </div>
        )
    }
}